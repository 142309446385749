import { ServerErrorMessage } from "../shared-interfaces";
import { NotificationRoot, NotificationType } from "./interfaces";

class Toast {
    private root: NotificationRoot;
    private isInitialized: boolean;

    init(root: NotificationRoot): void {
        if (this.isInitialized) {
            throw new Error('Toast is already initialized!');
        }
        this.root = root;
        this.isInitialized = true;
    }

    show(type: NotificationType, message: string, autoHide: boolean = true): number {
        return this.root.showNotification(type, message, null, null, autoHide);
    }

    confirm(steps: string[], header: string, confirmCallback: () => void): void {
        this.root.confirm(steps, header, confirmCallback);
    }

    serverError(error: ServerErrorMessage): number {
        return this.root.showNotification(NotificationType.ERROR, error.message, error.url, error.urlDescription, false);
    }
}

export let toast = new Toast();
