import React, { Component, SyntheticEvent } from 'react';
import { createRoot } from 'react-dom/client';
import Releases from './releases/releases'
import Settings from './settings/settings'
import AuthForm from './auth-form'
import { BaseStyles } from '@primer/components';
import { TailSpin } from 'react-loader-spinner';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Notifications } from './notifications/notifications';
import { Header } from './header';
import { api } from './api';
import { AppContext, AppInfo } from './context';
import { ActionModeChange, Actions, Resources, Settings as SettingsInfo, SettingsPropName } from './shared-interfaces';
import Can from './can';
import { ac } from './ac';
import History from './history/history';
import AutoReleasesBar from './releases/auto-releases-bar';
import Services from './services/services';
import ReleaseDetails from './releases/release';
import CanaryPage from './canary/canary';
import ArgocdPage from './argocd/argocd';
import { DateTimeMode, Utils } from './utils';

const API_URL = '/api';

interface Props { }

interface State extends AppInfo {
    authLoading: boolean;
    isAuthenticated: boolean;
    dateTimeMode: DateTimeMode;
}

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this.emptyState(true);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.updateDateTimeMode = this.updateDateTimeMode.bind(this);
        this.changeSettingsProp = this.changeSettingsProp.bind(this);
        api.init(API_URL, () => this.setState(this.emptyState(false)));

        if (!window.location.href?.includes('localhost')) {
            //Remove some logs on production
            console.log = (_: any) => { };
        }
    }

    componentDidMount() {
        this.ensureAccessControlRules().then(() => {
            this.login();
        });
    }

    ensureAccessControlRules() {
        return api.get('rules', {}).then(x => {
            return ac.init(x);
        });
    }

    emptyState(authLoading: boolean): State {
        return { user: null, authLoading, isAuthenticated: false, settings: null, dateTimeMode: Utils.getDateTimeMode() };
    }

    login() {
        this.setState({ authLoading: true });
        api.get('users/me', {}, false).then(res => {
            console.log(res);
            this.setState({
                isAuthenticated: true,
                user: res.user,
                authLoading: false,
                settings: res.settings,
                dateTimeMode: Utils.getDateTimeMode()
            })
        }).catch(_ => {
            this.setState(this.emptyState(false));
        });
    }

    logout(e: SyntheticEvent): void {
        e?.preventDefault();
        this.setState({ authLoading: true });
        api.post('logout', {}).finally(() => {
            this.setState(this.emptyState(false));
        });
    }

    updateDateTimeMode(mode: DateTimeMode): void {
        Utils.setDateTimeMode(mode);
        this.setState({ dateTimeMode: mode });
    }

    changeSettingsProp(propName: SettingsPropName, modeChange: ActionModeChange): void {
        this.setState({ settings: null });
        api.post('settings/patch',
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({ [propName]: modeChange }))
            .then(res => {
                this.setState({ settings: res.settings });
            });
    }

    renderAutoReleasesBar(): JSX.Element {
        return (
            <AutoReleasesBar isAuthenticated={this.state.isAuthenticated}
                settings={this.state.settings?.releaseSettings}
                onSettingsChanged={this.changeSettingsProp} />
        );
    }

    render() {
        let content;
        if (this.state.authLoading) {
            content = (
                <div className="d-flex flex-items-center flex-justify-around" style={{ minHeight: '300px' }}>
                    <TailSpin color="#777777" height={50} width={50} />
                </div>
            );
        }
        else if (this.state.isAuthenticated) {
            content = (
                <Router>
                    <Routes>
                        <Route path={`/releases/:releaseId`} element={
                            <Can action={Actions.READ} resource={Resources.RELEASES}
                                yes={() => (<ReleaseDetails />)}
                                no={() => (<Navigate replace to={"/history"} />)}
                            />} />
                        <Route path={'/history'} element={
                            <>
                                {this.renderAutoReleasesBar()}
                                <History />
                            </>} />
                        <Route path={'/releases'} element={
                            <Can action={Actions.UPDATE} resource={Resources.RELEASES}
                                yes={() => (
                                    <>
                                        {this.renderAutoReleasesBar()}
                                        <Releases />
                                    </>
                                )}
                                no={() => (<Navigate replace to={"/history"} />)}
                            />} />
                        <Route path={'/services'} element={
                            <>
                                {this.renderAutoReleasesBar()}
                                <Services />
                            </>} />

                        <Route path={'/canary'} element={
                            <>
                                <CanaryPage />
                            </>} />
                        
                        <Route path={'/argocd'} element={
                            <>
                                <ArgocdPage />
                        </>} />
                        <Route path="/settings" element={
                            <Can action={Actions.READ} resource={Resources.SETTINGS}
                                yes={() => (<Settings onSettingsUpdated={(settings: SettingsInfo) => this.setState({ settings })} />)}
                                no={() => (<Navigate replace to={"/history"} />)}
                            />}
                        />
                        <Route path="/" element={<Navigate replace to={"/history"} />} />
                    </Routes>
                </Router>);
        } else {
            content = (
                <AuthForm onAuth={this.login} />
            );
        }
        return (
            <AppContext.Provider value={this.state}>
                <>
                    <BaseStyles />
                    <Header isAuthenticated={this.state.isAuthenticated} displayName={this.state.user?.displayName ?? ''} onLogout={this.logout} 
                        dateTimeMode={this.state.dateTimeMode} onDateTimeModeChanged={this.updateDateTimeMode}/>
                    <Notifications />
                    {content}
                </>
            </AppContext.Provider>
        );
    }
}

const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(<App />);
