import React, { Component } from 'react';
import { AppContext, AppInfo } from '../context';
import { MINIMAL_RMC_ROLE, User, UserRole, userRolesList } from '../shared-interfaces';
import ComboBox from './combo-box';
import { ac } from '../ac';

interface Props {
    user: User;
    roleChanged: (user: User, role: UserRole) => void;
}

interface State {
    roles: UserRole[];
    role: UserRole;
}

export default class RoleEdit extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { roles: [], role: props.user.role };
        this.setRole = this.setRole.bind(this);
        this.canEdit = this.canEdit.bind(this);
    }

    canEdit(role: UserRole): boolean {
        const context = this.context as AppInfo;
        const isAdminPermission = context.user.role === UserRole.ADMIN;
        const hasMinimalRole = ac.hasRole(context.user, MINIMAL_RMC_ROLE);
        if (!isAdminPermission && !hasMinimalRole) {
            return false;
        }

        return this.props.user.isActive && this.props.user.id !== context.user.id
            && ac.hasRole(context.user, role);
    }

    componentDidMount(): void {
        const context = this.context as AppInfo;
        const roles = context.user.role === UserRole.ADMIN
            ? userRolesList()
            : userRolesList(UserRole.ADMIN);
        this.setState({ roles });
    }

    setRole(role: UserRole): void {
        if (this.state.role === role) {
            return;
        }
        this.setState({ role });
        this.props.roleChanged(this.props.user, role);
    }

    render(): JSX.Element {
        return (
            <ComboBox rawValues={this.state.roles} selectedValue={this.state.role} width={100}
                editable={this.canEdit(this.state.role)} onItemClick={(item) => this.setRole(item.value)} />
        );
    }
}

RoleEdit.contextType = AppContext;
