import { PlusIcon, QuestionIcon } from '@primer/octicons-react';
import React, { Component } from 'react';
import { CustomCiJob, SlackActionTTL, SlackQuickAction, UserRole, actionTtlToString, userRolesList } from '../shared-interfaces';
import { Utils } from '../utils';
import ComboBox, { ComboBoxItem } from './combo-box';

const TRUE = "true";
const FALSE = "false";

interface Props {
    onAdd: (job: CustomCiJob) => void;
}

interface State {
    isValid: boolean;
    job: CustomCiJob;
    isJobNameRequired: boolean;
}

export default class NewServiceCustomJob extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this.emptyState();
        this.addCustomJob = this.addCustomJob.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleQuickActionSelect = this.handleQuickActionSelect.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.updateState = this.updateState.bind(this);
        this.renderSlackActionForm = this.renderSlackActionForm.bind(this);
    }

    private getValue(e: React.ChangeEvent<HTMLInputElement>): any {
        return e.target.type === "radio"
            ? (e.target.value === TRUE || e.target.value === FALSE ? e.target.value === TRUE : e.target.value)
            : e.target.value;
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.updateValue(e.target.name, this.getValue(e));
    }

    handleQuickActionSelect(e: React.ChangeEvent<HTMLInputElement>): void {
        const job = this.state.job;
        const hasAction: boolean = this.getValue(e);
        job.slackAction = hasAction ? this.emptyQuickAction() : undefined;
        this.updateState(job);
    }

    updateValue(propName: string, value: any): void {
        const job = this.state.job as any;
        const names = propName.split(';');
        if (names.length === 1) {
            job[propName] = value;
        } else {
            console.log(`Set ${names[0]};${names[1]}`)
            job[names[0]][names[1]] = value;
        }
        this.updateState(job);
    }

    updateState(job: CustomCiJob): void {
        const isJobValid = Object.values(job).every((x: any) => (typeof x == "boolean" || typeof x == "object") || !Utils.isNullOrWhitespace(x));

        const action = job.slackAction;
        const isJobNameRequired = action?.ttl === SlackActionTTL.ACTIVE_DEPLOYMENT;
        const isActionValid = !action || (!Utils.isNullOrWhitespace(action.buttonName)
            && (!isJobNameRequired || !Utils.isNullOrWhitespace(action.requiredJobName)));

        this.setState({ isValid: isJobValid && isActionValid, isJobNameRequired, job });
    }

    addCustomJob(): void {
        this.props.onAdd(this.state.job);
        this.setState(this.emptyState());
    }

    private emptyState(): State {
        return {
            isValid: false,
            isJobNameRequired: false,
            job: { ciPipelineTrigger: '', name: '', branch: '', requiredUserRole: UserRole.GUEST, isReasonRequired: false },
        };
    }

    private emptyQuickAction(): SlackQuickAction {
        return { ttl: SlackActionTTL.HOUR_3, buttonName: '', confirmationMessage: '', requiredJobName: '' };
    }

    private ttlList(): ComboBoxItem[] {
        return [
            { name: actionTtlToString(SlackActionTTL.UNTIL_NEXT_RELEASE), value: SlackActionTTL.UNTIL_NEXT_RELEASE },
            { name: actionTtlToString(SlackActionTTL.HOUR_3), value: SlackActionTTL.HOUR_3 }
        ];
        // return Object.values(SlackActionTTL).map(x =>({ name: actionTtlToString(x), value: x }));
    }

    private renderSlackActionForm(action: SlackQuickAction): JSX.Element {
        if (!action) {
            return null;
        }

        return (
            <>
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2" htmlFor="slackAction;buttonName">
                    Button name
                </label>
                <input type="text" name="slackAction;buttonName" className="form-control col-5 float-left p-2 m-2"
                    onChange={this.handleChange} value={this.state.job.slackAction.buttonName} />
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2">
                    TTL
                    <span className="tooltipped tooltipped-e" aria-label="Button availability - during active deployment or fixed time after deployment completion." >
                        <QuestionIcon className="ml-1" />
                    </span>
                </label>
                <div className="col-5 float-left p-2 mb-2">
                    <ComboBox items={this.ttlList()} selectedValue={this.state.job.slackAction.ttl} editable={true} width={170}
                        onItemClick={(item) => this.updateValue(`slackAction;ttl`, item.value)} />
                </div>
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2" htmlFor="slackAction;confirmationMessage">
                    Slack confirmation message
                </label>
                <input type="text" name="slackAction;confirmationMessage" className="form-control col-5 float-left p-2 m-2"
                    onChange={this.handleChange} maxLength={285}
                    value={this.state.job.slackAction.confirmationMessage} />
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2" htmlFor="slackAction;userRole">
                    Required job name/key
                    <span className="tooltipped tooltipped-e" aria-label="Job key/name to identify for which Build this action should be available." >
                        <QuestionIcon className="ml-1" />
                    </span>
                </label>
                <input type="text" name="slackAction;requiredJobName" className="form-control col-5 float-left p-2 m-2"
                    onChange={this.handleChange} value={this.state.job.slackAction.requiredJobName} disabled={!this.state.isJobNameRequired} />
                <div className="col-5 float-left" />
            </>
        );
    }

    render() {
        return (
            <div className="clearfix">
                <label className="col-3 float-left text-right p-2 m-2" htmlFor="ciJob">
                    Name
                </label>
                <input type="text" name="name" className="form-control col-5 float-left p-2 m-2"
                    placeholder='Action name to display' value={this.state.job.name} onChange={this.handleChange} />
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2" htmlFor="branch">
                    Branch
                    <span className="tooltipped tooltipped-e" aria-label="CI job runs against specific branch. To use branch from the latest release use `<latest>` keyword." >
                        <QuestionIcon className="ml-1" />
                    </span>
                </label>
                <input type="text" name="branch" className="form-control col-5 float-left p-2 m-2"
                    placeholder='On which branch should we trigger job' value={this.state.job.branch} onChange={this.handleChange} />
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2" htmlFor="name">
                    CI pipeline trigger
                    <span className="tooltipped tooltipped-e" aria-label="Parameter name from 'config.yaml', which will be used with TRUE value to trigger pipeline." >
                        <QuestionIcon className="ml-1" />
                    </span>
                </label>
                <input type="text" name="ciPipelineTrigger" className="form-control col-5 float-left p-2 m-2"
                    placeholder="Parameter name, e.g. 'deploy_test_workflow'" value={this.state.job.ciPipelineTrigger} onChange={this.handleChange} />
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2">Required User role</label>
                <div className="col-5 float-left p-2 mb-2">
                    <ComboBox rawValues={userRolesList()} selectedValue={this.state.job.requiredUserRole} editable={true} width={100}
                        onItemClick={(item) => this.updateValue(`requiredUserRole`, item.value)} />
                </div>
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2">Height task</label>
                <div className="radio-group float-left col-5 float-left p-2 mb-2">
                    <input type="radio" id="no_reason" className="radio-input" name="isReasonRequired" value={FALSE}
                        checked={!this.state.job.isReasonRequired} onChange={this.handleChange} />
                    <label className="radio-label" htmlFor="no_reason">None</label>

                    <input type="radio" id="reason_required" className="radio-input" name="isReasonRequired" value={TRUE}
                        checked={!!this.state.job.isReasonRequired} onChange={this.handleChange} />
                    <label className="radio-label" htmlFor="reason_required">Required</label>
                </div>
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2">Slack quick action</label>
                <div className="radio-group float-left col-5 float-left p-2 mb-2">
                    <input type="radio" id="no_q_action" className="radio-input" name="slackQuickAction" value={FALSE}
                        checked={!this.state.job.slackAction} onChange={this.handleQuickActionSelect} />
                    <label className="radio-label" htmlFor="no_q_action">None</label>

                    <input type="radio" id="add_q_action" className="radio-input" name="slackQuickAction" value={TRUE}
                        checked={!!this.state.job.slackAction} onChange={this.handleQuickActionSelect} />
                    <label className="radio-label" htmlFor="add_q_action">Allow</label>
                </div>

                {this.renderSlackActionForm(this.state.job.slackAction)}

                <div className="form-control float-left p-0 m-2">
                    <button className="btn" onClick={this.addCustomJob} disabled={!this.state.isValid}>
                        <PlusIcon />
                    </button>
                </div>
            </div>
        );
    }
}
