import React, { Component } from "react";
import { ChecklistIcon, SignOutIcon, SettingsIcon } from "@primer/octicons-react";
import Can from "./can";
import { Actions, Resources } from "./shared-interfaces";
import { DateTimeMode } from "./utils";
import ComboBox from "./settings/combo-box";

interface Props {
    displayName: string;
    dateTimeMode: DateTimeMode;
    isAuthenticated: boolean;
    onLogout: (e: React.SyntheticEvent) => void;
    onDateTimeModeChanged: (mode: DateTimeMode) => void;
}

const DATE_TIME_MODES = [
    { value: DateTimeMode.LOCAL, name: "Local" },
    { value: DateTimeMode.UTC, name: "UTC" },
];

export class Header extends Component<Props> {

    render(): JSX.Element {
        if (this.props.isAuthenticated) {
            return (
                <div className="d-flex text-white bg-gray-dark text-shadow-dark" >
                    <div className="p-4 flex-1">
                        <a className="text-white text-shadow-dark px-3" href="/history">RMS</a>
                        <Can action={Actions.UPDATE} resource={Resources.RELEASES}
                            yes={() => (<a className="text-white text-shadow-dark px-3" href="/releases">Releases</a>)} />
                        <a className="text-white text-shadow-dark px-3" href="/services">Services</a>
                        <a className="text-white text-shadow-dark px-3" href="/canary">Canary</a>
                        <a className="text-white text-shadow-dark px-3" href="/argocd">ArgoCD</a>
                        <a className="text-white text-shadow-dark px-3" target="_blank"
                            href="https://fivetran.slab.com/posts/release-management-process-m07yysco">Help</a>
                    </div>
                    <div className="p-3 d-flex">
                        <span className="m-2">Date/Time format: &nbsp;</span>
                        <ComboBox items={DATE_TIME_MODES} width={100} editable={true}
                            selectedValue={this.props.dateTimeMode}
                            onItemClick={(item) => this.props.onDateTimeModeChanged(item.value)} />
                    </div>
                    <div className="p-4 text-white text-shadow-dark">
                        Hi, {this.props.displayName}!
                        <Can action={Actions.READ} resource={Resources.SETTINGS}
                            yes={() => (
                                <a href="/settings" className="text-white text-shadow-dark px-2 tooltipped tooltipped-sw" aria-label="Settings"><SettingsIcon /></a>
                            )} />
                        <a href="" onClick={this.props.onLogout}
                            className="text-white text-shadow-dark px-2 tooltipped tooltipped-sw" aria-label="Log out"><SignOutIcon /></a>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="d-flex bg-gray-dark text-white text-shadow-dark">
                    <div className="p-4"><ChecklistIcon />RMS</div>
                    <div className="p-4 flex-1">
                        <a className="text-white text-shadow-dark px-2" target="_blank"
                            href="https://fivetran.slab.com/posts/release-management-process-m07yysco">Help</a>
                    </div>
                    <div className="p-4">Hi, please login.</div>
                </div>
            );
        }
    }
}
