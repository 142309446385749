import React, { Component } from "react";

interface Props {
    additions: number;
    deletions: number;
}

export default class AdditionsDeletions extends Component<Props> {
    render(): JSX.Element {
        return (
            <span>
                <span className="text-green">+++{this.props.additions}</span>/
                <span className="text-red">---{this.props.deletions}</span>
            </span>
        );
    }
}
