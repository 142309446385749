
import { CheckIcon, XIcon } from "@primer/octicons-react";
import React, { Component } from "react";
import Can from "../can";
import { Actions, Resources } from "../shared-interfaces";

interface Props {
    row: RowObject;
    activate: (row: RowObject) => void;
    deactivate: (row: RowObject) => void;
    visibleTo: AccessRestriction;
}

interface RowObject {
    id?: number;
    isActive: boolean;
}

interface AccessRestriction {
    resource: Resources,
    action: Actions
}

export default class ActivationColumn extends Component<Props> {
    constructor(props: any) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <Can action={this.props.visibleTo.action} resource={this.props.visibleTo.resource}
                yes={() => (
                    <td>{
                        this.props.row.isActive
                            ? (<button className="btn-sm btn-danger m-1 tooltipped tooltipped-sw" aria-label="Deactivate"
                                onClick={() => { this.props.deactivate(this.props.row); }}>
                                <XIcon />
                            </button>)
                            : (<button className="btn-sm btn-outline m-1 tooltipped tooltipped-sw" aria-label="Activate"
                                onClick={() => { this.props.activate(this.props.row); }}>
                                <CheckIcon />
                            </button>)
                    }
                    </td>
                )}
            />
        );
    }
}
