import React, { Component } from "react";
import { TailSpin} from "react-loader-spinner";
import AdditionsDeletions from "./additions-deletions";
import { PersonIcon, TriangleDownIcon, TriangleRightIcon, } from "@primer/octicons-react";
import { Commit as CommitInfo } from "../shared-interfaces";
import CommitContent from "./commit-content";

interface Props {
    commit: CommitInfo;
}

interface CommitState {
    showDetails: boolean;
    isLoading?: boolean;
}

export default class HotifxCommit extends Component<Props, CommitState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDetails: false,
            isLoading: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(e: any): void {
        this.setState({
            showDetails: e.target.open,
        });
    }

    render(): JSX.Element {
        if (!!this.state.isLoading) {
            return (
                <div className="d-flex flex-items-center flex-justify-around">
                    <TailSpin color="#777777" height={50} width={50} />
                </div>
            );
        } else {
            return (
                <div>
                    <details className="details-reset" onToggle={this.toggle}>
                        <summary>
                            <div className="d-flex">
                                <div className="mx-1 my-4">
                                    {this.state.showDetails ? <TriangleDownIcon /> : <TriangleRightIcon />}
                                </div>

                                <div className="flex-1">
                                    <p className="h5 mb-1">
                                        <span>{this.props.commit.summary}</span>
                                    </p>
                                    <div className="no-wrap d-flex flex-items-center mt-1">
                                        <PersonIcon className="mr-1"  /*alt={"@" + this.props.commit.author}*/ />
                                        <div className="f6 text-gray">
                                            &ensp;committed by {this.props.commit.author} on {this.props.commit.time} (<span className="text-bold">{this.props.commit.shortSha}</span>)
                                        </div>
                                    </div>
                                </div>

                                <div className="no-wrap d-flex flex-items-center mt-2 ml-4">
                                    {!!this.props.commit?.diffs?.summary
                                        ? <AdditionsDeletions
                                            additions={this.props.commit.diffs.summary.additions}
                                            deletions={this.props.commit.diffs.summary.deletions}
                                        />
                                        : null}

                                    <div className="flex-1">
                                        {/* This block may be used for useful info */}
                                    </div>

                                    <div className="float-right">
                                        {/* This block may be used for action buttons */}
                                    </div>
                                </div>
                            </div>
                        </summary>
                        <CommitContent commit={this.props.commit} showMessageTab={false} />
                    </details>
                </div>
            );
        }
    }
}
