import React, { Component } from "react";

interface Props { }

export default class CircleCiIcon extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <svg width="12" height="12" viewBox="0 0 1200 1212"
                preserveAspectRatio="xMidYMid meet" className="mr-2">

                <g transform="translate(0.000000,1212.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M5640 12114 c-257 -17 -386 -28 -515 -45 -1218 -156 -2331 -661
                    -3255 -1476 -166 -146 -478 -466 -614 -628 -564 -675 -964 -1431 -1207 -2280
                    -63 -220 -57 -293 32 -390 22 -23 63 -53 92 -66 l52 -24 1280 0 c1235 0 1282
                    1 1329 19 87 33 128 82 218 259 145 285 338 553 563 786 525 542 1221 878
                    1970 951 171 17 503 15 665 -4 712 -85 1350 -388 1862 -884 270 -262 476 -546
                    643 -887 159 -325 246 -609 302 -990 22 -151 25 -614 5 -775 -30 -242 -99
                    -524 -184 -753 -100 -267 -305 -628 -494 -865 -127 -160 -408 -433 -576 -558
                    -667 -499 -1469 -716 -2292 -619 -683 80 -1329 389 -1828 873 -277 269 -463
                    520 -639 864 -63 123 -91 166 -125 197 -86 76 11 71 -1414 71 -1415 0 -1327 4
                    -1408 -66 -47 -40 -89 -119 -98 -184 -11 -79 131 -541 271 -885 552 -1359
                    1591 -2479 2905 -3136 213 -106 393 -185 583 -255 67 -24 132 -49 143 -54 41
                    -17 350 -109 464 -139 218 -56 442 -100 730 -143 133 -19 195 -21 730 -25 643
                    -5 695 -2 1060 58 1656 271 3129 1224 4067 2629 893 1339 1217 2989 897 4574
                    -202 1008 -666 1953 -1348 2746 -121 141 -414 438 -556 564 -990 880 -2204
                    1404 -3520 1521 -166 15 -672 27 -790 19z"/>
                                        <path d="M5720 7480 c-643 -90 -1141 -569 -1245 -1198 -22 -129 -16 -415 10
                    -531 64 -288 214 -553 424 -753 192 -183 426 -306 691 -364 123 -27 448 -27
                    575 0 682 143 1158 723 1159 1411 0 228 -42 419 -134 612 -201 422 -562 708
                    -1015 803 -114 24 -361 34 -465 20z"/>
                </g>
            </svg>
        );
    }
}
