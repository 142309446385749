import React, { Component } from "react";
import { Actions, CanaryRelease, CiJob, getVendorName, JobStatus, Release, ReleaseType, Resources, Service } from "../shared-interfaces";
import { Utils } from "../utils";
import { XIcon, CheckIcon, SyncIcon, HorizontalRuleIcon, NoEntryIcon, CheckCircleIcon } from "@primer/octicons-react";
import { api } from "../api";
import { ac } from "../ac";
import { AppContext } from "../context";
import { toast } from "../notifications/toast";
import { NotificationType } from "../notifications/interfaces";
import { ServiceJobs } from "./services";
import CanaryStatusInfo from "./canary-status-info";

interface Props {
    release: CanaryRelease;
    services: ServiceJobs[];
    index: number;
    onRevert: () => void;
}

interface State {
    isLoading: boolean;
    services: ServiceJobs[];
}

export default class ReleaseInfo extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.canRevertTo = this.canRevertTo.bind(this);
        this.deploymentFooter = this.deploymentFooter.bind(this);
        this.state = { services: this.calculateCurrentServices(props.release.id, props.services), isLoading: false };
    }

    calculateCurrentServices(releaseId: number, services: ServiceJobs[]): ServiceJobs[] {
        const result: ServiceJobs[] = [];
        for (const service of services) {
            const jobs = service.jobs?.filter(x => x.releaseId === releaseId);
            const lastNonCanceledJob = jobs?.find(x => x.status !== JobStatus.CANCELED);
            const isCurrentlyDeployed = lastNonCanceledJob?.status === JobStatus.SUCCESS && service.service.currentVersion == this.props.release.id;
            result.push({ service: service.service, jobs, lastJob: jobs?.[0], isCurrentlyDeployed });
        }
        return result;
    }

    handleRevert(serviceInfo: ServiceJobs): void {
        const service = serviceInfo.service;
        const confirmations = [
            Utils.isNullOrWhitespace(service.revertConfirmationMessage)
             ? `The '${service.displayName}' service will be reverted to the release #${this.props.release.id} from #${service.currentVersion}.\nAre you sure?`
             : `${service.revertConfirmationMessage}\nAre you sure?`
        ];
        if (serviceInfo.lastJob?.status !== JobStatus.SUCCESS) {
            confirmations.push('The last deployment job failed.\nAre you sure?');
        }
        toast.confirm(confirmations, `'${service.displayName}' revert confirmation`, () => this.revertServiceToVersion(service));
    }

    revertServiceToVersion(service: Service): void {
        api.post("services/revert",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({ serviceId: service.id, newVersion: this.props.release.id }))
            .then(_ => {
                toast.show(NotificationType.SUCCESS, `Revert job '${service.revertJobName}' has been triggered successfully.`, true);
            })
            .catch(ex => {
                console.error(ex);
            })
            .finally(() => {
                this.props.onRevert();
            });
    }

    statusIcon(status: JobStatus): JSX.Element {
        switch (status) {
            case JobStatus.SUCCESS:
                return <CheckIcon className="text-green" size={32} />;
            case JobStatus.RUNNING:
                return <SyncIcon size={32} />;
            case JobStatus.CANCELED:
                return <NoEntryIcon size={24} className="text-gray mt-1" />;
            case JobStatus.FAILED:
                return <XIcon className="text-red" size={32} />;
            default:
                return <HorizontalRuleIcon size={32} />;
        }
    }

    label(): JSX.Element {
        let className = '';
        let labelText = `-${this.props.index}`;
        switch (this.props.index) {
            case 0:
                className = 'State--green';
                labelText = 'current';
                break;
            case 1:
                className = 'bg-yellow-6';
                break;
            case 2:
                className = 'bg-orange-5';
                break;
            case 3:
                className = 'bg-red-6';
                break;
            default:
                className = 'bg-orange-9';
                break;
        }
        return <td className={`mt-5 State rounded-2 ${className}`} style={{ position: "absolute", left: '-90px', width: 80 }}>
            {labelText} &gt;
        </td>;
    }

    deploymentInfo(service: ServiceJobs): JSX.Element {
        if (service.lastJob?.status) {
            return <div>
                <div className="col-4 float-left">
                    <span className={"mx-1 tooltipped tooltipped-nw"} aria-label={service.lastJob?.status}>
                        {this.statusIcon(service.lastJob?.status)}
                    </span>
                </div>
                <div className="col-8 float-left mt-1">
                    <a className="link-gray-dark no-underline" target="_blank" href={service.lastJob?.url}>
                        <p className="text-left" style={{ fontSize: 9, whiteSpace: 'pre-wrap' }}>
                            {this.deploymentJobInfo(service.lastJob)}
                        </p >
                    </a>
                </div>
            </div>
        } else {
            return <div className="d-inline-flex m-4">
                {this.statusIcon(service.lastJob?.status)}
            </div>
        }
    }

    deploymentJobInfo(job: CiJob): string {
        switch (job.status) {
            case JobStatus.SUCCESS:
                return this.formatDeployTime('Deployed', job.finishedAt);
            case JobStatus.RUNNING:
                return this.formatDeployTime('Deployment...\nStarted', job.startedAt);
            case JobStatus.CANCELED:
                return this.formatDeployTime('Canceled', job.finishedAt);
            case JobStatus.FAILED:
            default:
                return this.formatDeployTime('Failed', job.finishedAt);
        }
    }

    formatDeployTime(status: string, time: string): string {
        return `${status} ${Utils.formatDateTime(time)}`;
    }

    deploymentFooter(service: ServiceJobs): JSX.Element {
        const ciStatus = this.buildCIStatus(service);
        return (
            <>
                {ciStatus}
                <CanaryStatusInfo service={service.service} release={this.props.release} />
            </>);
    }

    buildCIStatus(service: ServiceJobs): JSX.Element {
        if (service.isCurrentlyDeployed) {
            const tooltip = `${getVendorName(service.service.ciVendor)}: Currently deployed`;
            return <span className="State State--green m-2 tooltipped tooltipped-nw float-left" aria-label={tooltip}>
                <CheckCircleIcon />
            </span>;
        }

        if (this.canRevertTo(service)) {
            const disabled = !ac.hasRoleAccess(this, service.service.revertRequiredRole);
            return <button className=" btn btn-sm btn-danger m-2" type="button" disabled={disabled}
                onClick={() => this.handleRevert(service)} >Revert</button>
        }

        if (!!service.lastJob?.status) {
            return <div className="d-inline-flex m-3" />;
        }

        return null;
    }

    canRevertTo(service: ServiceJobs): boolean {
        return !!service.service.revertJobName &&
            service.jobs?.some(x => x.status === JobStatus.SUCCESS) &&
            ac.hasAccess(this, Actions.CREATE, Resources.REVERTS);
    }

    releaseName(release: Release): string {
        return release.tag ?? `${release.type === ReleaseType.RELEASE ? 'Release' : 'Hotfix'} #${release.id}`;
    }

    render(): JSX.Element {
        return (
            <tr className="border-top border-bottom">
                <td className="py-2 bg-gray-light position-sticky text-left pl-3" style={{ zIndex: 1, outline: '1px solid #e1e4e8' }}>
                    <a className="no-underline link-gray-dark link-hover-blue" href={`/releases/${this.props.release.id}`}>{this.releaseName(this.props.release)}</a>
                    <br />{Utils.formatDateTime(this.props.release.createdAt)}
                    <br />{<span className="text-bold">{this.props.release.sha.substring(0, 8)}</span>}
                </td>
                {this.state.services.map((s: ServiceJobs, index: number) => (
                    <td className="py-2 border bg-gray-light" key={index}>
                        <div className="mx-2">{this.deploymentInfo(s)}</div>
                        <div className="mx-2">{this.deploymentFooter(s)}</div>
                    </td>
                ))}
                {this.label()}
            </tr>);
    }
}

ReleaseInfo.contextType = AppContext;
