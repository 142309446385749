import React, { Component } from 'react';
import { AppContext } from '../context';
import { Cluster, ClusterVendor, CanaryProgressStatus, ClusterResource, canaryStateToString, ClustersResponse, buildArgoCdURL } from '../shared-interfaces';
import { api } from '../api';
import { utc } from 'moment';

interface Props { }

interface State {
    resources: LinkedCanaryResource[];
}

interface LinkedCanaryResource extends ClusterResource {
    clusterUrl: string;
}

export default class CanaryPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { resources: [] };
        this.setDataSource = this.setDataSource.bind(this);
    }

    componentDidMount(): void {
        api.get(`clusters`, { "Accept": "application/json", "Content-Type": "application/json" }).then(
            (r: ClustersResponse) => {
                this.setDataSource(r.clusters, r.resources);
            });
    }

    setDataSource(clusters: Cluster[], resources: ClusterResource[]): void {
        const result: LinkedCanaryResource[] = [];
        for (const resource of resources) {
            const cluster = clusters.find(x => x.id === resource.clusterId);
            resource.state.clusterName = cluster.name;
            result.push({ ...resource, clusterUrl: this.buildClusterUrl(cluster) });
        }
        this.setState({ resources: result });
    }

    buildClusterUrl(cluster: Cluster): string {
        switch (cluster.type) {
            case ClusterVendor.GCP:
                return `https://console.cloud.google.com/kubernetes/clusters/details/${cluster.auth.region}/${cluster.auth.name}/details?project=${cluster.auth.project}`;
            default:
                return '';
        }
    }

    renderCanaryState(resource: LinkedCanaryResource): string {
        return canaryStateToString(resource.state);
    }

    getStatusStyle(status: CanaryProgressStatus): string {
        return `text-${this.getStatusColor(status)}`;
    }

    getStatusColor(status: CanaryProgressStatus): string {
        switch (status) {
            case CanaryProgressStatus.PROGRESS:
                return `blue`;
            case CanaryProgressStatus.SUCCESS:
                return 'green';
            case CanaryProgressStatus.FAILED:
                return 'red';
            case CanaryProgressStatus.UNKNOWN:
            default:
                return `yellow`;
        }
    }

    getItemStyle(resource: LinkedCanaryResource): string {
        let bgColor: string = `bg-${this.getStatusColor(resource.state.status)}-light`;
        return `col-3 d-table-cell float-left p-5 m-5 border rounded-2 ${bgColor}`;
    }

    renderPods(resource: LinkedCanaryResource): string {
        const pods = resource.state.pods;
        const total = pods?.reduce((sum, pod) => sum + pod.count, 0);
        return pods
            ? pods.filter(x => x.count).map(x => `${x.sha} - ${x.count}/${total}`).join('; ')
            : 'UNKNOWN';
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <div className="Box-body">
                    <div className="clearfix">
                        {this.state.resources.map((res, index) => (
                            <div className={this.getItemStyle(res)} key={index}>
                                <div>Cluster: <a href={res.clusterUrl} target="_blank">{res.state.clusterName}</a></div>
                                <div>ArgoCD: <a href={buildArgoCdURL(res.state)} target="_blank">{res.state.nameAnnotation}</a></div>
                                <div>Name: {res.name}</div>
                                <div>Namespace: {res.namespace}</div>
                                <div className={this.getStatusStyle(res.state.status)}>Status: {this.renderCanaryState(res)}</div>
                                <div>Pods: {this.renderPods(res)}</div>
                                <div>RMS service: {res.state.serviceName ?? 'NOT LINKED'}</div>
                                <div>Last updated: {utc(res.updatedAt).format('DD.MM.YYYY HH:mm')}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

CanaryPage.contextType = AppContext;
