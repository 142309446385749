import React, { Component } from "react";
import { CheckIcon, PlusIcon, XIcon } from "@primer/octicons-react";
import Can from "../can";
import { api } from "../api";
import { toast } from "../notifications/toast";
import { Actions, ApiToken, Resources } from "../shared-interfaces";
import { NotificationType } from "../notifications/interfaces";
import ActivationColumn from "./activation-column";

interface State {
    isLoaded: boolean;
    tokens: ApiToken[];
}

interface Props { }

export default class ApiTokens extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { tokens: [], isLoaded: false };
        this.onTokenActivation = this.onTokenActivation.bind(this);
        this.newToken = this.newToken.bind(this);
    }

    componentDidMount(): void {
        api.get("tokens", {}).then(r => {
            this.setState({ isLoaded: true, tokens: r.tokens });
        });
    }

    onTokenActivation(token: ApiToken, newIsActive: boolean): void {
        const endpoint = newIsActive
            ? `tokens/${token.id}/activate`
            : `tokens/${token.id}/deactivate`;

        api.post(endpoint, {})
            .then((r) => {
                this.setState({ tokens: r.tokens, isLoaded: true });
                toast.show(NotificationType.SUCCESS, `API token has been successfully ${newIsActive ? 'activated' : 'deactivated'}`);
            });
    }

    newToken(): void {
        api.post('tokens/new', {})
            .then((r) => {
                this.setState({ tokens: r.tokens, isLoaded: true });
                toast.show(NotificationType.SUCCESS, `New API token has been successfully created`);
            });
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <div className="Box-header">API tokens</div>
                <div className="Box-body scroll-box" style={{ maxHeight: 300 }} >
                    <table>
                        <thead>
                            <tr>
                                <th className="border-right"><div className="mx-3">ID</div></th>
                                <th className="border-right"><div className="mx-3">token</div></th>
                                <th className="border-right"><div className="mx-3">Is active</div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tokens.map((token, index) => (
                                <tr className="border-top" key={index} style={{ height: 40 }}>
                                    <td className="border-right"><div className="mx-3">{token.id}</div></td>
                                    <td className="border-right"><div className="mx-3">{token.token}</div></td>
                                    <td className="border-right">
                                        <div className="mx-3">{token.isActive ? <CheckIcon /> : <XIcon />}</div>
                                    </td>
                                    <ActivationColumn row={token} visibleTo={{ resource: Resources.USERS, action: Actions.DELETE }}
                                        activate={(row) => this.onTokenActivation(row as ApiToken, true)}
                                        deactivate={(row) => this.onTokenActivation(row as ApiToken, false)} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="Box-body">
                    <Can action={Actions.CREATE} resource={Resources.USERS}
                        yes={() =>
                            <div className="clearfix">
                                <div className="form-control float-right p-0 m-2">
                                    <button className="btn btn-primary" onClick={() => this.newToken()}>
                                        <PlusIcon /> New token
                                    </button>
                                </div>
                            </div>}
                    />
                </div>
            </div >
        );
    }
}
