import React, { Component } from "react";
import { IssueClosedIcon, BugIcon } from "@primer/octicons-react";
import { ActionModeChange, Actions, DeploymentPipeline, DeploymentPipelinesRequest, DeploymentPipelinesResponse, HotfixAppMode, ReleaseFreezeSchedule, ReleaseSettings, Resources, RmsReleaseMode, SettingsPropName } from "../shared-interfaces";
import { Utils } from "../utils";
import Can from "../can";
import { api } from "../api";
import { utc } from "moment";

interface Props {
    isAuthenticated: boolean;
    settings: ReleaseSettings;
    onSettingsChanged: (propName: SettingsPropName, modeChange: ActionModeChange) => void;
}

interface State {
    pipelines: DeploymentPipeline[];
    releaseFreezes: ReleaseFreezeSchedule[];
    isLoaded: boolean;
}

export default class AutoReleasesBar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.buildHeader = this.buildHeader.bind(this);
        this.buildReleaseModeButton = this.buildReleaseModeButton.bind(this);
        this.buildHotfixAppModeButton = this.buildHotfixAppModeButton.bind(this);
    }

    componentDidMount(): void {
        const request: DeploymentPipelinesRequest = { includeReleaseFreezes: true }
        api.post(`pipelines`, { "Accept": "application/json", "Content-Type": "application/json" }, request)
        .then((r: DeploymentPipelinesResponse) => {
            this.setState({ isLoaded: true, pipelines: r.pipelines, releaseFreezes: r.releaseFreezes });
        });
    }


    private getReleaseTitle(releaseMode: RmsReleaseMode): string {
        switch (releaseMode) {
            case RmsReleaseMode.FULL_AUTO:
                return `Next release will be created automatically at: ${Utils.getNextReleaseTime(this.props.settings.autoCreationCrons)}`;
            case RmsReleaseMode.AUTO_CREATE:
                return `Next release will be created automatically and awaiting for manual approval at: ${Utils.getNextReleaseTime(this.props.settings.autoCreationCrons)}`;
            case RmsReleaseMode.MANUAL:
            default:
                return 'No scheduled releases';
        }
    }

    private buildPipelinesTitle(releaseMode: RmsReleaseMode): string[] {
        if (releaseMode === RmsReleaseMode.MANUAL) {
            return [];
        }

        const result = [];
        for(const pipeline of this.state.pipelines) {
            if (!pipeline.isActive || !pipeline.isServiceActive) {
                continue;
            }
            result.push(`Pipeline '${pipeline.name}' will be triggered at: ${Utils.getNextReleaseTime(pipeline.schedules)}`);
        }
        return result;
    }

    findActiveReleaseFreeze(schedules: ReleaseFreezeSchedule[]): ReleaseFreezeSchedule | undefined {
        const now = utc();
        return schedules?.find(x => !x.isFinished && utc(x.startAt).isBefore(now) && utc(x.finishAt).isAfter(now));
    }

    buildHeader(): JSX.Element {
        const titles: string[] = [];
        const releaseFreeze = this.findActiveReleaseFreeze(this.state.releaseFreezes);
        const releaseMode = this.props.settings?.releaseMode;
        if (!releaseFreeze) {
            titles.push(this.getReleaseTitle(releaseMode), ...this.buildPipelinesTitle(releaseMode));
        } else {
            titles.push(`Release freeze '${releaseFreeze.name}' is active till ${Utils.formatDateTime(releaseFreeze.finishAt)}`);
        }
        const prefix = titles.length > 1 ? '• ' : '';

        return (
            <div className="d-flex col-xl-8 col-lg-8 col-10 m-3 mx-auto" style={{ backgroundColor: '#E0E0E0' }}>
                <div className="p-4 flex-1">
                    {titles.map((x, index) => (<h4 key={index} className=''>{`${prefix}${x}`}</h4>))}
                </div>
                <div>
                    <Can action={Actions.CREATE} resource={Resources.RELEASES}
                        yes={() => (<>
                            {this.buildReleaseModeButton(releaseMode)}
                            {this.buildHotfixAppModeButton(this.props.settings?.hotfixAppMode)}
                        </>)} />
                </div >
            </div >
        );
    }

    buildReleaseModeButton(mode: RmsReleaseMode): JSX.Element {
        let buttonLabel: string;
        let style: string;
        let modeChange: ActionModeChange;

        switch (mode) {
            case RmsReleaseMode.FULL_AUTO:
                buttonLabel = 'Stop auto releases';
                style = 'btn-danger';
                modeChange = ActionModeChange.TURN_OFF;
                break;
            case RmsReleaseMode.AUTO_CREATE:
            case RmsReleaseMode.MANUAL:
            default:
                buttonLabel = 'Start auto releases';
                style = 'btn-primary';
                modeChange = ActionModeChange.TURN_ON;
                break;
        }

        return (
            <button
                className={`btn btn-large m-3 ${style}`}
                onClick={(_) => this.props.onSettingsChanged(SettingsPropName.RELEASE_CREATION_MODE, modeChange)}
                disabled={!mode || window.location.pathname?.startsWith('/settings')}>
                <IssueClosedIcon />&ensp;{buttonLabel}
            </button>);
    }

    buildHotfixAppModeButton(mode: HotfixAppMode): JSX.Element {
        let buttonLabel: string;
        let style: string;
        let modeChange: ActionModeChange;

        switch (mode) {
            case HotfixAppMode.ACTIONS_AND_INTERCEPT:
            case HotfixAppMode.ACTIONS:
                buttonLabel = 'Disable Hotfix App';
                style = 'btn-danger';
                modeChange = ActionModeChange.TURN_OFF;
                break;
            case HotfixAppMode.DISABLED:
            default:
                buttonLabel = 'Enable Hotfix App';
                style = 'btn-primary';
                modeChange = ActionModeChange.TURN_ON;
                break;
        }

        return (
            <button
                className={`btn btn-large m-3 ${style}`}
                onClick={(_) => this.props.onSettingsChanged(SettingsPropName.HOTFIX_APP_MODE, modeChange)}
                disabled={!mode || window.location.pathname?.startsWith('/settings')}>
                <BugIcon />&ensp;{buttonLabel}
            </button>);
    }

    render(): JSX.Element {
        if (this.props.isAuthenticated && this.state?.isLoaded) {
            return this.buildHeader();
        }
        else {
            return (<div></div>);
        }
    }
}