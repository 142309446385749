import { QuestionIcon } from '@primer/octicons-react';
import React, { Component } from 'react';
import { ac } from '../ac';
import Can from '../can';
import { AppContext } from '../context';
import { Actions, RmsReleaseMode, ReleaseSettings, Resources, HotfixAppMode, GithubRepositoryMode } from '../shared-interfaces';
import NewReleaseSchedule from './new-release-schedule';
import ReleaseSchedule from './release-schedule';

interface Props {
    settings: ReleaseSettings;
    onUpdate: (settings: ReleaseSettings) => void;
}

interface State {
    settings: ReleaseSettings;
    disabled: boolean;
}

const TRUE = "true";
const FALSE = "false";

export default class RmsSettings extends Component<Props, State>  {
    constructor(props: Props) {
        super(props);
        this.state = { settings: props.settings, disabled: true };
        this.handleChange = this.handleChange.bind(this);
        this.deleteCron = this.deleteCron.bind(this);
        this.addNewCron = this.addNewCron.bind(this);
        this.renderRadioChooser = this.renderRadioChooser.bind(this);
    }

    componentDidMount(): void {
        const disabled = !ac.hasAccess(this, Actions.UPDATE, Resources.SETTINGS);
        this.setState({ disabled });
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = this.valueFromEvent(e);
        const settings = this.state.settings as any;
        console.log(`${e.target.name}: ${value}`)
        settings[e.target.name as string] = value;
        this.setState({ settings });
        this.props.onUpdate(this.state.settings);
    }

    valueFromEvent(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): any {
        if (e.target.type === "checkbox") {
            return (e.target as HTMLInputElement).checked;
        }
        const value = e.target.value;
        return value === TRUE || value === FALSE ? value === TRUE : value;
    }

    addNewCron(cron: string): void {
        console.log(`Adding new cron: ${cron}`);
        const settings = this.state.settings;
        if (settings.autoCreationCrons) {
            settings.autoCreationCrons.push(cron);
        } else {
            settings.autoCreationCrons = [cron];
        }
        this.setState({ settings });
        this.props.onUpdate(this.state.settings);
        console.log(`Complete`);
    }

    deleteCron(index: number): void {
        console.log(`Deleting cron #${index}`);
        let crons = [...this.props.settings.autoCreationCrons];
        crons.splice(index, 1);
        this.state.settings.autoCreationCrons = crons;
        this.props.onUpdate(this.state.settings);
    }

    private renderRadioChooser(settingsPropName: string, values: { value: string, name: string, isDefault?: boolean }[]): JSX.Element {
        const currentValue = (this.state.settings as any)[settingsPropName];
        return (
            <div className="radio-group col-lg-6 col-md-5 col-xs-3 p-0 m-2 float-left">
                {values.map((x) => (
                    <div key={x.value}>
                        <input checked={(x.isDefault && !currentValue) || currentValue === x.value} disabled={this.state.disabled}
                            className="radio-input" value={x.value} id={x.value} type="radio" name={settingsPropName} onChange={this.handleChange} />
                        <label className="radio-label" htmlFor={x.value}>{x.name}</label>
                    </div>
                ))}
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <div className="Box-header">RMS settings</div>
                <div className="Box-body">
                    <div className="clearfix">
                        <label htmlFor="minRmcReviews" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Min RMC reviews requered</label>
                        <input type="number" name="minRmcReviews" className="form-control col-lg-7 col-md-6 col-xs-3 p-2 m-2 float-left"
                            disabled={this.state.disabled} value={this.state.settings.minRmcReviews} onChange={this.handleChange} />

                        <label className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Require RMC review for</label>
                        <div className="radio-group float-left p-0 m-2 ">
                            <input type="radio" id="allPRs" className="radio-input" name="requireDangerousApprovalOnly" value={FALSE}
                                disabled={this.state.disabled} checked={!this.state.settings.requireDangerousApprovalOnly} onChange={this.handleChange} />
                            <label className="radio-label" htmlFor="allPRs">All PRs</label>

                            <input type="radio" id="dangerousPrs" className="radio-input" name="requireDangerousApprovalOnly" value={TRUE}
                                disabled={this.state.disabled} checked={!!this.state.settings.requireDangerousApprovalOnly} onChange={this.handleChange} />
                            <label className="radio-label" htmlFor="dangerousPrs">Dangerous PRs only</label>
                        </div>
                    </div>
                </div>
                <div className="Box-body">
                    <div className="clearfix">
                        <label htmlFor="releaseBranchPrefix" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Release branch prefix</label>
                        <input type="text" name="releaseBranchPrefix" className="form-control col-lg-7 col-md-6 col-xs-3 p-2 m-2 float-left"
                            disabled={this.state.disabled} value={this.state.settings.releaseBranchPrefix} onChange={this.handleChange} />

                        <label htmlFor="hotfixBranchPrefix" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Hotfix branch prefix</label>
                        <input type="text" name="hotfixBranchPrefix" className="form-control col-lg-7 col-md-6 col-xs-3 p-2 m-2 float-left"
                            disabled={this.state.disabled} value={this.state.settings.hotfixBranchPrefix} onChange={this.handleChange} />

                        <label className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Release workflow
                            <span className="text-gray tooltipped tooltipped-e ml-1" aria-label="Allow to create new release without waiting for required commit">
                                <QuestionIcon />
                            </span>
                        </label>
                        <div className="radio-group float-left p-0 m-2 ">
                            <input type="radio" id="flowStrict" className="radio-input" name="allowUnsafeWorkflow" value={FALSE}
                                disabled={this.state.disabled} checked={!this.state.settings.allowUnsafeWorkflow} onChange={this.handleChange} />
                            <label className="radio-label" htmlFor="flowStrict">Strict</label>

                            <input type="radio" id="flowUnsafe" className="radio-input" name="allowUnsafeWorkflow" value={TRUE}
                                disabled={this.state.disabled} checked={!!this.state.settings.allowUnsafeWorkflow} onChange={this.handleChange} />
                            <label className="radio-label" htmlFor="flowUnsafe">Unsafe</label>
                        </div>
                    </div>
                </div>
                <div className="Box-body">
                    <div className="clearfix" >
                        <label htmlFor="releaseMode" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Release mode</label>
                        {this.renderRadioChooser('releaseMode', [
                            { value: RmsReleaseMode.MANUAL, name: "Manual", isDefault: true },
                            { value: RmsReleaseMode.AUTO_CREATE, name: "Auto create" },
                            { value: RmsReleaseMode.FULL_AUTO, name: "Auto" },
                        ])}
                        <div className="col-lg-8 col-md-7 col-xs-8 float-left" />

                        <label htmlFor="githubRepoMode" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Github repository</label>
                        {this.renderRadioChooser('githubRepoMode', [
                            { value: GithubRepositoryMode.API, name: "API", isDefault: true },
                            { value: GithubRepositoryMode.LOCAL, name: "Local" }
                        ])}
                        <div className="col-lg-8 col-md-7 col-xs-8 float-left" />
                        
                        <label htmlFor="hotfixAppMode" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Hotfix App mode</label>
                        {this.renderRadioChooser('hotfixAppMode', [
                            { value: HotfixAppMode.DISABLED, name: "Disabled", isDefault: true },
                            { value: HotfixAppMode.ACTIONS, name: "Actions" },
                            { value: HotfixAppMode.ACTIONS_AND_INTERCEPT, name: "Actions & Intercept" }
                        ])}
                        <div className="col-lg-8 col-md-7 col-xs-8 float-left" />

                        {/* notificationOnMessageInterception */}

                        <label htmlFor="slackChannel" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Slack channel</label>

                        <input type="text" name="slackChannel" className="form-control col-lg-6 col-md-5 col-xs-3 p-2 m-2 float-left"
                            placeholder='e.g. "#team_releasemanagement"' disabled={this.state.disabled}
                            value={this.state.settings.slackChannel} onChange={this.handleChange} />
                        <div className="col-lg-8 col-md-7 col-xs-8 float-left" />

                        <label htmlFor="interceptionMessage" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">
                            Interception message
                        </label>

                        <div>
                            <textarea name="interceptionMessage" className="form-control col-lg-6 col-md-5 col-xs-3 p-2 m-2 float-left"
                                placeholder='e.g. "<url_here|Release schedule>"' disabled={this.state.disabled}
                                value={this.state.settings.interceptionMessage} onChange={this.handleChange} style={{ minHeight: 120 }} />
                            <div className="col-lg-8 col-md-7 col-xs-8 float-left" />
                        </div>

                        <label htmlFor="deploymentNotificationMessage" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">
                            Deployment notification message
                        </label>

                        <div>
                            <textarea name="deploymentNotificationMessage" className="form-control col-lg-6 col-md-5 col-xs-3 p-2 m-2 float-left"
                                placeholder='e.g. "<url_here|Release schedule>"' disabled={this.state.disabled}
                                value={this.state.settings.deploymentNotificationMessage} onChange={this.handleChange} style={{ minHeight: 120 }} />
                            <div className="col-lg-8 col-md-7 col-xs-8 float-left" />
                        </div>

                        <label htmlFor="mergeDelay" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Auto-merge delay(min)</label>

                        <input type="number" name="mergeDelay" className="form-control col-lg-6 col-md-5 col-xs-3 p-2 m-2 float-left"
                            disabled={this.state.disabled} value={this.state.settings.mergeDelay} onChange={this.handleChange} />
                        <div className="col-lg-8 col-md-7 col-xs-8 float-left" />

                        <div>
                            {this.props.settings.autoCreationCrons?.map((cron, index) => (
                                <ReleaseSchedule key={index} cron={cron} delete={() => { this.deleteCron(index) }} />
                            ))}
                        </div>

                        <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                            yes={() => (
                                <NewReleaseSchedule onAdd={this.addNewCron} />
                            )}
                        />
                    </div>
                </div>
                <div className="Box-body">
                    <div className="clearfix">
                        <label htmlFor="codeDeliveryUrl" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Code delivery url</label>
                        <input type="text" name="codeDeliveryUrl" className="form-control col-lg-6 col-md-5 col-xs-3 p-2 m-2 float-left"
                            placeholder='e.g. https://yourdomain.cloudfunctions.net/code-delivery' disabled={this.state.disabled}
                            value={this.state.settings.codeDeliveryUrl}
                            onChange={this.handleChange} />
                    </div>
                </div>
            </div>
        );
    }
}

RmsSettings.contextType = AppContext;
