import { NotificationType } from "./notifications/interfaces";
import { toast } from "./notifications/toast";
import { ServerErrorMessage } from "./shared-interfaces";

class API {
    private endpoint: string;
    private noAuthCallback: () => void;

    init(endpoint: string, noAuthCallback: () => void) {
        this.endpoint = endpoint;
        this.noAuthCallback = noAuthCallback;
    }

    get(url: string, headers: any, showError = true): Promise<any> {
        return fetch(`${this.endpoint}/${url}`, { headers })
            .then(r => this.onResult(r, showError));
    }

    post(url: string, headers: any, requestBody?: any, showError = true): Promise<any> {
        const body = this.stringifyBody(requestBody);
        return fetch(`${this.endpoint}/${url}`, {
            method: "POST",
            headers,
            body
        }).then(r => this.onResult(r, showError));
    }

    delete(url: string, headers: any, requestBody?: any, showError = true): Promise<any> {
        const body = this.stringifyBody(requestBody);
        return fetch(`${this.endpoint}/${url}`, {
            method: "DELETE",
            headers,
            body
        }).then(r => this.onResult(r, showError));
    }

    private stringifyBody(body?: any): string | undefined {
        return !body || typeof body === 'string' ? body : JSON.stringify(body);
    }

    private onResult(r: Response, showError: boolean): Promise<any> {
        if (r.status >= 200 && r.status < 300) {
            const contentType = r.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return r.json();
            } else {
                return r.text();
            }
        } else {
            if (r.status === 500) {
                return this.handleServerError(r, showError);
            }
            const reason = r.statusText || r.status?.toString();
            if (r.status === 401) {
                this.noAuthCallback();
            }
            if (showError) {
                toast.show(NotificationType.ERROR, `Error during request: ${reason}`, r.status === 401 || r.status === 403);
            }
            return r.text().then(message => {
                console.error(`Error while loading data: ${reason}\n${message}`);
                return Promise.reject(new ServerError({ message: reason, code: undefined }));
            });
        }
    }

    private handleServerError(r: Response, showError: boolean): Promise<any> {
        return r.json().then(x => {
            let error = new ServerError({ message: r.statusText || r.status?.toString(), code: undefined });
            if (x?.error) {
                if (showError) {
                    handleError(x.error, false);
                }
                error = new ServerError(x.error);
            }
            return Promise.reject(error);
        });
    }
}

export function handleError(error: ServerErrorMessage, autoHide: boolean) {
    if (!error) {
        return;
    }
    if (!!error.code) {
        toast.serverError(error);
    } else {
        toast.show(NotificationType.ERROR, error.message, autoHide);
    }
    console.error(error?.message ?? error);
}

export let api = new API();

export class ServerError extends Error {
    readonly error: ServerErrorMessage;

    constructor(error: ServerErrorMessage) {
        super(error.message);
        this.error = error;
    }
}
