import { Component, ReactNode } from "react";
import { ac } from "./ac";
import { AppContext, AppInfo } from './context';
import { Actions, Resources } from "./shared-interfaces";

interface Props {
    action: Actions;
    resource: Resources;
    yes?: () => ReactNode | null,
    no?: () => ReactNode | null
}

export default class Can extends Component<Props> {
    private check(props: Props): boolean {
        const app = this.context as AppInfo;
        const allowed = ac.hasAccess(app.user.role!, props.action, props.resource);
        return allowed;
    };

    render(): ReactNode {
        if (this.check(this.props)) {
            return this.props.yes ? this.props.yes() : null;
        } else {
            return this.props.no ? this.props.no() : null;
        }
    }
}

Can.contextType = AppContext;
