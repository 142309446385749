import { QuestionIcon, ShieldCheckIcon, ShieldLockIcon, TrashcanIcon } from '@primer/octicons-react';
import React, { Component } from 'react';
import Can from '../can';
import { Actions, CustomCiJob, Resources, SlackActionTTL, SlackQuickAction, actionTtlToString } from '../shared-interfaces';
import { Utils } from '../utils';

interface Props {
    job: CustomCiJob;
    delete: () => void;
}

export default class ServiceCustomJob extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    private renderTooltipText(action: SlackQuickAction): string {
        return [
            `TTL: ${actionTtlToString(action.ttl)}`,
            `Required job name: ${action.ttl === SlackActionTTL.ACTIVE_DEPLOYMENT ? action.requiredJobName : 'NONE'}`,
            `Confirmation message: ${Utils.isNullOrWhitespace(action.confirmationMessage) ? 'DEFAULT' : action.confirmationMessage}`
        ].join('\n');
    }

    render() {
        return (
            <div >
                <label className="col-3 float-left text-right p-2 m-2 border-top" htmlFor="name">
                    Name
                </label>
                <input type="text" name="name" className="form-control col-5 float-left p-2 m-2"
                    value={this.props.job.name} disabled={true} />
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2" htmlFor="ciPipelineTrigger">
                    CI pipeline trigger
                </label>
                <input type="text" name="ciPipelineTrigger" className="form-control col-5 float-left p-2 m-2"
                    value={`${this.props.job.branch}:${this.props.job.ciPipelineTrigger}`} disabled={true} />
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2" htmlFor="userRole">
                    User role and Restictions
                    <span className="tooltipped tooltipped-e" aria-label={this.props.job.isReasonRequired ? 'Reason(Height task) required to submit the job' : 'No reason required'} >
                        {this.props.job.isReasonRequired ? <ShieldLockIcon className="ml-1" /> : <ShieldCheckIcon className="ml-1" />}
                    </span>
                </label>
                <input type="text" name="userRole" className="form-control col-5 float-left p-2 m-2"
                    value={this.props.job.requiredUserRole} disabled={true} />
                <div className="col-5 float-left" />

                <label className="col-3 float-left text-right p-2 m-2" htmlFor="slackAction">
                    Slack quick action
                    {this.props.job.slackAction
                        ? (<span className="tooltipped tooltipped-e" aria-label={this.renderTooltipText(this.props.job.slackAction)} >
                            <QuestionIcon className="ml-1" />
                        </span>)
                        : null}
                </label>
                <input type="text" name="slackAction" className="form-control col-5 float-left p-2 m-2" disabled={true}
                    value={!this.props.job.slackAction ? "NONE" : this.props.job.slackAction.buttonName} />
                {/* <div className="col-4 float-left" /> */}

                <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                    yes={() => (
                        <div className="form-control float-left p-0 m-2">
                            <button className="btn" onClick={this.props.delete}>
                                <TrashcanIcon />
                            </button>
                        </div>
                    )}
                />
                <div className="col-5 float-left" />
            </div>
        );
    }
}
