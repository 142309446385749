import React, { Component } from "react";
import { ac } from "../ac";
import Can from "../can";
import { AppContext } from "../context";
import { Actions, ArgocdSettings, FileFilterInfo, Resources } from "../shared-interfaces";
import FileFilters from "./file-filters";
import NewProjectFilter from "./argocd-new-project";

export interface ArgocdSettingsState {
    disabled: boolean;
}

interface Props {
    settings: ArgocdSettings;
    onUpdate: (settings: ArgocdSettings) => void;
}

export default class ArgocdSettingsForm extends Component<Props, ArgocdSettingsState> {
    constructor(props: Props) {
        super(props);
        this.state = { disabled: true };
        this.addNewProjectFilter = this.addNewProjectFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addNewProjectFilter = this.addNewProjectFilter.bind(this);
        this.deleteProjectFilter = this.deleteProjectFilter.bind(this);
    }

    componentDidMount(): void {
        const disabled = !ac.hasAccess(this, Actions.UPDATE, Resources.SETTINGS);
        this.setState({ disabled: disabled });
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.props.onUpdate({ ...this.props.settings, ...{ [e.target.name]: e.target.value } });
    }

    addNewProjectFilter(f: FileFilterInfo): void {
        console.log(f);
        this.props.onUpdate({ ...this.props.settings, ...{ projects: [...this.props.settings.projects, f] } });
    }

    deleteProjectFilter(index: number): void {
        let filters = [...this.props.settings.projects];
        filters.splice(index, 1);
        this.props.onUpdate({ ...this.props.settings, ...{ projects: filters } });
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <div className="Box-header">ArgoCD integration</div>
                <div className="Box-body">
                    <div className="clearfix">
                        <label htmlFor="url" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">URL</label>
                        <input type="text" name="url" className="form-control col-lg-7 col-md-6 col-xs-3 p-2 m-2 float-left" 
                            disabled={this.state.disabled} value={this.props.settings.url} onChange={this.handleChange} />

                        <label htmlFor="token" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">Token</label>
                        <input type="password" name="token" className="form-control col-lg-7 col-md-6 col-xs-3 p-2 m-2 float-left" 
                            disabled={this.state.disabled} value={this.props.settings.token} onChange={this.handleChange} />
                    </div>
                </div>

                <div className="Box-header">ArgoCD tracked projects</div>
                <div className="Box-body">
                    <FileFilters filters={this.props.settings.projects} onDelete={this.deleteProjectFilter} />
                </div>

                <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                    yes={() => (
                        <div className="Box-body">
                            <NewProjectFilter onAdd={this.addNewProjectFilter} />
                        </div>
                    )}
                />
            </div>
        );
    }
}

ArgocdSettingsForm.contextType = AppContext;
