export enum NotificationType {
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success'
}

export interface NotificationInfo {
    type: NotificationType;
    id: number;
    permanent: boolean;
    text: string;
    url: string;
    urlDescription: string;
}

export interface NotificationRoot {
    showNotification: (type: NotificationType, text: string, url: string, urlDescription: string, autoHide: boolean) => number;
    confirm: (steps: string[], header: string, confirmCallback: () => void) => void;
}
