import React, { Component } from "react";
import { api } from "../api";
import { Commit as CommitInfo } from "../shared-interfaces";
import Commit from "./commit";

interface Props {
    releaseId: number;
}

interface State {
    isLoaded: boolean;
    commits: CommitInfo[];
}

export default class ArchivedRelease extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoaded: false, commits: [] };
    }

    getReleaseCommits(): Promise<State> {
        return api.get(`releases/${this.props.releaseId}/commits`, {});
    }

    componentDidMount(): void {
        this.loadCommits();
    }

    loadCommits(): Promise<void> {
        this.setState({ isLoaded: false });
        return this.getReleaseCommits().then(r => {
            this.setState({
                isLoaded: true, commits: r.commits
            });
        });
    }

    render(): JSX.Element {
        return (
            <div>
                <ul>
                    {this.state.commits.map((c: CommitInfo, index: number) => {
                        return (
                            <li className="Box-row p-1 pl-2" key={index}>
                                {<Commit commit={c} />}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
