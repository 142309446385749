import { AccessControl as AccessControlImpl } from 'accesscontrol'
import { Component } from 'react';
import { AppInfo } from './context';
import { Actions, Resources, roleWeight, User, UserRole } from './shared-interfaces';

class AccessControl {
    private ac: AccessControlImpl;

    constructor() {
        this.ac = new AccessControlImpl();
    }

    init(rules: any): void {
        this.ac.setGrants(rules);
        this.ac.lock();
    }

    hasRole(user: User, requiredRole: UserRole): boolean {
        return roleWeight(user.role) >= roleWeight(requiredRole);
    }

    hasRoleAccess(component: Component, requiredRole: UserRole): boolean {
        const appInfo = component.context as AppInfo;
        if (!appInfo?.user) {
            return false;
        }
        const result = roleWeight(appInfo.user.role) >= roleWeight(requiredRole);
        console.log(`Access for: ${appInfo.user.role}. Required: ${requiredRole}. Result: ${result}`);
        return result;
    }

    hasAccess(roleOrComponent: string | Component, action: Actions, resource: Resources): boolean {
        let role: string;
        if (typeof roleOrComponent !== 'string') {
            role = (roleOrComponent.context as AppInfo)?.user?.role;
        } else {
            role = roleOrComponent;
        }

        try {
            if (!role || !this.ac) {
                return false;
            }
            const permission = this.ac.can(role)[action](resource);
            return permission.granted;
        } catch (error) {
            return false;
        }
    }
}

export let ac = new AccessControl();
