import React, { Component } from "react";
import { GitMergeIcon, FileIcon, FileCodeIcon, FileDiffIcon, CodeReviewIcon, EyeSlashIcon } from "@primer/octicons-react";
import { Commit } from "../shared-interfaces";

interface Props {
    commit: Commit;
}

export default class CommitWarnings extends Component<Props> {
    render(): JSX.Element {
        let summary = this.props.commit.pr.diffs.summary;
        let files = (
            <span className="tooltipped tooltipped-ne" aria-label={`${summary.changedFiles} files`}>
                <FileIcon className={this.props.commit.reviewStatus.tooManyFiles ? "text-red" : "text-green"} />
            </span>
        );

        let lines = (
            <span className="tooltipped tooltipped-ne" aria-label={`${summary.additions + summary.deletions} lines`}>
                <FileDiffIcon className={this.props.commit.reviewStatus.tooManyLines ? "text-red" : "text-green"} />
            </span>
        );

        let reviews = (
            <span className="tooltipped tooltipped-ne" aria-label={`${this.props.commit.pr.reviews.approved} reviews`}>
                <CodeReviewIcon className={this.props.commit.reviewStatus.tooFewReviewers ? "text-red" : "text-green"} />
            </span>
        );

        let reverted;
        if (this.props.commit.pr.isReverted) {
            reverted = (
                <span className="tooltipped tooltipped-ne" aria-label="PR has been reverted">
                    <GitMergeIcon className="text-red" />
                </span>
            );
        } else {
            reverted = "";
        }

        let dangerousFiles;
        if (this.props.commit.reviewStatus.hasDangerousFiles) {
            dangerousFiles = (
                <span className="tooltipped tooltipped-ne" aria-label="Important files">
                    <FileCodeIcon className="text-red" />
                </span>
            );
        } else {
            dangerousFiles = "";
        }

        let dismissedReviews;
        if (this.props.commit.reviewStatus.hasDismissedReviews) {
            dismissedReviews = (
                <span className="tooltipped tooltipped-ne" aria-label="Dismissed reviews" >
                    <EyeSlashIcon className="text-red" />
                </span>
            );
        } else {
            dismissedReviews = "";
        }

        return (
            <span className="mr-2">
                {reverted}
                {dangerousFiles}
                {files}
                {lines}
                {reviews}
                {dismissedReviews}
            </span>
        );
    }
}
