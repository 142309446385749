import React, { Component } from 'react';
import { ShieldCheckIcon, ShieldXIcon } from '@primer/octicons-react';

interface Props {
    status: string;
    userName: string;
}

export default class RmcReviewLabel extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.getName = this.getName.bind(this);
    }

    getName(account: string): string {
        const regExp = /(\S+)@fivetran\.com/;
        const result = regExp.exec(account);
        return !!result && result.length > 1 ? result[1] : account;
    }

    render() {
        return (
            <span className={"State State--small tooltipped tooltipped-n mr-1 mb-1 " + (this.props.status === "approve" ? "State--green" : "State--red")}
                aria-label={this.props.userName + "_" + this.props.status}>
                {this.props.status === "approve" ? <ShieldCheckIcon /> : <ShieldXIcon />}{this.getName(this.props.userName)}
            </span>
        );
    };
}