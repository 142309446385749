import React, { Component } from "react";
import { PlusIcon } from "@primer/octicons-react";
import { FileFilterInfo } from "../shared-interfaces";

interface Props {
    onAdd: (filter: FileFilterInfo) => void;
}

export default class NewProjectFilter extends Component<Props, FileFilterInfo> {
    constructor(props: Props) {
        super(props);
        this.state = { pattern: "", isRegex: false };
        this.handleChange = this.handleChange.bind(this);
        this.addNewFilter = this.addNewFilter.bind(this);
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.name === "isRegEx" ? e.target.value === "true" : e.target.value;
        const name = e.target.name === "isRegEx" ? "isRegex" : e.target.name;
        this.setState({ [name]: value } as any);
    }

    addNewFilter(): void {
        this.props.onAdd({ pattern: this.state.pattern, isRegex: this.state.isRegex });
        this.setState({ pattern: "", isRegex: false });
    }

    render(): JSX.Element {
        return (
            <div className="clearfix">
                <label className="col-xl-1 col-lg-2 float-left text-right p-2 m-2" htmlFor="pattern">Pattern</label>
                <input type="text" className="form-control col-lg-5 col-sm-4 float-left p-2 m-2" name="pattern" value={this.state.pattern} onChange={this.handleChange} />

                <div className="radio-group float-left p-0 m-2 ">
                    <input type="radio" id="projIsRegexTrue" className="radio-input" name="isRegEx" value="true" onChange={this.handleChange} checked={!!this.state.isRegex} />
                    <label className="radio-label" htmlFor="projIsRegexTrue">RegExp</label>

                    <input type="radio" id="projIsRegexFalse" className="radio-input" name="isRegEx" value="false" onChange={this.handleChange} checked={!this.state.isRegex} />
                    <label className="radio-label" htmlFor="projIsRegexFalse">Text</label>
                </div>
                <div className="form-control float-left p-0 m-2">
                    <button className="btn" onClick={this.addNewFilter} disabled={!this.state.pattern}><PlusIcon /></button>
                </div>
            </div>
        );
    }
}
