import { TrashcanIcon } from '@primer/octicons-react';
import React, { Component } from 'react';
import Can from '../can';
import { Actions, Resources } from '../shared-interfaces';

interface Props {
    cron: string;
    delete: () => void;
}

export default class ReleaseSchedule extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div >
                <label htmlFor="autoCreationCronSchedule" className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right">
                    Release schedule
                </label>

                <input type="text" name="autoCreationCronSchedule" className="form-control col-lg-6 col-md-5 col-xs-3 p-2 m-2 float-left"
                    disabled={true} value={this.props.cron} />
                <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                    yes={() => (
                        <div className="form-control float-left p-0 m-2">
                            <button className="btn" onClick={this.props.delete}>
                                <TrashcanIcon />
                            </button>
                        </div>
                    )}
                />
            </div>
        );
    }
}
