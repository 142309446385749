import { HourglassIcon } from "@primer/octicons-react";
import React, { Component } from "react";
import { TailSpin } from "react-loader-spinner";
import { api } from "../api";
import BuildkiteIcon from "../icons/buildkite-icon";
import CircleCiIcon from "../icons/circle-ci-icon";
import { NotificationType } from "../notifications/interfaces";
import { toast } from "../notifications/toast";
import ComboBox from "../settings/combo-box";
import {
    ArchivedReleaseRequest, ArchivedReleaseResponse, CanaryRelease, CiJob, CiVendor, CustomCiJob,
    HEIGHT_URL, HEIGHT_URL_SHORT, parsePauseReason, ReleaseStatus, ReleaseType, Service
} from "../shared-interfaces";
import ReleaseInfo from "./release-info";
import ServiceCustomActions from "./service-custom-actions";

const SERVICES_COUNT: number[] = [7, 14, 28];

interface Props { }

interface State {
    isLoading: boolean;
    services: ServiceJobs[];
    releases: CanaryRelease[];
    releasesCount: number;
}

export interface ServiceJobs {
    service: Service;
    lastJob: CiJob;
    jobs: CiJob[];
    isCurrentlyDeployed?: boolean;
}

export default class Services extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { services: [], releases: [], isLoading: false, releasesCount: SERVICES_COUNT[0] };
        this.load = this.load.bind(this);
        this.executeCustomJob = this.executeCustomJob.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.setReleasesCount = this.setReleasesCount.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    getArchivedReleases(count: number): Promise<ArchivedReleaseResponse> {
        const response: ArchivedReleaseRequest = {
            batchSize: count,
            offset: 0,
            includeTypes: [ReleaseType.RELEASE, ReleaseType.HOTFIX],
            includeStatuses: [ReleaseStatus.DEPLOYED, ReleaseStatus.DEPLOYING, ReleaseStatus.DEPLOYED_WITH_ERRORS]
        };
        return api.post("releases/archived", { "Accept": "application/json", "Content-Type": "application/json" }, response);
    }

    getServices(releaseIds: number[]): Promise<{ services: Service[], jobs: CiJob[] }> {
        console.log(JSON.stringify({ releaseIds }))
        return api.post("services", { "Accept": "application/json", "Content-Type": "application/json" }, JSON.stringify({ releaseIds }));
    }

    load(releasesCount?: number): void {
        console.log('Loading deployed releases');
        this.setState({ isLoading: true });
        this.getArchivedReleases(releasesCount ?? this.state.releasesCount)
            .then(r => {
                console.log('Loading services and jobs');
                const releases = r.releases;
                this.getServices(releases.map(x => x.id)).then(s => {
                    this.setState({ releases, services: this.toServiceJobs(s.services, s.jobs), isLoading: false });
                });
            });
    }

    toServiceJobs(services: Service[], ciJobs: CiJob[]): ServiceJobs[] {
        const result: ServiceJobs[] = [];
        const jobsByService: Map<number, CiJob[]> = new Map();
        if (ciJobs) {
            for (const job of ciJobs) {
                if (jobsByService.has(job.serviceId)) {
                    jobsByService.get(job.serviceId).push(job);
                } else {
                    jobsByService.set(job.serviceId, [job]);
                }
            }
        }
        for (const service of services) {
            if (!service.isActive) {
                continue;
            }
            const jobs = jobsByService.get(service.id);
            console.log(`Service: ${service.id}. Jobs: ${jobs?.length}`);
            result.push({ service, jobs, lastJob: jobs?.[0] });
        }
        return result;
    }

    executeCustomJob(service: Service, job: CustomCiJob) {
        const message = `You are going to trigger '${job.name}' (${job.ciPipelineTrigger}) CI pipeline on '${job.branch}' branch.`;
        let task: string;
        if (!job.isReasonRequired) {
            if (!confirm(`${message} Are you sure?`)) {
                return;
            }
        } else {
            task = prompt(`${message}\nPlease enter related Height task.`);
            const index = task?.match(HEIGHT_URL)?.[1] ?? task?.match(HEIGHT_URL_SHORT)?.[1];
            if (!index) {
                alert('Canceled - wrong value for Height task.');
                return;
            }
        }

        console.log(`Executing custom job '${job.ciPipelineTrigger}' on '${job.branch}' branch. Service: '${service.displayName}'`);
        api.post('services/execute', { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({ job, serviceId: service.id, task }))
            .then(() => {
                toast.show(NotificationType.SUCCESS, `Job '${job.ciPipelineTrigger}' has been triggered succesfully.`);
            });
    }

    setReleasesCount(newCount: number): void {
        if (this.state.releasesCount === newCount) {
            return;
        }
        this.setState({ releasesCount: newCount });
        this.load(newCount);
    }

    vendorIcon(vendor: CiVendor): JSX.Element {
        switch (vendor) {
            case CiVendor.CIRCLE_CI:
                return <CircleCiIcon />
            case CiVendor.BUILDKITE:
                return <BuildkiteIcon />
            default:
                return null;
        }
    }

    renderBody(): JSX.Element {
        if (!!this.state.isLoading) {
            return (
                <div className="d-flex flex-items-center flex-justify-around"
                    style={{ minHeight: "300px" }}>
                    <TailSpin color="#777777" height={50} width={50} />
                </div>
            );
        } else {
            return (
                <div className="Box-header">
                    <div className="d-flex">
                        <p className="h4 py-2">Deployment status for the last&ensp;</p>
                        {this.renderReleaseCountBox()}
                        <p className="h4 py-2">&ensp;releases</p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <table className="border overflow-auto d-block">
                            {this.renderTableHeader()}
                            <tbody>
                                {this.state.releases.map((r: CanaryRelease, index: number) => (
                                    <ReleaseInfo release={r} services={this.state.services} index={index} key={index} onRevert={this.load} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div >
            )
        }
    }

    renderTableHeader(): JSX.Element {
        return (
            <thead>
                <tr className="border-top border-bottom">
                    <th className="p-2 bg-gray-light position-sticky left-0" style={{ zIndex: 1, outline: '1px solid #e1e4e8' }}>
                        <div className="mx-2">Releases</div>
                    </th>
                    {this.state.services.map((s: ServiceJobs, index: number) => (
                        <th className="border bg-gray-light" key={index} >
                            <div className="mx-2 d-flex flex-items-center flex-justify-around">
                                <div>
                                    {this.renderDeploymentPausedTooltip(s.service)}
                                    {this.vendorIcon(s.service.ciVendor)}
                                    {s.service.displayName}
                                </div>
                                <ServiceCustomActions service={s.service} execute={this.executeCustomJob} />
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }

    renderDeploymentPausedTooltip(service: Service): JSX.Element {
        if (!service.pauseReason?.length) {
            return null;
        }

        const reason = parsePauseReason(service.pauseReason);
        const text = reason.taskUrl
            ? `${reason.status} by ${reason.user} on ${reason.date} due to ${reason.taskUrl}`
            : reason.status;

        return (
            <span className="tooltipped tooltipped-e" aria-label={text} >
                <HourglassIcon className="mr-2" />
            </span>
        );
    }

    renderReleaseCountBox(): JSX.Element {
        return (<ComboBox rawValues={SERVICES_COUNT} editable={true} selectedValue={this.state.releasesCount} width={50}
            onItemClick={(item) => this.setReleasesCount(item.value)} />);
    }

    render(): JSX.Element {
        return (
            <div className="col-xl-8 col-lg-8 col-10 m-5 mx-auto position-relative">
                <div>
                    {this.renderBody()}
                </div>
            </div>
        )
    }
}