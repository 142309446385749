export const HEIGHT_URL = /https?:\/\/(?:fivetran.)?height.app\/(?:[\d\w-]*\/)?T-(\d+)/i;
export const HEIGHT_URL_SHORT = /^(?:T-)?(\d+)$/i;

export interface User {
    id: number;
    login: string;
    password?: string;
    isActive: boolean;
    openId?: string;
    fullName?: string;
    displayName: string;
    role: UserRole;
    isBotUser?: boolean;
}

export interface Review {
    id: number;
    commitId: string;
    userId: number;
    userName?: string;
    status: string;
    message?: string;
}

export interface Release {
    id?: number;
    sha: string;
    name: string;
    status: ReleaseStatus;
    type: ReleaseType;
    source: ReleaseSource;
    prevSha: string;
    createdAt?: string;
    messageId?: number;
    requireCommit?: string;
    tag?: string;
    //Hotfix fields
    mergeCommitUrl?: string;
    basedOn?: number;
    latest?: boolean;
}

export interface CanaryRelease extends Release {
    canaryState: NamedCanaryResourceState[];
    pods: PodsStatus[];
}

export interface StatusHistory {
    id?: number;
    releaseId: number;
    user: User;
    stamp: string;
    status: ReleaseStatus;
    initial: boolean;
}

export enum ReleaseActionType {
    REVERT = 'revert',
    DEPLOYMENT_NOTIFICATION = 'deployment_notification',
    CUSTOM_CI_JOB = 'custom_ci_job',
    CREATION_FAILURE = 'creation_failure',
    CREATED_ON_AUTO_RETRY = 'created_on_auto_retry'
}

export enum ReleaseCreationFailureReason {
    NO_NEW_COMMITS = 'no_new_commits',
    NO_REQUIRED_COMMITS = 'no_required_commits',
    RELEASE_CANDIDATE_EXISTS = 'release_candidate_exists',
    AUTO_RELEASE_PAUSED = 'auto_release_paused',
    RELEASE_FREEZE = 'release_freeze',
    OTHER_ERROR = 'other_error'
}

export interface ReleaseActionHistory {
    id: number;
    releaseId: number;
    user: User;
    stamp: string;
    action: ReleaseActionType;
    details: string;
    status: ReleaseStatus;
}

export interface Commit {
    sha: string;
    shortSha: string;
    time: string;
    author: string;
    summary: string;
    body: string;
    pr?: PullRequest;
    prNumber: number;
    rmcReviews: Review[];
    status: CommitStatus;
    diffs?: Diffs;
    reviewStatus?: CommitReviewStatus;
}


export interface CommitReviewStatus {
    tooManyFiles?: boolean;
    tooManyLines?: boolean;
    tooFewReviewers?: boolean;
    hasDangerousFiles?: boolean;
    hasDismissedReviews?: boolean;
    isDangerous?: boolean;
}

export interface PullRequest {
    number: number;
    sha: string;
    commitId: string;
    branch?: string;
    author: Author;
    title: string;
    body: string;
    diffs: Diffs;
    url: string;
    createdAt: string;
    mergedAt: string;
    date: string;
    reviews?: {
        reviews: PrReview[],
        approved: number,
        requested: number
    };
    revertsCommit?: string;
    isReverted?: boolean;
    mergeCommitSha?: string;
    isMerged?: boolean;
    isClosed?: boolean;
    label?: string;
}

export interface Author {
    login: string,
    avatar: string,
    url: string
}

export interface FileDiffs {
    additions: number,
    deletions: number,
    changes: number,
    name: string,
    patch: string,
    isDangerous?: boolean
}

export interface Diffs {
    summary: {
        changedFiles: number,
        additions: number,
        deletions: number,
    },
    changedFiles: FileDiffs[]
}

export interface PrReview {
    author: Author,
    status: string,
    message?: string
}

export interface Settings {
    filterSettings: FileFilterSettings;
    releaseSettings: ReleaseSettings;
    argocdSettings: ArgocdSettings;
}

export interface FileFilterSettings {
    maxLines: number;
    maxFiles: number;
    minReviews: number;
    fileFilters: FileFilterInfo[];
}

export interface FileFilterInfo {
    isRegex: boolean;
    pattern: string;
}

export interface ReleaseSettings {
    requireDangerousApprovalOnly: boolean;
    minRmcReviews: number;
    mergeDelay: number;
    releaseMode: RmsReleaseMode;
    autoCreationCrons: string[];
    slackChannel: string;
    codeDeliveryUrl: string;
    allowUnsafeWorkflow: boolean;
    releaseBranchPrefix: string;
    hotfixBranchPrefix: string;
    hotfixAppMode: HotfixAppMode;
    githubRepoMode: GithubRepositoryMode;
    prevActiveHotfixAppMode?: HotfixAppMode;
    interceptionMessage: string;
    deploymentNotificationMessage: string;
}

export interface ArgocdSettings {
    url: string;
    token: string;
    projects: FileFilterInfo[];
}

export enum CommitStatus {
    EMPTY = 'empty',
    FETCHING = 'fetching',
    FETCHED = 'fetched'
}

export enum ReleaseType {
    RELEASE = 'release',
    HOTFIX = 'hotfix'
}

export enum ActionModeChange {
    TURN_ON = 'turn_on',
    TURN_OFF = 'turn_off'
}

export enum SettingsPropName {
    HOTFIX_APP_MODE = 'hotfix_app_mode',
    RELEASE_CREATION_MODE = 'release_creation_mode'
}

export enum ReleaseStatus {
    NEW = 'new',
    ACCEPTED = 'accepted',
    IN_REVIEW = 'in_review',
    DEPLOYING = 'deploying',
    DEPLOYED = 'deployed',
    DEPLOYED_WITH_ERRORS = 'deployed_with_errors',
    SKIPPED_PAUSED = 'skipped_paused',
    SKIPPED_NO_NEW_COMMITS = 'skipped_no_new_commits',
    SKIPPED_NO_REQUIRED_COMMITS = 'skipped_no_required_commits',
    SKIPPED_ON_ERROR = 'skipped_on_error',
    SKIPPED_RC_EXISTS = 'skipped_rc_exists',
    SKIPPED_RELEASE_FREEZE = 'skipped_release_freeze',
    CANCELED = 'canceled'
}

export const RETRYABLE_STATUSES = [
    ReleaseStatus.SKIPPED_NO_NEW_COMMITS,
    ReleaseStatus.SKIPPED_NO_REQUIRED_COMMITS,
    ReleaseStatus.SKIPPED_ON_ERROR,
    ReleaseStatus.SKIPPED_RC_EXISTS
];

export const SKIPPED_STATUSES = [
    ReleaseStatus.SKIPPED_NO_NEW_COMMITS,
    ReleaseStatus.SKIPPED_NO_REQUIRED_COMMITS,
    ReleaseStatus.SKIPPED_PAUSED,
    ReleaseStatus.SKIPPED_ON_ERROR,
    ReleaseStatus.SKIPPED_RC_EXISTS,
    ReleaseStatus.SKIPPED_RELEASE_FREEZE
];

export const SKIPPED_OR_CANCELED_STATUSES = [
    ReleaseStatus.CANCELED,
    ...SKIPPED_STATUSES
];

export const NOT_DEPLOYED_STATUSES = [
    ReleaseStatus.NEW,
    ReleaseStatus.ACCEPTED,
    ReleaseStatus.IN_REVIEW
];

export enum ReleaseSource {
    MANUAL = 'manual',
    SCHEDULE = 'schedule',
    SLACK = 'slack'
}

export enum JobStatus {
    RUNNING = 'running',
    FAILED = 'failed',
    SUCCESS = 'success',
    CANCELED = 'canceled'
}

export enum RmsReleaseMode {
    MANUAL = 'manual',
    AUTO_CREATE = 'auto_create',
    FULL_AUTO = 'full_auto'
}

export enum HotfixAppMode {
    DISABLED = 'disabled',
    ACTIONS = 'actions',
    ACTIONS_AND_INTERCEPT = 'actions_and_intercept'
}

export enum GithubRepositoryMode {
    LOCAL = 'local',
    API = 'api'
}

export enum ClusterVendor {
    GCP = 'GCP',
    // AZURE = 'AZURE', //not added yet
    // AWS = 'AWS'      //not added yet
}

export enum UserRole {
    GUEST = 'guest',
    ENGINEER = 'engineer',
    RMC = 'rmc',
    SRE = 'sre',
    ADMIN = 'admin'
}

export const MINIMAL_RMC_ROLE = UserRole.RMC;

export function userRolesList(exlcudeRoles?: UserRole | UserRole[]): UserRole[] {
    const excludeList = !exlcudeRoles
        ? []
        : (Array.isArray(exlcudeRoles) ? exlcudeRoles : [exlcudeRoles]);
    const roles: UserRole[] = [];
    for (const role of Object.values(UserRole)) {
        if (!excludeList.includes(role)) {
            roles.push(role);
        }
    }
    return roles;
}

export function roleWeight(role: UserRole): number {
    switch (role) {
        case UserRole.GUEST:
            return 1;
        case UserRole.ENGINEER:
            return 2;
        case UserRole.RMC:
            return 3;
        case UserRole.SRE:
            return 4;
        case UserRole.ADMIN:
            return 5;
        default:
            throw new Error(`Unknown role: '${role}'`);
    }
}

export enum Actions {
    READ = 'read',
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete'
}

export enum Resources {
    RELEASES = 'releases',
    SETTINGS = 'settings',
    USERS = 'users',
    SERVICES = 'services',
    REVERTS = 'reverts'
}

export enum CiVendor {
    CIRCLE_CI = 'circleci',
    BUILDKITE = 'buildkite'
}

export interface CiJob {
    id: number;
    ciVendorId: string;
    buildId: number;
    status: JobStatus;
    serviceId?: number;
    serviceName?: string;
    startedAt: string;
    finishedAt?: string;
    isRevert: boolean;
    ciVendor: CiVendor;
    url: string;
    releaseId?: number;
    ownerTeam?: string;
}

export enum ServerErrorCodes {
    MERGE_CONFLICTS = 1,
    MERGE_UNKNOWN = 2,
    RELEASE_NO_NEW_COMMITS = 3,
    RELEASE_NO_REQUIRED_COMMITS = 4,
    REVERT_INVALID_PARAMETERS = 5,
    REVERT_ALREADY_RUNNING = 6,
    REVERT_INTERNAL_ERROR = 7,
    TRIGGER_CI_JOB_ERROR = 8,
    PUSH_FAILED = 9,
    CUSTOM_JOB_INVOCATION_FAILED = 10,
    GIT_CLEANUP_ERROR = 11,
    CI_VENDOR_NOT_SUPPORTED = 12,
    RELEASE_FREEZE_STARTED = 13
}

export interface ServerErrorMessage {
    message: string;
    url?: string;
    urlDescription?: string;
    code: ServerErrorCodes;
}

export interface Service {
    id: number;
    displayName: string;
    jobName: string;
    currentVersion: number;
    isActive: boolean;
    sendDeploymentNotification: boolean;
    revertJobName?: string;
    revertPipelineTrigger?: string;
    revertRequiredRole?: UserRole;
    revertConfirmationMessage: string;
    customJobs: CustomCiJob[];
    ciVendor: CiVendor;
    pauseReason: string;
    canaryResourceNamespace: string;
    canaryResourceName: string;
}

export interface DeploymentPipeline {
    id?: number;
    name: string;
    slug: string;
    branch: string;
    serviceId: number;
    serviceName?: string;
    serviceVendor?: CiVendor;
    isServiceActive?: boolean;
    schedules: string[];
    isActive: boolean;
}

export interface DeploymentPipelineSchedule {
    scheduledAt: string;
    pipeline: DeploymentPipeline;
}

export interface ReleaseFreezeSchedule {
    id?: number;
    name: string;
    startAt: string;
    finishAt: string;
    isStarted: boolean;
    isFinished: boolean;
}

export interface CustomCiJob {
    ciPipelineTrigger: string;
    branch: string;
    name: string;
    requiredUserRole: UserRole;
    isReasonRequired: boolean;
    slackAction?: SlackQuickAction;
}

export enum SlackActionTTL {
    ACTIVE_DEPLOYMENT = 'active_deployment',
    UNTIL_NEXT_RELEASE = 'until_next_release',
    HOUR_3 = 'hour_3'
}

export interface SlackQuickAction {
    ttl: SlackActionTTL;
    buttonName?: string;
    confirmationMessage?: string;
    requiredJobName?: string;
}

export interface RevertJob {
    id: number;
    releaseFrom: number;
    releaseTo: number;
    releaseToHash?: string;
    serviceId: number;
    startedAt: string;
    requestedBy?: number;
    messageId: number;
    status: JobStatus;
    ciVendor: CiVendor;
}

export interface ApiToken {
    id: number;
    token: string;
    createdBy?: number;
    activatedBy?: number;
    createdAt?: string;
    isActive: boolean;
}

export interface ServiceHistory {
    service: string;
    serviceName: string;
    finishedAt: string;
    releaseId: number;
}

type ClusterBase = {
    id?: number;
    name: string;
    type: ClusterVendor;
}

type GcpCluster = ClusterBase & {
    type: ClusterVendor.GCP;
    auth: GcpClusterAuth;
}

export type GcpClusterAuth = {
    name: string;
    region: string;
    project: string;
}

export type Cluster = GcpCluster;

export interface ClusterResource {
    id?: number;
    clusterId: number;
    name: string;
    namespace: string;
    updatedAt: string;
    state: NamedCanaryResourceState;
}

export interface NamedCanaryResourceState {
    name: string;
    namespace: string;
    nameAnnotation: string;
    clusterId?: number;
    clusterName?: string;
    serviceId?: number;
    serviceName?: string;
    imageSha?: string;
    status: CanaryProgressStatus;
    canaryWeight?: number;
    stepIndex?: number;
    stepsCount?: number;
    pods?: PodInfo[];
    lastFailureAt?: string;
}

export interface PodInfo {
    sha: string;
    count: number;
}

export enum CanaryProgressStatus {
    UNKNOWN,
    SUCCESS,
    FAILED,
    PROGRESS,
}

export function actionTtlToString(ttl: SlackActionTTL): string {
    switch (ttl) {
        case SlackActionTTL.ACTIVE_DEPLOYMENT:
            return 'Active deployment';
        case SlackActionTTL.UNTIL_NEXT_RELEASE:
            return 'Until next release';
        case SlackActionTTL.HOUR_3:
            return '3 hours';
        default:
            throw new Error("Unknown 'SlackActionTTL' value");
    }
}

export function buildPauseReason(status: string, user?: string, date?: string, taskUrl?: string): string {
    return [status, user, date, taskUrl].filter(x => !!x?.length).map(x => x.trim()).join(';');
}

export function parsePauseReason(reason: string): { status: string, user?: string, date?: string, taskUrl?: string } {
    const values = reason?.split(';');
    if (values?.length > 1) {
        return { status: values[0], user: values[1], date: values[2], taskUrl: values[3] };
    } else {
        return { status: reason };
    }
}

export function getVendorName(vendor: CiVendor): string {
    switch (vendor) {
        case CiVendor.CIRCLE_CI:
            return 'CircleCI';
        case CiVendor.BUILDKITE:
            return 'Buildkite';
        default:
            return '';
    }
}

export function buildArgoCdURL(resource: NamedCanaryResourceState): string {
    return `https://argocd.it-fivetran.com/applications/argocd-server-next-gen/${resource.nameAnnotation}?node=argoproj.io/Rollout/${resource.namespace}/${resource.name}/&tab=extension-0`;
}

export function canaryStateToString(state: NamedCanaryResourceState, pod?: PodsStatus): string {
    switch (state.status) {
        case CanaryProgressStatus.PROGRESS:
            if (state.stepIndex === state.stepsCount && pod && pod.count == 0) {
                return 'Complete';
            }
            const progressValue = state.canaryWeight > 0 ? `- ${state.canaryWeight}% ` : '';
            return `In Progress ${progressValue}(Step ${state.stepIndex}/${state.stepsCount ?? '?'})`;
        case CanaryProgressStatus.SUCCESS:
            return 'Complete';
        case CanaryProgressStatus.FAILED:
            return 'Failed';
        case CanaryProgressStatus.UNKNOWN:
        default:
            return `Unknown`;
    }
}

export function getRelatedPodsStatus(canaryResources: ClusterResource[], releaseSha: string): PodsStatus[] {
    const relatedResources = canaryResources.filter(x => x.state.pods?.some(x => releaseSha.startsWith(x.sha)));
    const result: PodsStatus[] = [];
    for (const resource of relatedResources) {
        let total = 0;
        let count = 0;
        for (const pod of resource.state.pods) {
            total += pod.count;
            if (releaseSha.startsWith(pod.sha)) {
                count += pod.count;
            }
        }
        result.push({
            name: resource.name,
            namespace: resource.namespace,
            clusterName: resource.state.clusterName,
            clusterId: resource.clusterId,
            sha: releaseSha.substring(0, 8),
            total,
            count
        });
    }
    return result;
}

export interface PodsStatus {
    name: string;
    namespace: string;
    clusterId: number;
    clusterName: string;
    sha: string;
    count: number;
    total: number;
}

//------------------------ REQUEST/RESPONSE --------------------

export interface ArchivedReleaseRequest {
    batchSize: number;
    offset: number;
    includeTypes: ReleaseType[];
    includeStatuses: ReleaseStatus[];
}

export interface ArchivedReleaseResponse {
    releases: CanaryRelease[];
}

export interface ClustersResponse {
    clusters: Cluster[];
    resources: ClusterResource[];
}

export interface DeploymentPipelinesRequest {
    includeReleaseFreezes?: boolean;
}

export interface DeploymentPipelinesResponse {
    pipelines: DeploymentPipeline[];
    services: Service[];
    releaseFreezes?: ReleaseFreezeSchedule[];
}

export interface ReleaseFreezesResponse {
    schedules: ReleaseFreezeSchedule[];
}

export interface ArgocdProjectsResponse {
    projects: ArgocdProject[];
}

//------------------------ Argocd interfaces --------------------

export function isArgocdAppInFailedState(health: ArgocdHealthStatus): boolean {
    const isHealthy = health === ArgocdHealthStatus.HEALTHY || health === ArgocdHealthStatus.PROGRESSING;
    return !isHealthy;
}

export interface ArgocdProject {
    id?: number;
    name: string;
    uid: string;
    rawData: ArgocdProjectRawData;
}

export interface ArgocdProjectRawData {
    url: string;
    project: ArgocdProjectItem;
    applications: ArgocdApplicationItem[];
}

/**
 * Represents a project item in the list.
 */
export interface ArgocdProjectItem {
    metadata: ArgocdProjectMetadata;
    spec: ArgocdProjectSpec;
}

/**
 * Metadata associated with a project.
 */
interface ArgocdProjectMetadata {
    name: string;
    namespace: string;
    uid: string;
    resourceVersion: string;
    generation: number;
    creationTimestamp: string;
    labels?: Record<string, string>;
    annotations?: Record<string, string>;
}

/**
 * Specification of a project.
 */
interface ArgocdProjectSpec {
    sourceRepos: string[];
    destinations?: {
        server: string;
        namespace: string;
    }[];
    description?: string;
}
/**
 * Represents an individual application item.
 */
export interface ArgocdApplicationItem {
    metadata: ArgocdApplicationMetadata;
    spec: ArgocdApplicationSpec;
    status: ArgocdApplicationStatus;
}

/**
 * Metadata associated with an application.
 */
interface ArgocdApplicationMetadata {
    name: string;
    namespace: string;
    uid: string;
    resourceVersion: string;
    generation: number;
    creationTimestamp: string;
    labels: Record<string, string>;
    annotations: Record<string, string>;
    ownerReferences: ArgocdOwnerReference[];
}

/**
 * Represents an owner reference.
 */
interface ArgocdOwnerReference {
    apiVersion: string;
    kind: string;
    name: string;
    uid: string;
    controller: boolean;
    blockOwnerDeletion: boolean;
}

/**
 * Specification of an application.
 */
interface ArgocdApplicationSpec {
    source: ArgocdApplicationSource;
    destination: {
        server: string;
        namespace: string;
    };
    project: string;
    syncPolicy: ArgocdSyncPolicy;
    ignoreDifferences: ArgocdIgnoreDifference[];
}

/**
 * Source information of an application.
 */
interface ArgocdApplicationSource {
    repoURL: string;
    path: string;
    targetRevision: string;
    plugin: {
        name: string;
        env: Record<string, unknown>[];
    };
}

/**
 * Sync policy of an application.
 */
interface ArgocdSyncPolicy {
    automated: {
        selfHeal: boolean;
    };
    syncOptions: string[];
}

/**
 * Represents an ignore difference.
 */
interface ArgocdIgnoreDifference {
    group: string;
    kind: string;
    name: string;
    namespace: string;
    jsonPointers: string[];
    jqPathExpressions: string[];
}

/**
 * Status of an application.
 */
interface ArgocdApplicationStatus {
    resources: ArgocdResourceStatus[];
    sync: ArgocdSyncStatusItem;
    health: { status: ArgocdHealthStatus };
    history: ArgocdDeploymentHistory[];
    reconciledAt: string;
    operationState: ArgocdOperationState;
    sourceType: string;
    summary: {
        images: string[];
    }
    controllerNamespace: string;
    conditions: ArgocdApplicationCondition[];
}

export interface ArgocdApplicationCondition {
    type: ArgocdApplicationConditionType;
    message: string;
    lastTransitionTime: string;
}

export enum ArgocdApplicationConditionType {
    SYNC_ERROR = 'SyncError',
    SHARED_RESOURCE_WARNING = 'SharedResourceWarning',
    ORPHANED_RESOURCE_WARNING = 'OrphanedResourceWarning',
    INVALID_SPEC_ERROR = 'InvalidSpecError',
    UNKNOWN = 'Unknown'
}

/**
 * Status of a resource.
 */
interface ArgocdResourceStatus {
    version: string;
    kind: string;
    namespace: string;
    name: string;
    status: string;
    group?: string;
    health?: { status: ArgocdHealthStatus, message?: string };
}

/**
 * Sync status of an application.
 */
interface ArgocdSyncStatusItem {
    status: ArgocdSyncStatus;
    comparedTo: Record<string, unknown>;
    revision: string;
}

/**
 * Health status of an application.
 */
export enum ArgocdHealthStatus {
    HEALTHY = 'Healthy',
    DEGRADED = 'Degraded',
    PROGRESSING = 'Progressing',
    SUSPENDED = 'Suspended',
    MISSING = 'Missing',
    UNKNOWN = 'Unknown'
}

export enum ArgocdSyncStatus {
    SYNCED = 'Synced',
    OUT_OF_SYNC = 'OutOfSync',
    UNKNOWN = 'Unknown'
}

/**
 * Deployment history of an application.
 */
interface ArgocdDeploymentHistory {
    deployStartedAt: string;
}

/**
 * Operation state of an application.
 */
interface ArgocdOperationState {
    operation: Record<string, unknown>;
    phase: string;
    message: string;
    syncResult: {
        resources: OperationStateResource[];
    };
    startedAt: string;
    finishedAt: string;
}

interface OperationStateResource {
    group: string;
    version: string;
    kind: string;
    namespace: string;
    name: string;
    status: OperationStateResourceStatus;
    message: string;
    syncPhase: string;
}

export enum OperationStateResourceStatus {
    SYNC_FAILED = 'SyncFailed',
    SYNCED = 'Synced'
}
