import React, { Component } from "react";
import { AlertIcon, ArrowRightIcon, CheckIcon, CircleSlashIcon, EyeIcon, GitMergeIcon, GitPullRequestIcon, SkipIcon, SyncIcon } from "@primer/octicons-react";
import { Release, ReleaseStatus, SKIPPED_STATUSES, ReleaseStatus as Status } from "../shared-interfaces";

interface Props {
    release: Release;
    customName?: (release: Release) => string;
}

export default class ReleaseSummary extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.releaseName = this.releaseName.bind(this);
    }

    releaseStatus(status: Status): JSX.Element {
        if (SKIPPED_STATUSES.includes(status)) {
            return (
                <span className="text-gray tooltipped tooltipped-e" aria-label="Skipped" >
                    <SkipIcon />
                </span>
            )
        }

        switch (status) {
            case Status.DEPLOYED:
                return (
                    <span className="text-purple tooltipped tooltipped-e" aria-label="Deployed on production" >
                        <GitMergeIcon />
                    </span>
                );
            case Status.DEPLOYED_WITH_ERRORS:
                return (
                    <span className="text-purple tooltipped tooltipped-e" aria-label="Depolyed on production. Some services have deployment errors" >
                        <GitMergeIcon />
                    </span>
                );
            case Status.DEPLOYING:
                return (
                    <span className="text-gray tooltipped tooltipped-e" aria-label="Deploying">
                        <GitPullRequestIcon />
                    </span>
                );
            case Status.CANCELED:
                return (
                    <span className="text-red tooltipped tooltipped-e" aria-label="Canceled" >
                        <CircleSlashIcon />
                    </span>
                );
            case Status.IN_REVIEW:
            default: {
                return (
                    <span className="text-green tooltipped tooltipped-e" aria-label="In review" >
                        <EyeIcon />
                    </span>
                );
            }
        }
    }

    deployStatus(status: Status): JSX.Element {
        switch (status) {
            case Status.DEPLOYED:
                return <CheckIcon className="text-green ml-1" />;
            case Status.DEPLOYED_WITH_ERRORS:
                return <AlertIcon className="text-yellow ml-1" />;
            case Status.DEPLOYING:
                return <SyncIcon className="ml-1" />;
            default:
                return null;
        }
    }

    releaseName(release: Release): string {
        let name: string;
        if (this.props.customName) {
            name = this.props.customName(release);
        } else {
            name = this.props.release.tag ?? this.props.release.name;
        }
        if (!name) {
            name = `Release #${release.id}`;
        }
        return `${name.trim()} ${this.releaseNameStatus(release)}`;
    }

    releaseNameStatus(release: Release): string {
        let statusName: string;
        switch (release.status) {
            case ReleaseStatus.SKIPPED_RELEASE_FREEZE:
                statusName = '(SKIPPED - RELEASE FREEZE)';
                break;
            case ReleaseStatus.SKIPPED_NO_NEW_COMMITS:
                statusName = '(SKIPPED - NO NEW COMMITS)';
                break;
            case ReleaseStatus.SKIPPED_NO_REQUIRED_COMMITS:
                statusName = '(SKIPPED - NO REQUIRED COMMITS)';
                break;
            case ReleaseStatus.SKIPPED_ON_ERROR:
                statusName = '(SKIPPED - UNKNOWN ERROR)';
                break;
            case ReleaseStatus.SKIPPED_PAUSED:
                statusName = '(SKIPPED - PAUSED)';
                break;
            case ReleaseStatus.SKIPPED_RC_EXISTS:
                statusName = '(SKIPPED - RC EXISTS)';
                break;
            case ReleaseStatus.CANCELED:
                statusName = '(CANCELED)';
                break;
            default:
                statusName = '';
        }

        return statusName;
    }

    render(): JSX.Element {
        return (
            <div className="d-flex">
                <div className="flex-shrink-0 p-2">
                    {this.releaseStatus(this.props.release.status)}
                </div>
                <div>
                    <a className="h4 link-gray-dark no-underline" href={`/releases/${this.props.release.id}`} >
                        {this.releaseName(this.props.release)}
                    </a>
                    {this.deployStatus(this.props.release.status)}
                    <div className="mt-1 text-small text-gray">
                        {this.props.release.prevSha === this.props.release.sha
                            ? (this.props.release.prevSha)
                            : (<>
                                {this.props.release.prevSha}
                                {" "}<ArrowRightIcon size="small" />{" "}
                                {this.props.release.sha}
                            </>)}
                    </div>
                </div>
                <div className="text-right"></div>
            </div>
        );
    }
}
