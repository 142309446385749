import React, { Component } from "react";
import { GitMergeIcon, MarkGithubIcon, IssueClosedIcon } from "@primer/octicons-react";
import { TailSpin } from "react-loader-spinner";
import ReleaseCommits from "./release-commits";
import ReleaseReviewStats from "./release-review-stats";
import ReleaseSummary from "./release-summary";
import Can from "../can";
import { api } from "../api";
import { Actions, FileFilterSettings, Commit, Release as ReleaseInfo, PullRequest, Resources, Review, Settings, CommitStatus, ReleaseStatus, ReleaseType, NOT_DEPLOYED_STATUSES, SKIPPED_OR_CANCELED_STATUSES } from "../shared-interfaces";
import { Dialog } from "@primer/components";
import { useParams } from "react-router-dom";

interface State {
    isLoaded: boolean;
    release?: ReleaseInfo;
    commits: Commit[];
    rmcReviews: { [key: string]: Review[] };
    stats?: ReleaseStatistics;
    settings?: Settings;
    isRequireCommitDialogOpen: boolean;
    shaToWait?: string;
}

interface ReleaseResponse {
    commits: Commit[],
    release?: ReleaseInfo;
    rmcReviews: Review[];
    settings?: Settings;
}

interface ReleaseStatistics {
    total?: number;
    approved?: number;
    disapproved?: number;
}

interface Props {
    id: string;
}

function ReleaseDetails() {
    const { releaseId } = useParams();
    return (
        <div>
            <Release id={releaseId} />
        </div>
    );
}

export default ReleaseDetails;

class Release extends Component<Props, State> {
    private intervalId: NodeJS.Timeout;
    private id: string;

    constructor(props: Props) {
        super(props);
        this.id = props.id;
        this.state = { isLoaded: false, commits: [], rmcReviews: {}, isRequireCommitDialogOpen: false };
        this.publishRelease = this.publishRelease.bind(this);
        this.cancelRelease = this.cancelRelease.bind(this);
        this.readyToMerge = this.readyToMerge.bind(this);
        this.onReview = this.onReview.bind(this);
        this.onRevert = this.onRevert.bind(this);
        this.getData = this.getData.bind(this);
        this.getCommitReviews = this.getCommitReviews.bind(this);
        this.publishHotifx = this.publishHotifx.bind(this);
    }

    getData(): Promise<ReleaseResponse> {
        return api.get(`releases/${this.id}`, {});
    }

    getCommitReviews(commitSha: string): Promise<{ [key: string]: Review[] }> {
        return api.get(`releases/${this.id}/reviews/${commitSha}`, {});
    }

    getStats(commits: Commit[], rmsReviews: { [key: string]: Review[] }): ReleaseStatistics {
        let total = 0;
        let approved = 0;
        let disapproved = 0;
        if (commits && rmsReviews) {
            total = commits.length;
            commits.forEach(commit => {
                let reviews: Review[] = rmsReviews[commit.sha];
                if (reviews) {
                    reviews.find(r => r.status === "disapprove") ? disapproved++ : approved++;
                }
            });
        }
        const stats = { total, approved, disapproved };
        console.log(stats);
        return stats;
    }

    componentDidMount(): void {
        this.loadData();
        this.intervalId = setInterval(this.updateCommits.bind(this), 20000);
    }

    componentWillUnmount(): void {
        clearInterval(this.intervalId);
    }

    private updateCommits(): void {
        const fetchingCommits = this.state.commits.filter(x => x.status !== CommitStatus.FETCHED).map(x => x.sha);
        if (!fetchingCommits?.length) {
            clearInterval(this.intervalId);
            return;
        }
        console.log(`Update commits content: ${fetchingCommits.length}`)
        api.post(`commits`,
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({ commits: fetchingCommits })).then(r => {
                const updatedCommits: Commit[] = r.commits;
                const commits = this.state.commits;
                for (const commit of commits) {
                    const updated = updatedCommits.find(x => x.sha === commit.sha);
                    if (updated) {
                        commit.pr = updated.pr;
                        commit.status = updated.status;
                    }
                }
                this.setState({ commits });
            });
    }

    applyFilters(commits: Commit[], filters: FileFilterSettings) {
        commits.forEach(commit => {
            if (!!commit.pr?.number) {
                let pr: PullRequest = commit.pr;
                let summary = pr.diffs.summary;
                const tooManyLines = summary.additions + summary.deletions > filters.maxLines;
                const tooManyFiles = summary.changedFiles > filters.maxFiles;
                const tooFewReviewers = pr.reviews.approved < filters.minReviews;
                const hasDismissedReviews = pr.reviews.reviews.some(r => r.status === "DISMISSED");
                let hasDangerousFiles = false;
                pr.diffs.changedFiles.forEach(file => {
                    let isDangerousFile = false;
                    if (filters.fileFilters && filters.fileFilters.length) {
                        isDangerousFile = filters.fileFilters.some(filter => {
                            return filter.isRegex
                                ? file.name.match(new RegExp(filter.pattern))
                                : file.name === filter.pattern;
                        });
                    }
                    file.isDangerous = isDangerousFile;
                    hasDangerousFiles = hasDangerousFiles || isDangerousFile;
                });
                commit.reviewStatus = { tooManyFiles, tooManyLines, tooFewReviewers, hasDangerousFiles, hasDismissedReviews };
                commit.reviewStatus.isDangerous = tooManyFiles || tooManyLines || tooFewReviewers || hasDangerousFiles || hasDismissedReviews;
            } else {
                commit.reviewStatus = { isDangerous: true };
            }
        });
    }

    publishRelease(): void {
        if (this.state.release.type === ReleaseType.HOTFIX) {
            this.setState({ shaToWait: '', isRequireCommitDialogOpen: true });
            return;
        }
        this.setState({ isLoaded: false });
        const id = this.id;
        api.post(`releases/publish`,
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({ id })).finally(() => {
                this.loadData();
            });
    }

    private publishHotifx(isCommitRequired: boolean): void {
        const sha2wait = isCommitRequired ? this.state.shaToWait?.trim() : undefined;
        if (isCommitRequired) {
            if (!sha2wait || sha2wait.length != 40 || !sha2wait.match(/[0-9a-f]{40}/)) {
                alert('Commit sha must only contain the characters `0-9` and `a-f` and be 40 characters long.');
                return;
            }
        }
        this.setState({ isLoaded: false, isRequireCommitDialogOpen: false });
        const id = this.id;
        api.post(`releases/publish`,
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({ sha2wait, id })).finally(() => {
                this.loadData();
            });
    }

    cancelRelease(): void {
        if (!confirm(`Release ${this.state.release.name} will be canceled.\nAre you sure?`)) {
            return;
        }
        this.setState({ isLoaded: false });
        const id = this.id;
        api.post(`releases/${id}/cancel`, {})
            .catch(ex => {
                console.error(ex);
            })
            .finally(() => {
                this.loadData();
            });
    }

    loadData(): Promise<void> {
        this.setState({ isLoaded: false });
        return this.getData().then(r => {
            const release = r.release;
            const commits = r.commits;
            const rmcReviews: { [key: string]: Review[] } = {};
            const settings = r.settings;

            r.rmcReviews.forEach(r => {
                if (rmcReviews[r.commitId]) {
                    rmcReviews[r.commitId].push(r);
                } else {
                    rmcReviews[r.commitId] = [r];
                }
            });
            this.applyFilters(commits, settings.filterSettings);
            this.setState({
                isLoaded: true,
                release,
                commits,
                rmcReviews,
                stats: this.getStats(commits, rmcReviews),
                settings
            });
        });
    }

    onReview(commit: Commit): Promise<void> {
        return this.getCommitReviews(commit.sha).then(res => {
            let rmcReviews: { [key: string]: Review[] } = this.state.rmcReviews;
            rmcReviews[commit.sha] = res.reviews;
            this.setState({ stats: this.getStats(this.state.commits, rmcReviews) });
        });
    }

    onRevert(): Promise<void> {
        return this.loadData();
    }

    readyToMerge(): boolean {
        if (this.state.stats.disapproved) {
            return false;
        }
        const settings = this.state.settings.releaseSettings;
        let commitsToCheck: Commit[] = [];
        if (settings.requireDangerousApprovalOnly) {
            this.state.commits.forEach(c => c.reviewStatus.isDangerous && commitsToCheck.push(c));
        } else {
            commitsToCheck.push(...this.state.commits);
        }
        console.log(commitsToCheck.length);
        for (let c of commitsToCheck) {
            const commitReviews = this.state.rmcReviews[(c.sha)] || [];
            if (commitReviews.length < settings.minRmcReviews)
                return false;
        }
        return this.state.release.status === ReleaseStatus.IN_REVIEW;
    }

    render(): JSX.Element {
        if (!!this.state.isLoaded) {
            if (SKIPPED_OR_CANCELED_STATUSES.includes(this.state.release.status)) {
                return (
                    <div className="Box box-shadow col-xl-8 col-lg-10 m-5 mx-auto">
                        <div className="Box-header position-sticky top-0" style={{ zIndex: 10 }}>
                            <div className="d-flex">
                                <div className="flex-1">
                                    <ReleaseSummary release={this.state.release} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            return (
                <div className="Box box-shadow col-xl-8 col-lg-10 m-5 mx-auto">
                    <div className="Box-header position-sticky top-0" style={{ zIndex: 10 }}>
                        <div className="d-flex">
                            <div className="flex-1">
                                <ReleaseSummary release={this.state.release} />
                            </div>
                            <div>
                                <ReleaseReviewStats
                                    total={this.state.stats.total}
                                    approved={this.state.stats.approved}
                                    rejected={this.state.stats.disapproved} />
                            </div>

                            <div>
                                {!NOT_DEPLOYED_STATUSES.includes(this.state.release.status)
                                    ? (!!this.state.release.mergeCommitUrl
                                        ? <a className="btn btn-primary" href={this.state.release.mergeCommitUrl} target="_blank">
                                            <MarkGithubIcon className="mr-2" />Go to Commit</a>
                                        : null)
                                    : <Can action={Actions.UPDATE} resource={Resources.RELEASES}
                                        yes={() => (
                                            <div>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={this.publishRelease}
                                                    disabled={!this.readyToMerge()}>
                                                    <GitMergeIcon />Merge
                                                </button>
                                                <button
                                                    className="btn btn-danger ml-2"
                                                    onClick={this.cancelRelease}>
                                                    <IssueClosedIcon />Cancel
                                                </button>
                                            </div>
                                        )} />
                                }
                            </div>
                        </div>
                    </div>
                    <Dialog title="PR SHA1 of the fix from `Main` branch is required"
                        isOpen={this.state.isRequireCommitDialogOpen}
                        onDismiss={() => this.setState({ isRequireCommitDialogOpen: false })} aria-labelledby="header-id">
                        <div className="Box">
                            <div className="Box-body">
                                <div className="clearfix" >
                                    <p className="">Please submit PR SHA1 of the fix from `Main` branch to wait for it to be delivered to the `Stable` before creating a new release</p>
                                    <label htmlFor="commitSha" className="col-lg-3 col-md-4 p-2 m-2 float-left text-right">
                                        Commit sha(40)
                                    </label>
                                    <input type="text" name="commitSha" className="form-control col-lg-6 col-md-7 p-2 m-2 float-left text-left"
                                        placeholder='e.g. "1234567890abcdef"'
                                        onChange={e => this.setState({ shaToWait: e.target.value })} />
                                </div>
                            </div>
                            <div className="form-actions my-3 mx-5">
                                <button className="btn btn-primary m-1" type="submit" onClick={() => this.publishHotifx(true)}>Publish</button>
                                <button className="btn btn-danger m-1" type="submit" onClick={() => this.publishHotifx(false)}
                                    disabled={!this.state.settings.releaseSettings.allowUnsafeWorkflow}>Publish without required commit</button>
                                <button className="btn m-1" type="button" onClick={() => this.setState({ isRequireCommitDialogOpen: false })}>Cancel</button>
                            </div>
                        </div>
                    </Dialog>
                    <ReleaseCommits
                        commits={this.state.commits}
                        onReview={this.onReview}
                        onRevert={this.onRevert}
                        rmcReviews={this.state.rmcReviews}
                        release={this.state.release}
                    />
                </div>
            );
        } else {
            return (
                <div
                    className="d-flex flex-items-center flex-justify-around"
                    style={{ minHeight: "300px" }}>
                    <TailSpin color="#777777" height={50} width={50} />
                </div>
            );
        }
    }
}
