import React, { Component } from "react";
import { FileDiffs as FileDiffsInfo } from "../shared-interfaces";

export default class FileDiffs extends Component<FileDiffsInfo> {
    getDiffColor(line: string): string {
        if (line.charAt(0) === "+") {
            return "bg-green-light";
        } else if (line.charAt(0) === "-") {
            return "bg-red-light";
        }
        return "bg-gray-light";
    }

    render(): JSX.Element {
        return (
            <div>
                <span></span>
                <h5>{this.props.name}+++{this.props.additions}/---{this.props.deletions}</h5>
                <pre className="text-small">
                    {this.props.patch
                        ? this.props.patch.split("\n")
                            .map((line: string, index: number) => <span key={index} className={this.getDiffColor(line)}>{line + "\n"}</span>)
                        : "No modified lines"}
                </pre>
                <span></span>
            </div>
        );
    }
}
