import React, { Component } from "react";

interface Props {
    pageCount: number;
    pageRangeDisplayed: number;
    marginPagesDisplayed: number;
    initialPage?: number;
    onPageChange: (page: number) => void;
}

interface State {
    selected: number;
}

export default class Pagination extends Component<Props, State> {
    static defaultProps = {
        pageCount: 10,
        pageRangeDisplayed: 2,
        marginPagesDisplayed: 3
    }

    constructor(props: Props) {
        super(props);

        let initialSelected;
        if (props.initialPage) {
            initialSelected = Math.min(Math.max(0, props.initialPage), props.pageCount - 1);
        } else {
            initialSelected = 0;
        }

        this.state = { selected: initialSelected };
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
    }

    componentDidMount(): void {
        if (this.props.onPageChange) {
            this.props.onPageChange(this.state.selected);
        }
    }

    private handlePreviousPage(evt: React.SyntheticEvent): void {
        this.handlePageSelected(this.state.selected - 1, evt);
    }

    private handleNextPage(evt: React.SyntheticEvent): void {
        this.handlePageSelected(this.state.selected + 1, evt);
    }

    private handlePageClick(index: number, evt: React.SyntheticEvent): void {
        this.handlePageSelected(index, evt);
    }

    private handlePageSelected(selected: number, evt: React.SyntheticEvent): void {
        if (evt.preventDefault) {
            evt.preventDefault();
        }
        if (selected < 0 || selected >= this.props.pageCount || this.state.selected === selected) {
            return;
        }

        this.setState({ selected: selected });
        this.props?.onPageChange(selected);
    }

    private pageElement(pageIndex: number, isSelected: boolean): JSX.Element {
        const pageNumber = pageIndex + 1;
        return isSelected
            ? <em key={pageIndex} aria-current="page">{pageNumber}</em>
            : <a key={pageIndex}
                aria-label={`Page ${pageNumber}`}
                onClick={(evt) => this.handlePageClick(pageIndex, evt)}>
                {pageNumber}
            </a>;
    }

    private generatePages(): JSX.Element[] {
        const children = [];
        const { pageRangeDisplayed, pageCount, marginPagesDisplayed } = this.props;
        const selected = this.state.selected;
        const maxVisible = marginPagesDisplayed * 2 + pageRangeDisplayed + 1;

        if (pageCount <= maxVisible) {
            for (let index = 0; index < pageCount; index++) {
                children.push(this.pageElement(index, index === selected));
            }
        } else {
            let leftSide = pageRangeDisplayed / 2;
            let rightSide = pageRangeDisplayed - leftSide;
            let gapElement;

            // Center range
            if (selected > pageCount - pageRangeDisplayed / 2) {
                rightSide = pageCount - selected;
                leftSide = pageRangeDisplayed - rightSide;
            } else if (selected < pageRangeDisplayed / 2) {
                leftSide = selected;
                rightSide = pageRangeDisplayed - leftSide;
            }
            for (let index = 0; index < pageCount; index++) {
                // Pagination left side
                if (index < marginPagesDisplayed) {
                    children.push(this.pageElement(index, index === selected));
                    continue;
                }

                // Pagination right side
                if (index >= pageCount - marginPagesDisplayed) {
                    children.push(this.pageElement(index, index === selected));
                    continue;
                }

                // center of pagination
                if (index >= selected - leftSide && index <= selected + rightSide) {
                    children.push(this.pageElement(index, index === selected));
                    continue;
                }

                // gap
                if (children[children.length - 1] !== gapElement) {
                    gapElement = (<span key={index} className="gap">…</span>);
                    children.push(gapElement);
                }
            }
        }

        return children;
    }

    render(): JSX.Element {
        return (
            <nav className="paginate-container" aria-label="Pagination">
                <div className="pagination">
                    {this.state.selected < 1
                        ? <span className="previous_page" aria-disabled="true">Previous</span>
                        : <a className="previous_page" aria-label="Previous Page" onClick={this.handlePreviousPage}>Previous</a>
                    }
                    {this.generatePages()}
                    {this.state.selected >= this.props.pageCount - 1
                        ? <span className="next_page" aria-disabled="true">Next</span>
                        : <a className="next_page" aria-label="Next Page" onClick={this.handleNextPage}>Next</a>
                    }
                </div>
            </nav>
        );
    }
}
