import { Component } from "react";
import { ArgocdApplicationCondition, ArgocdApplicationConditionType, ArgocdApplicationItem, ArgocdHealthStatus, ArgocdProject, ArgocdSyncStatus, isArgocdAppInFailedState, OperationStateResourceStatus } from "../shared-interfaces";
import React from "react";
import { Utils } from "../utils";
import { QuestionIcon } from "@primer/octicons-react";

export const CLOUD_LABEL = 'cloud';

export interface ArgocdApplicationGroup {
    name: string;
    project: ArgocdProject;
    health: Record<ArgocdHealthStatus, number>;
    syncStatus: Record<ArgocdSyncStatus, number>;
    conditions: ArgocdApplicationCondition[];
    apps: ArgocdApplicationItem[];
    errors: ArgocdApplicationItem[];
}

interface Props {
    app: ArgocdApplicationGroup;
}

const Colors = {
    BLUE: 'blue',
    GREEN: 'green',
    RED: 'red',
    YELLOW: 'yellow'
};

export default class ArgocdApplicationGroupItem extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    buildArgocdURL(app: ArgocdApplicationGroup): string {
        return `${app.project.rawData.url}/applications?search=${app.name}&proj=${app.project.name}`;
    }

    getStatusStyle(status: ArgocdHealthStatus): string {
        return `text-${this.getStatusColor(status)}`;
    }

    getStatusColor(status: ArgocdHealthStatus): string {
        switch (status) {
            case ArgocdHealthStatus.PROGRESSING:
                return Colors.BLUE;
            case ArgocdHealthStatus.HEALTHY:
                return Colors.GREEN;
            case ArgocdHealthStatus.DEGRADED:
                return Colors.RED;
            case ArgocdHealthStatus.MISSING:
            case ArgocdHealthStatus.SUSPENDED:
            case ArgocdHealthStatus.UNKNOWN:
            default:
                return Colors.YELLOW;
        }
    }

    getSyncStyle(status: ArgocdSyncStatus): string {
        return `text-${this.getSyncColor(status)}`;
    }

    getSyncColor(status: ArgocdSyncStatus): string {
        switch (status) {
            case ArgocdSyncStatus.SYNCED:
                return Colors.GREEN;
            case ArgocdSyncStatus.OUT_OF_SYNC:
                return Colors.YELLOW;
            case ArgocdSyncStatus.UNKNOWN:
            default:
                return Colors.RED;
        }
    }

    getConditionStyle(condition: ArgocdApplicationConditionType): string {
        return `text-${this.getConditionColor(condition)}`;
    }

    getConditionColor(condition: ArgocdApplicationConditionType): string {
        switch (condition) {
            case ArgocdApplicationConditionType.INVALID_SPEC_ERROR:
            case ArgocdApplicationConditionType.SYNC_ERROR:
                return Colors.RED;
            case ArgocdApplicationConditionType.ORPHANED_RESOURCE_WARNING:
            case ArgocdApplicationConditionType.SHARED_RESOURCE_WARNING:
            case ArgocdApplicationConditionType.UNKNOWN:
            default:
                return Colors.YELLOW;
        }
    }

    getItemStyle(app: ArgocdApplicationGroup): string {
        const color = this.getAppOverallStatusColor(this.props.app);
        let bgColor: string = `bg-${color}-light`;
        return `col-3 d-table-cell float-left p-5 m-5 border rounded-2 ${bgColor}`;
    }

    getAppOverallStatusColor(app: ArgocdApplicationGroup): string {
        if (app.errors.some(x => x.status.health.status === ArgocdHealthStatus.DEGRADED)) {
            return Colors.RED;
        }
        if (app.errors.some(x => x.status.health.status === ArgocdHealthStatus.MISSING)) {
            return Colors.YELLOW;
        }
        return Colors.BLUE;
    }

    renderAppsHealth(app: ArgocdApplicationGroup): JSX.Element {
        return (
            <div>
                {Object.entries(app.health).filter(x => x[1] > 0).map(([key, value]) =>
                    (<span key={key} className={this.getStatusStyle(key as ArgocdHealthStatus)}>{key}: {value}&ensp;</span>)
                )}
            </div>
        );
    }

    renderAppsError(app: ArgocdApplicationGroup): JSX.Element {
        if (!app.errors.length) {
            return null;
        }
        return (
            <>
                {app.errors.map((x, index) => this.renderAppErrorElement(x, index, app.project.rawData.url))}
            </>);
    }

    renderAppErrorElement(app: ArgocdApplicationItem, index: number, baseUrl: string): JSX.Element {
        const messages = app.status.resources
            .filter(x => !!x.health?.message && isArgocdAppInFailedState(x.health.status))
            .map(x => `${x.name}: ${x.health.message}`);

        const syncMessage = app.status.operationState?.message;
        if (syncMessage) {
            messages.push(`Sync status: ${syncMessage}`);
        }

        messages.push(...app.status.operationState?.syncResult?.resources
            ?.filter(x => x.status === OperationStateResourceStatus.SYNC_FAILED)
            ?.map(x => `${x.name}(${x.kind}): ${x.message}`) ?? []);

        const url = `${baseUrl}/applications/${app.metadata.namespace}/${app.metadata.name}`;
        const color = this.getStatusColor(app.status.health.status);
        return (
            <div key={index}>
                {this.renderErrorTooltip(messages.join('\n'))}
                <span><a className={`text-${color}`} href={url} target="_blank">{app.metadata.name}</a></span>
            </div>
        );
    }

    private renderErrorTooltip(message: string): JSX.Element {
        if (Utils.isNullOrWhitespace(message)) {
            return <span className={`mr-2 float-left text-left`} ></span>;
        }
        return (
            <span className={`mr-2 tooltipped tooltipped-n float-left text-left`} aria-label={message}
                style={{ whiteSpace: 'pre-wrap' }}>
                <QuestionIcon />
            </span>
        );
    }

    renderAppsSync(app: ArgocdApplicationGroup): JSX.Element {
        return (
            <div>
                {Object.entries(app.syncStatus).filter(x => x[1] > 0).map(([key, value]) =>
                    (<span key={key} className={this.getSyncStyle(key as ArgocdSyncStatus)}>{key}: {value}&ensp;</span>)
                )}
            </div>
        );
    }

    renderRevisions(appsGroup: ArgocdApplicationGroup): JSX.Element {
        const revisions: Record<string, number> = {};
        let total = 0;
        for (const app of appsGroup.apps) {
            const revision = app.status.sync.revision?.substring(0, 7);
            total++;
            if (revision) {
                revisions[revision] = (revisions[revision] || 0) + 1;
            }
        }
        return (
            <div>
                {Object.entries(revisions).map(([key, value]) =>
                    (<span key={key}>{key}: {value}/{total}.   </span>)
                )}
            </div>
        );
    }

    renderClouds(appsGroup: ArgocdApplicationGroup): JSX.Element {
        const revisions: Record<string, number> = {};
        for (const app of appsGroup.apps) {
            const cloud = app.metadata.labels?.[CLOUD_LABEL];
            if (cloud) {
                revisions[cloud] = (revisions[cloud] || 0) + 1;
            }
        }
        return (
            <div>
                Clouds: {Object.entries(revisions).map(([key, value]) =>
                    (<span key={key}>{key} - {value}&ensp;</span>)
                )}
            </div>
        );
    }

    renderConditions(appsGroup: ArgocdApplicationGroup): JSX.Element {
        if (!appsGroup.conditions.length) {
            return <div>Conditions: NO.</div>;
        }
        return (
            <div>
                Conditions: {appsGroup.conditions.map((item, index) =>
                    (<div className={this.getConditionStyle(item.type)} key={index}>{this.conditionNameFromType(item.type)} - {item.message}</div>)
                )}
            </div>
        );
    }

    conditionNameFromType(type: ArgocdApplicationConditionType): string {
        switch (type) {
            case ArgocdApplicationConditionType.INVALID_SPEC_ERROR:
                return 'Invalid Spec';
            case ArgocdApplicationConditionType.ORPHANED_RESOURCE_WARNING:
                return 'Orphaned Resource';
            case ArgocdApplicationConditionType.SHARED_RESOURCE_WARNING:
                return 'Shared Resource';
            case ArgocdApplicationConditionType.SYNC_ERROR:
                return 'Sync Error';
            case ArgocdApplicationConditionType.UNKNOWN:
            default:
                return 'Unknown';
        };
    }

    render(): JSX.Element {
        return (
            <div className={this.getItemStyle(this.props.app)} style={{ minHeight: '350px' }}>
                <div>Apps group: <a href={this.buildArgocdURL(this.props.app)} target="_blank">{this.props.app.name}</a></div>
                <div>Project name: {this.props.app.project.name}</div>
                {this.renderClouds(this.props.app)}
                <div>Revisions:</div>
                {this.renderRevisions(this.props.app)}
                <div>Health:</div>
                {this.renderAppsHealth(this.props.app)}
                {this.renderAppsError(this.props.app)}
                <div>Sync status:</div>
                {this.renderAppsSync(this.props.app)}
                {this.renderConditions(this.props.app)}
            </div>
        );
    }
}