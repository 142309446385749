import React, { Component } from "react";
import { CheckIcon, PencilIcon, PlusIcon, XIcon } from "@primer/octicons-react";
import Can from "../can";
import RoleEdit from "./role-edit";
import { api } from "../api";
import { toast } from "../notifications/toast";
import { Actions, Resources, User, UserRole } from "../shared-interfaces";
import { NotificationType } from "../notifications/interfaces";
import { Dialog } from "@primer/components";
import { Utils } from "../utils";
import { AppContext, AppInfo } from "../context";
import ActivationColumn from "./activation-column";

interface State {
    isLoaded: boolean;
    allUsers: User[];
    isEditOpened: boolean;
    canEditUsers: boolean;
    editUser: User;
    searchValue: string;
    usersToDisplay: User[];
}

interface Props { }

export default class Users extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { allUsers: [], usersToDisplay: [], isLoaded: false, isEditOpened: false, canEditUsers: false, editUser: this.emptyUser(), searchValue: '' };
        this.handleUserDialog = this.handleUserDialog.bind(this);
        this.onUserActivation = this.onUserActivation.bind(this);
        this.setUserRole = this.setUserRole.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.searchUsers = this.searchUsers.bind(this);
    }

    componentDidMount(): void {
        const context = this.context as AppInfo;
        const canEditUsers = context.user.role === UserRole.ADMIN;
        this.setState({ canEditUsers });
        api.get("users", {}).then(r => {
            this.setState({ isLoaded: true, allUsers: r.users }, () => this.searchUsers(this.state.searchValue));
        });
    }

    emptyUser(): User {
        return { login: '', isActive: true, displayName: '', role: UserRole.GUEST } as User;
    }

    handleUserDialog(): void {
        this.setState({ isLoaded: false, isEditOpened: false });
        const user = this.state.editUser;
        api.post(
            "users",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify(user)
        ).then(r => {
            this.setState({ allUsers: r.users, isLoaded: true, editUser: this.emptyUser() }, 
                () => this.searchUsers(this.state.searchValue));
            toast.show(NotificationType.SUCCESS, `User '${user.login}' has been successfully ${user.id ? 'modified' : 'added'}`);
        });
    }

    onUserActivation(user: User, newIsActive: boolean): void {
        const endpoint = newIsActive
            ? `users/${user.id}/activate`
            : `users/${user.id}/deactivate`;

        api.post(endpoint, {})
            .then(() => {
                let users = [...this.state.allUsers];
                users.find(u => u.id === user.id).isActive = newIsActive;
                this.setState({ allUsers: users, isLoaded: true });
                this.searchUsers(this.state.searchValue);
                toast.show(NotificationType.SUCCESS, `User has been successfully ${newIsActive ? 'activated' : 'deactivated'}`);
            });
    }

    setUserRole(editUser: User, newRole: UserRole): void {
        editUser.role = newRole;
        console.log(`New ${newRole} role for ${editUser.login}`);
        this.setState({ editUser });
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const editUser = this.state.editUser as any;
        editUser[e.target.name] = e.target.value;
        this.setState({ editUser });
    }

    searchUsers(searchValue: string): void {
        const users = [];
        if (Utils.isNullOrWhitespace(searchValue)) {
            users.push(...this.state.allUsers);
        } else {
            users.push(...this.state.allUsers.filter(x =>
                `${x.id}` === searchValue
                || x.login?.includes(searchValue)
                || x.displayName?.includes(searchValue)
                || x.fullName?.includes(searchValue)));
        }
        this.setState({ usersToDisplay: users, searchValue: searchValue });
    }

    renderPasswordField(editUser: User): JSX.Element {
        if (!!editUser.openId) {
            return (<div />);
        } else {
            return (
                <div>
                    <label className="col-2 float-left text-right p-2 m-2" htmlFor="password">Password</label>
                    <input disabled={!this.state.canEditUsers} type="password" className="form-control col-5 float-left p-2 m-2"
                        name="password" value={this.state.editUser.password} onChange={this.handleChange} />
                    <div className="col-5 float-left" />
                </div>);
        }
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <Dialog title={this.state.editUser.id ? `Edit '${this.state.editUser.displayName}' user` : 'Create new user'}
                    isOpen={this.state.isEditOpened}
                    onDismiss={() => this.setState({ isEditOpened: false })} aria-labelledby="header-id">
                    <div className="Box">
                        <div className="Box-body">
                            <div className="clearfix">
                                <label className="col-2 float-left text-right p-2 m-2" htmlFor="login">Login</label>
                                <input disabled={!this.state.canEditUsers} type="text" className="form-control col-5 float-left p-2 m-2"
                                    name="login" value={this.state.editUser.login} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                {this.renderPasswordField(this.state.editUser)}

                                <label className="col-2 float-left text-right p-2 m-2" htmlFor="displayName">Display name</label>
                                <input disabled={!this.state.canEditUsers} type="text" className="form-control col-5 float-left p-2 m-2"
                                    name="displayName" value={this.state.editUser.displayName} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-2 float-left text-right p-2 m-2" htmlFor="fullName">Full name</label>
                                <input disabled={!this.state.canEditUsers} type="text" className="form-control col-5 float-left p-2 m-2"
                                    name="fullName" value={this.state.editUser.fullName} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-2 float-left text-right p-2 m-2" htmlFor="Role">Role</label>
                                <div className="col-5 float-left m-2">
                                    <RoleEdit user={this.state.editUser} roleChanged={this.setUserRole} />
                                </div>
                                <div className="col-5 float-left" />
                            </div>
                        </div>
                        <div className="form-actions my-3 mx-5">
                            <button className="btn btn-primary m-1" type="submit" onClick={this.handleUserDialog}>{this.state.editUser.id ? 'Save' : 'Create'}</button>
                            <button className="btn m-1" type="button" onClick={() => this.setState({ isEditOpened: false })}>Cancel</button>
                        </div>
                    </div>
                </Dialog>
                <div className="Box-header">Users</div>
                <div className="Box-body d-flex">
                    <div className="input-group col-xl-5 p-0 m-2">
                        <input className="form-control" type="text" name="search" placeholder="Enter id or login or display name or full name" value={this.state.searchValue} onChange={(e) => this.searchUsers(e.target.value)} />
                    </div>
                    <Can action={Actions.CREATE} resource={Resources.USERS}
                        yes={() =>
                            <div className="p-0 my-2 mr-2" style={{ marginLeft: 'auto' }}>
                                <button className="btn btn-primary" onClick={() => this.setState({ isEditOpened: true, editUser: this.emptyUser() })}>
                                    <PlusIcon /> Add
                                </button>
                            </div>}
                    />
                </div>
                <div className="Box-body scroll-box" style={{ maxHeight: 700, minHeight: 700 }} >
                    <table>
                        <thead>
                            <tr>
                                <th className="border-right"><div className="mx-3">ID</div></th>
                                <th className="border-right"><div className="mx-3">Login</div></th>
                                <th className="border-right"><div className="mx-3">Display name</div></th>
                                <th className="border-right"><div className="mx-3">Full name</div></th>
                                <th className="border-right"><div className="mx-3">Role</div></th>
                                <th className="border-right"><div className="mx-3">Is active</div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.usersToDisplay.map((user, index) => (
                                <tr className="border-top" key={index} style={{ height: 40 }}>
                                    <td className="border-right"><div className="mx-3">{user.id}</div></td>
                                    <td className="border-right"><div className="mx-3">{user.login}</div></td>
                                    <td className="border-right"><div className="mx-3">{user.displayName}</div></td>
                                    <td className="border-right"><div className="mx-3">{user.fullName}</div></td>
                                    <td className="border-right"><div className="mx-3">{user.role}</div></td>
                                    <td className="border-right">
                                        <div className="mx-3">{user.isActive ? <CheckIcon /> : <XIcon />}</div>
                                    </td>
                                    <Can action={Actions.UPDATE} resource={Resources.USERS}
                                        yes={() => (
                                            <td>
                                                <button className="btn-sm btn-primary m-1"
                                                    onClick={() => this.setState({ isEditOpened: true, editUser: { ...user } })}>
                                                    <PencilIcon />
                                                </button>
                                            </td>
                                        )}
                                    />
                                    <ActivationColumn row={user} visibleTo={{ resource: Resources.USERS, action: Actions.DELETE }}
                                        activate={(row) => this.onUserActivation(row as User, true)}
                                        deactivate={(row) => this.onUserActivation(row as User, false)} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}

Users.contextType = AppContext;
