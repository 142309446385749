import React, { Component } from 'react';
import { NotificationInfo, NotificationType } from './interfaces';

interface Props {
    notification: NotificationInfo;
    hideNotification: (notification: NotificationInfo) => void;
}

export class Notification extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.flashClassName.bind(this);
        this.hideNotification = this.hideNotification.bind(this);
    }

    flashClassName(): string {
        let flashStyle: string;
        switch (this.props.notification.type?.toLowerCase()) {
            case NotificationType.ERROR:
                flashStyle = 'flash-error';
                break;
            case NotificationType.SUCCESS:
                flashStyle = 'flash-success';
                break;
            case NotificationType.WARNING:
                flashStyle = 'flash-warn';
                break;
            default:
                flashStyle = '';
                break;
        }
        return `flash m-3 ${flashStyle}`;
    }

    private renderText(notification: NotificationInfo) {
        return notification.text.split("\n").map((line, index) => (
            <div key={index}>{line}</div>
        ));
    }
    private renderUrl(notification: NotificationInfo) {
        return notification.url
            ? <a href={notification.url} target="_blank">{notification.urlDescription ?? notification.url}</a>
            : null;
    }

    private hideNotification(): void {
        this.props.hideNotification(this.props.notification);
    }

    render() {
        return (
            <div className={this.flashClassName()}>
                <div className="d-flex ">
                    <div className="flex-1 text-section">
                        {this.renderText(this.props.notification)}
                        {this.renderUrl(this.props.notification)}
                    </div>

                    {this.props.notification.permanent
                        ? <button onClick={this.hideNotification} className="btn btn-sm primary flash-action flex-self-end" type="submit">Ok</button>
                        : null
                    }
                </div>
            </div>
        );
    }
}