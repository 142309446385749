import React, { Component } from "react";
import { TrashcanIcon } from "@primer/octicons-react";
import Can from "../can";
import { Actions, FileFilterInfo, Resources } from "../shared-interfaces";

interface Props {
    index: number;
    filter: FileFilterInfo;
    onDelete: (index: number) => void;
}

export default class FileFilter extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.delete = this.delete.bind(this);
    }

    delete(): void {
        console.log(`Deleting #${this.props.index}: ${this.props.filter.pattern}`);
        this.props.onDelete(this.props.index);
    }

    render(): JSX.Element {
        return (
            <div className="clearfix">
                <label className="col-xl-1 col-lg-2 float-left text-right p-2 m-2" htmlFor={`pattern-${this.props.index}`}>Pattern</label>
                <input type="text" className="form-control col-lg-5 float-left p-2 m-2" name={`pattern-${this.props.index}`} value={this.props.filter.pattern} disabled />

                <div className="radio-group float-left p-0 m-2 form-control">
                    <input type="radio" className="radio-input" id={`isRegexTrue-${this.props.index}`} name={`isRegex-${this.props.index}`} value='true' checked={!!this.props.filter.isRegex} disabled />
                    <label className="radio-label" htmlFor={`isRegexTrue-${this.props.index}`}>RegExp</label>

                    <input type="radio" className="radio-input" id={`isRegexFalse-${this.props.index}`} name={`isRegex-${this.props.index}`} value='false' checked={!this.props.filter.isRegex} disabled />
                    <label className="radio-label" htmlFor={`isRegexFalse-${this.props.index}`}>Text</label>
                </div>

                <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                    yes={() => (
                        <div className="form-control float-left p-0 m-2">
                            <button className="btn" onClick={this.delete}><TrashcanIcon /></button>
                        </div>
                    )}
                />
            </div>
        );
    }
}
