import { ChevronDownIcon } from '@primer/octicons-react';
import React, { Component } from 'react';
import { ac } from '../ac';
import { AppContext } from '../context';
import ComboBox from '../settings/combo-box';
import { Actions, CustomCiJob, Resources, Service } from '../shared-interfaces';

interface Props {
    service: Service;
    execute: (service: Service, job: CustomCiJob) => void;
}

export default class ServiceCustomActions extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = { isOpen: false };
        this.execute = this.execute.bind(this);
    }

    execute(job: CustomCiJob): void {
        this.props.execute(this.props.service, job);
    }

    render(): JSX.Element {
        if (!this.props.service.customJobs?.length || !ac.hasAccess(this, Actions.CREATE, Resources.REVERTS)) {
            return null;
        }
        const items = this.props.service.customJobs.map(x => ({ name: x.name, value: x, disabled: !ac.hasRoleAccess(this, x.requiredUserRole) }));
        return (
            <ComboBox items={items} selectable={false} alignRight={true} editable={true}
                onItemClick={item => this.execute(item.value)}
                renderSummaryContent={() => <ChevronDownIcon />} summaryClassName="btn p-0 ml-1" />
        );
    }
}

ServiceCustomActions.contextType = AppContext;
