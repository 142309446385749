import React, { Component } from "react";
import { PencilIcon, PlusIcon, QuestionIcon, XIcon } from "@primer/octicons-react";
import Can from "../can";
import { api } from "../api";
import { toast } from "../notifications/toast";
import { Actions, Cluster, ClusterVendor, GcpClusterAuth, Resources } from "../shared-interfaces";
import { NotificationType } from "../notifications/interfaces";
import GcpIcon from "../icons/gcp-icon";
import { Utils } from "../utils";
import { Dialog } from "@primer/components";
import ComboBox from "./combo-box";

interface State {
    isLoaded: boolean;
    clusters: Cluster[];
    isEditOpened: boolean;
    editCluster: Cluster;
}

interface Props { }

const CLUSTER_VENDORS = [ClusterVendor.GCP];

export default class CanaryClusters extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { clusters: [], isLoaded: false, isEditOpened: false, editCluster: this.emptyCluster() };
        this.handleClusterDialog = this.handleClusterDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateEditCluster = this.updateEditCluster.bind(this);
        this.validateAuthFields = this.validateAuthFields.bind(this);
        this.deleteCluster = this.deleteCluster.bind(this);
    }

    componentDidMount(): void {
        api.get(`clusters`, { "Accept": "application/json", "Content-Type": "application/json" }).then(r => {
            this.setState({ isLoaded: true, clusters: r.clusters });
        });
    }

    handleClusterDialog(): void {
        if (!this.validateAuthFields()) {
            alert('All auth fields are mandatory.');
            return;
        }

        this.setState({ isLoaded: false, isEditOpened: false });
        const cluster = this.state.editCluster;
        api.post(
            "clusters",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify(cluster)
        ).then(r => {
            this.setState({ clusters: r.clusters, isLoaded: true, editCluster: this.emptyCluster() });
            toast.show(NotificationType.SUCCESS, `Cluster '${cluster.name}' ${cluster.id ? 'modified' : 'added'} successfully`);
        });
    }

    private validateAuthFields(): boolean {
        const cluster = this.state.editCluster;
        const values = Object.values(cluster.auth);
        return values.every(x => !Utils.isNullOrWhitespace(x));
    }

    deleteCluster(cluster: Cluster): void {
        if (!confirm(`You are going to remove '${cluster.name}' cluster. Changes cannot be reverted. Are you sure?`)) {
            return;
        }
        api.delete(
            "clusters",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify(cluster)
        ).then(r => {
            this.setState({ clusters: r.clusters, isLoaded: true, editCluster: this.emptyCluster() });
            toast.show(NotificationType.SUCCESS, `Cluster '${cluster.name}' has been removed.`);
        });
    }

    emptyCluster(): Cluster {
        return {
            name: '',
            type: ClusterVendor.GCP,
            auth: {
                name: '',
                region: '',
                project: ''
            } as GcpClusterAuth
        } as Cluster;
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        this.updateEditCluster(e.target.name, value);
    }

    updateEditCluster(propName: string, value: any): void {
        const editCluster = this.state.editCluster as any;
        const names = propName.split(';');
        if (names.length === 1) {
            editCluster[propName] = value;
        } else {
            editCluster[names[0]][names[1]] = value;
        }
        this.setState({ editCluster: editCluster });
    }

    deleteClusterColumn(cluster: Cluster): JSX.Element {
        return (
            <td className="border-right">
                <button className="btn-sm btn-danger m-1 tooltipped tooltipped-sw" aria-label="Delete cluster"
                    onClick={() => { this.deleteCluster(cluster); }}>
                    <XIcon />
                </button>
            </td>
        );
    }

    editColumn(cluster: Cluster): JSX.Element {
        return (<Can action={Actions.UPDATE} resource={Resources.SERVICES}
            yes={() => (
                <td>
                    <button className="btn-sm btn-primary m-1"
                        onClick={() => this.setState({ isEditOpened: true, editCluster: { ...cluster } })}>
                        <PencilIcon />
                    </button>
                </td>
            )}
        />);
    }

    clusterIcon(vendor: ClusterVendor): JSX.Element {
        switch (vendor) {
            case ClusterVendor.GCP:
                return <GcpIcon />
            default:
                return null;
        }
    }

    renderAuthBlock(type: ClusterVendor, disabled: boolean): JSX.Element {
        switch (type) {
            case ClusterVendor.GCP:
                return (
                    <>
                        <label className="col-3 float-left text-right p-2 m-2" htmlFor="displayName">Project id</label>
                        <input type="text" className="form-control col-5 float-left p-2 m-2" name="auth;project" disabled={disabled}
                            value={this.state.editCluster.auth.project} onChange={this.handleChange} />
                        <div className="col-5 float-left" />
                        <label className="col-3 float-left text-right p-2 m-2" htmlFor="displayName">Cluster name</label>
                        <input type="text" className="form-control col-5 float-left p-2 m-2" name="auth;name" disabled={disabled}
                            value={this.state.editCluster.auth.name} onChange={this.handleChange} />
                        <div className="col-5 float-left" />
                        <label className="col-3 float-left text-right p-2 m-2" htmlFor="displayName">Cluster region</label>
                        <input type="text" className="form-control col-5 float-left p-2 m-2" name="auth;region" disabled={disabled}
                            value={this.state.editCluster.auth.region} onChange={this.handleChange} />
                        <div className="col-5 float-left" />
                    </>);
            default:
                return null;
        }
    }

    renderAuthTooltip(type: ClusterVendor): JSX.Element {
        switch (type) {
            case ClusterVendor.GCP:
                return (
                    <span className="tooltipped tooltipped-e" aria-label="Please provide following values for the command 'gcloud container clusters get-credentials <cluster_name> --region <cluster_region> --project <project_id>'." >
                        <QuestionIcon className="ml-1" />
                    </span>
                );
            default:
                return null;
        }
    }

    stringifyAuth(cluster: Cluster): string {
        switch (cluster.type) {
            case ClusterVendor.GCP:
                return cluster.auth.name;
            default:
                return null;
        }
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <Dialog title={this.state.editCluster.id ? `Edit '${this.state.editCluster.name}' cluster` : 'Create new cluster'}
                    isOpen={this.state.isEditOpened}
                    onDismiss={() => this.setState({ isEditOpened: false })} aria-labelledby="header-id">
                    <div className="Box">
                        <div className="Box-body">
                            <div className="clearfix">
                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="displayName">Name</label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="name" value={this.state.editCluster.name} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2">Vendor</label>
                                <div className="col-5 float-left p-2 mb-2">
                                    <ComboBox rawValues={CLUSTER_VENDORS}
                                        selectedValue={this.state.editCluster.type} editable={!this.state.editCluster?.id} width={100}
                                        onItemClick={(item) => this.updateEditCluster(`type`, item.value)} />
                                </div>
                                <div className="col-5 float-left" />

                            </div>
                        </div>

                        <div className="Box-header text-bold">Auth: {this.renderAuthTooltip(this.state.editCluster.type)}</div>
                        <div className="Box-body">
                            <div className="clearfix">
                                {this.renderAuthBlock(this.state.editCluster.type, !!this.state.editCluster.id)}
                            </div>
                        </div>

                        <div className="form-actions my-3 mx-5">
                            <button className="btn btn-primary m-1" type="submit" onClick={this.handleClusterDialog}>{this.state.editCluster.id ? 'Save' : 'Create'}</button>
                            <button className="btn m-1" type="button" onClick={() => this.setState({ isEditOpened: false })}>Cancel</button>
                        </div>
                    </div>
                </Dialog>
                <div className="Box-header">Clusters with Canary Deployments</div>
                <div className="Box-body">
                    <table>
                        <thead>
                            <tr>
                                <th className="border-right"><div className="mx-3">Name</div></th>
                                <th className="border-right"><div className="mx-3">Auth</div></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.clusters.map((cluster, index) => (
                                <tr className="border-top" key={index}>
                                    <td className="border-right"><div className="mx-3" style={{ minWidth: 350 }}>{this.clusterIcon(cluster.type)}{cluster.name}</div></td>
                                    <td className="border-right"><div className="mx-3" style={{ minWidth: 250 }}>{this.stringifyAuth(cluster)}</div></td>
                                    {this.editColumn(cluster)}
                                    {this.deleteClusterColumn(cluster)}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
                <div className="Box-body">
                    <Can action={Actions.CREATE} resource={Resources.SERVICES}
                        yes={() =>
                            <div className="clearfix">
                                <div className="form-control float-right p-0 m-2">
                                    <button className="btn btn-primary" onClick={() => this.setState({ isEditOpened: true, editCluster: this.emptyCluster() })}>
                                        <PlusIcon /> Add
                                    </button>
                                </div>
                            </div>}
                    />
                </div>
            </div>
        );
    }
}
