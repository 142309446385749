import moment, { Moment, utc } from "moment";
import parser from "cron-parser";

const LOCAL_STORAGE_DATE_MODE_KEY = 'rms+datetime+mode';

export enum DateTimeMode {
    LOCAL = 'local',
    UTC = 'utc'
}

export class Utils {
    private static mode: DateTimeMode | undefined;
    private static timeZone: string = 'UTC';

    static getDateTimeMode(): DateTimeMode {
        if (!this.mode) {
            let mode = DateTimeMode.LOCAL;
            try {
                mode = localStorage.getItem(LOCAL_STORAGE_DATE_MODE_KEY) as DateTimeMode;
            } catch (error) {
                console.error(`Error getting date time mode: ${error.message}`);
            }
            this.setDateTimeMode(this.isNullOrWhitespace(mode) ? DateTimeMode.LOCAL : mode);
        }
        return this.mode;
    }

    static setDateTimeMode(mode: DateTimeMode): void {
        if (this.mode !== mode) {
            try {
                localStorage.setItem(LOCAL_STORAGE_DATE_MODE_KEY, mode);
            } catch (error) {
                console.error(`Error saving date time mode: ${error.message}`);
            }
        }
        this.mode = mode;
        this.timeZone = mode === DateTimeMode.UTC ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    static isNullOrWhitespace(str: string): boolean {
        return !str || str.trim() === '';
    }

    static getNextReleaseTime(crons: string[]): string {
        if (!crons?.length) {
            return 'release schedule is not added.';
        }

        try {
            const releaseSchedule: number[] = [];
            for (const period of crons) {
                const date = parser.parseExpression(period, { tz: 'GMT' }).next().toDate() as Date;
                releaseSchedule.push(date.getTime());
            }
            if (!releaseSchedule.length) {
                return 'no more releases for current schedule.';
            }

            const nearestTime = new Date(Math.min(...releaseSchedule));
            return this.formatDateTime(nearestTime.toISOString());
        } catch (error) {
            console.warn(`Parsing error from cron format to UTC: ${error?.message ?? error}`);
            return `Invalid date`;
        }
    }

    static formatDate(dateTime: string): string {
        const formatted = this.toMoment(dateTime).format('DD.MM.YYYY');
        return formatted;
    }

    static formatDateTime(dateTime: string, showTimezone = true): string {
        const formatted = this.toMoment(dateTime).format('DD.MM.YYYY HH:mm');
        return showTimezone
            ? `${formatted} ${this.timeZone}`
            : formatted;
    }

    private static toMoment(dateTime: string): Moment {
        return this.mode === DateTimeMode.UTC ? utc(dateTime) : moment(dateTime);
    }
}
