import React, { Component } from "react";
import { FileFilterInfo } from "../shared-interfaces";
import FileFilter from "./file-filter";

interface Props {
    filters: FileFilterInfo[];
    onDelete: (index: number) => void;
}

export default class FileFilters extends Component<Props> {
    render(): JSX.Element {
        return (
            <div>
                {this.props.filters.map((f, index) => (
                    <FileFilter key={index} index={index} filter={f} onDelete={this.props.onDelete} />
                ))}
            </div>
        );
    }
}
