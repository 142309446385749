import React, { Component } from "react";
import { CheckIcon, PencilIcon, PlusIcon, QuestionIcon, XIcon } from "@primer/octicons-react";
import Can from "../can";
import { api } from "../api";
import { toast } from "../notifications/toast";
import { Actions, CiVendor, DeploymentPipeline, DeploymentPipelinesResponse, Resources, Service } from "../shared-interfaces";
import { NotificationType } from "../notifications/interfaces";
import { Dialog } from "@primer/components";
import ComboBox from "./combo-box";
import ActivationColumn from "./activation-column";
import CircleCiIcon from "../icons/circle-ci-icon";
import BuildkiteIcon from "../icons/buildkite-icon";
import ReleaseSchedule from "./release-schedule";
import NewReleaseSchedule from "./new-release-schedule";

interface State {
    isLoaded: boolean;
    pipelines: DeploymentPipeline[];
    services: Service[];
    isEditOpened: boolean;
    editPipeline: DeploymentPipeline;
}

interface Props { }

const TRUE = "true";
const FALSE = "false";

export default class DeploymentPipelines extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { pipelines: [], services: [], isLoaded: false, isEditOpened: false, editPipeline: this.emptyPipeline() };
        this.handlePipelineDialog = this.handlePipelineDialog.bind(this);
        this.onPipelineActivation = this.onPipelineActivation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateEditService = this.updateEditService.bind(this);
        this.deleteReleaseSchedule = this.deleteReleaseSchedule.bind(this);
        this.addReleaseSchedule = this.addReleaseSchedule.bind(this);
    }

    componentDidMount(): void {
        api.post(`pipelines`, { "Accept": "application/json", "Content-Type": "application/json" }).then((r: DeploymentPipelinesResponse) => {
            this.setState({ isLoaded: true, services: r.services, pipelines: r.pipelines });
        });
    }

    handlePipelineDialog(): void {
        this.setState({ isLoaded: false, isEditOpened: false });
        const pipeline = this.state.editPipeline;
        api.post(
            "pipelines/update",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify(pipeline)
        ).then((r: DeploymentPipelinesResponse) => {
            this.setState({ services: r.services, pipelines: r.pipelines, isLoaded: true, editPipeline: this.emptyPipeline() });
            toast.show(NotificationType.SUCCESS, `Deployment pipeline '${pipeline.name}' ${pipeline.id ? 'modified' : 'added'} successfully`);
        });
    }

    emptyPipeline(): DeploymentPipeline {
        return {
            name: '',
            slug: '',
            branch: '',
            serviceId: -1,
            schedules: [],
            isActive: true
        };
    }

    onPipelineActivation(pipeline: DeploymentPipeline, newIsActive: boolean): void {
        const endpoint = newIsActive
            ? `pipelines/${pipeline.id}/activate`
            : `pipelines/${pipeline.id}/deactivate`;

        api.post(endpoint, {})
            .then(() => {
                let pipelines = [...this.state.pipelines];
                pipelines.find(u => u.id === pipeline.id).isActive = newIsActive;
                this.setState({ pipelines: pipelines, isLoaded: true });
                toast.show(NotificationType.SUCCESS, `Deployment pipeline has been successfully ${newIsActive ? 'activated' : 'deactivated'}`);
            });
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.type === "radio"
            ? (e.target.value === TRUE || e.target.value === FALSE ? e.target.value === TRUE : e.target.value)
            : e.target.value;
        this.updateEditService(e.target.name, value);
    }

    updateEditService(propName: string, value: any): void {
        const editPipeline = this.state.editPipeline as any;
        editPipeline[propName] = value;
        this.setState({ editPipeline });
    }

    deleteReleaseSchedule(index: number): void {
        console.log(`Deleting release schedule #${index} for deployment pipeline.`);
        const pipeline = this.state.editPipeline;
        pipeline.schedules.splice(index, 1);
        this.setState({ editPipeline: pipeline });
    }

    addReleaseSchedule(cron: string): void {
        console.log(`Adding new release schedule: ${cron}`);
        const pipeline = this.state.editPipeline;
        pipeline.schedules.push(cron);
        this.setState({ editPipeline: pipeline });
    }

    checkColumn(isChecked: boolean): JSX.Element {
        return (
            <td className="border-right">
                <div className="mx-3">{isChecked ? <CheckIcon /> : <XIcon />}</div>
            </td>
        );
    }

    editColumn(pipeline: DeploymentPipeline): JSX.Element {
        return (<Can action={Actions.UPDATE} resource={Resources.SETTINGS}
            yes={() => (
                <td>
                    <button className="btn-sm btn-primary m-1"
                        onClick={() => this.setState({ isEditOpened: true, editPipeline: { ...pipeline } })}>
                        <PencilIcon />
                    </button>
                </td>
            )}
        />);
    }

    maxWidthStyle(maxWidth: number): React.CSSProperties {
        return { maxWidth, wordWrap: "break-word" };
    }

    vendorIcon(vendor: CiVendor): JSX.Element {
        switch (vendor) {
            case CiVendor.CIRCLE_CI:
                return <CircleCiIcon />
            case CiVendor.BUILDKITE:
                return <BuildkiteIcon />
            default:
                return null;
        }
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <Dialog title={this.state.editPipeline.id ? `Edit '${this.state.editPipeline.name}' deployment pipeline` : 'Create new deployment pipeline'}
                    isOpen={this.state.isEditOpened}
                    onDismiss={() => this.setState({ isEditOpened: false })} aria-labelledby="header-id">
                    <div className="Box">
                        <div className="Box-body">
                            <div className="clearfix">
                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="name">Name</label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="name" value={this.state.editPipeline.name} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="slug">
                                    Slug
                                    <span className="tooltipped tooltipped-e" aria-label="Pipeline slug for triggering via API" >
                                        <QuestionIcon className="ml-1" />
                                    </span></label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="slug" value={this.state.editPipeline.slug} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="branch">
                                    Branch
                                    <span className="tooltipped tooltipped-e" aria-label="CI job runs against specific branch. To use branch from the latest release use `<latest>` keyword." >
                                        <QuestionIcon className="ml-1" />
                                    </span>
                                </label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="branch" value={this.state.editPipeline.branch} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2">Service</label>
                                <div className="col-5 float-left p-2 mb-2">
                                    <ComboBox items={this.state.services.filter(x => x.isActive).map(x => ({ name: x.displayName, value: x.id }))}
                                        selectedValue={this.state.editPipeline.serviceId} editable={!this.state.editPipeline?.id} width={100}
                                        onItemClick={(item) => this.updateEditService(`serviceId`, item.value)} />
                                </div>
                                <div className="col-5 float-left" />
                            </div>
                        </div>
                        <div className="Box-header">Schedules</div>
                        {this.state.editPipeline.schedules.length > 0
                            ? (<div className="Box-body">
                                <div className="clearfix">
                                    <div>
                                        {this.state.editPipeline.schedules.map((schedule, index) => (
                                            <ReleaseSchedule key={index} cron={schedule} delete={() => { this.deleteReleaseSchedule(index) }} />
                                        ))}
                                    </div>
                                </div>
                            </div>)
                            : null
                        }

                        <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                            yes={() => (
                                <div className="Box-body">
                                    <NewReleaseSchedule onAdd={this.addReleaseSchedule} />
                                </div>
                            )}
                        />
                        <div className="form-actions my-3 mx-5">
                            <button className="btn btn-primary m-1" type="submit" onClick={this.handlePipelineDialog}>{this.state.editPipeline.id ? 'Save' : 'Create'}</button>
                            <button className="btn m-1" type="button" onClick={() => this.setState({ isEditOpened: false })}>Cancel</button>
                        </div>
                    </div>
                </Dialog>
                <div className="Box-header">Deployment pipelines</div>
                <div className="Box-body">
                    <table>
                        <thead>
                            <tr>
                                <th className="border-right"><div className="mx-3">Name</div></th>
                                <th className="border-right"><div className="mx-3">Slug</div></th>
                                <th className="border-right"><div className="mx-3">Branch</div></th>
                                <th className="border-right"><div className="mx-3">Schedules</div></th>
                                <th className="border-right"><div className="mx-3">Is active</div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pipelines.map((pipeline, index) => (
                                <tr className="border-top" key={index}>
                                    <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(240)}>{this.vendorIcon(pipeline.serviceVendor)}{pipeline.name}</div></td>
                                    <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(240)}>{pipeline.slug}</div></td>
                                    <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(200)}>{pipeline.branch}</div></td>
                                    <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(200)}>{pipeline.schedules.map((x, si) => (<div key={`${index}_${si}`}>{x}</div>))}</div></td>

                                    {this.checkColumn(pipeline.isActive)}
                                    {this.editColumn(pipeline)}
                                    <ActivationColumn row={pipeline} visibleTo={{ resource: Resources.SETTINGS, action: Actions.DELETE }}
                                        activate={(row) => this.onPipelineActivation(row as DeploymentPipeline, true)}
                                        deactivate={(row) => this.onPipelineActivation(row as DeploymentPipeline, false)} />
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
                <div className="Box-body">
                    <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                        yes={() =>
                            <div className="clearfix">
                                <div className="form-control float-right p-0 m-2">
                                    <button className="btn btn-primary" onClick={() => this.setState({ isEditOpened: true, editPipeline: this.emptyPipeline() })}>
                                        <PlusIcon /> Add
                                    </button>
                                </div>
                            </div>}
                    />
                </div>
            </div>
        );
    }
}
