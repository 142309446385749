import { Component } from "react";
import { InfoIcon, UnverifiedIcon, VerifiedIcon } from "@primer/octicons-react";
import { CanaryProgressStatus, CanaryRelease, canaryStateToString, NamedCanaryResourceState, PodsStatus, Service } from "../shared-interfaces";
import React from "react";
import { Utils } from "../utils";

interface Props {
    service: Service;
    release: CanaryRelease;
}

interface State {
    state: NamedCanaryResourceState[];
    pods: PodsStatus[];
}

interface CanaryRenderInfo {
    icon: JSX.Element;
    label: string;
    bgColor: string;
}

enum CanaryState {
    STABLE,
    IN_PROGRESS,
    HISTORY,
    NONE
}

export default class CanaryStatusInfo extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this.buildState();
        this.canaryHasFailures = this.canaryHasFailures.bind(this);
        this.getCanaryState = this.getCanaryState.bind(this);
    }

    private buildState(): State {
        const service = this.props.service;
        const state = this.props.release.canaryState.filter(x => x.name === service.canaryResourceName && x.namespace === service.canaryResourceNamespace);
        const pods = this.props.release.pods.filter(x => x.name === service.canaryResourceName && x.namespace === service.canaryResourceNamespace);
        return { state, pods };
    }

    private getCanaryState(): CanaryState {
        if (!this.state.state?.length) {
            return CanaryState.NONE;
        }
        const pods = this.state.pods;
        if (pods && pods.every(x => x.count === x.total) && pods.some(x => x.count > 0)) {
            return CanaryState.STABLE;
        } else if (pods && pods.some(x => x.count > 0)) {
            return CanaryState.IN_PROGRESS;
        } else {
            return CanaryState.HISTORY;
        }
    }

    private renderStableIcon(): JSX.Element {
        const info: CanaryRenderInfo = {
            icon: <VerifiedIcon />,
            // bgColor: "State--green",
            bgColor: "bg-green",
            label: `Canary status: 'Stable'`
        };
        return this.renderCanaryInfo(info);
    }

    private renderInProgressIcon(): JSX.Element {
        const bgColor = this.canaryHasFailures() ? 'bg-red-5' : 'bg-blue';
        const info: CanaryRenderInfo = {
            icon: <UnverifiedIcon />,
            bgColor,
            label: `Canary status: 'In Progress'`
        };
        return this.renderCanaryInfo(info);
    }

    private renderHistoryIcon(): JSX.Element {
        const bgColor = this.canaryHasFailures() ? 'bg-red-5' : 'bg-green';
        const label = `Last reported canary statuses:\n${this.state.state.map(x => this.getHistoryStateDescription(x))}`;

        const info: CanaryRenderInfo = {
            icon: <InfoIcon />,
            bgColor,
            label
        };
        return this.renderCanaryInfo(info);
    }

    private getHistoryStateDescription(state: NamedCanaryResourceState): string {
        const description = `${state.clusterName}: ${canaryStateToString(state)}`;
        return state.lastFailureAt ? `${description}\nLast failure at: ${Utils.formatDateTime(state.lastFailureAt)}` : description;
    }

    private canaryHasFailures(): boolean {
        const canaryState = this.state.state;
        return canaryState.some(x => x.status === CanaryProgressStatus.FAILED || x.status === CanaryProgressStatus.UNKNOWN)
            || canaryState.some(x => !!x.lastFailureAt);
    }

    private renderCanaryInfo(info: CanaryRenderInfo): JSX.Element {
        const tooltipText = `${info.label}.\n${this.state.pods.filter(x => x.count > 0).map(x => this.readablePodStatus(x)).join('\n')}`;
        return (
            <span className={`State m-2 tooltipped tooltipped-n float-left text-left ${info.bgColor}`} aria-label={tooltipText}
                style={{ whiteSpace: 'pre-wrap' }}>
                {info.icon}
            </span>
        );
    }

    private readablePodStatus(pod: PodsStatus): string {
        return `${pod.clusterName ? `${pod.clusterName}\n` : ''}${pod.sha} - ${pod.count}/${pod.total}`;
    }

    render(): JSX.Element {
        const canaryState = this.getCanaryState();
        switch (canaryState) {
            case CanaryState.STABLE:
                return this.renderStableIcon();
            case CanaryState.IN_PROGRESS:
                return this.renderInProgressIcon();
            case CanaryState.HISTORY:
                return this.renderHistoryIcon();
            case CanaryState.NONE:
            default:
                return null;
        }
    }
}