import React, { Component } from "react";
import { Avatar } from "@primer/components";
import CommitWarnings from "./commit-warnings";
import { PersonIcon, SkipIcon, XIcon, CheckIcon, QuestionIcon, TriangleDownIcon, TriangleRightIcon, } from "@primer/octicons-react";
import { AppContext } from "../context";
import PrActions from "./pr-actions";
import { Commit, CommitStatus, NOT_DEPLOYED_STATUSES, PrReview, PullRequest, Release, Review } from "../shared-interfaces";
import CommitContent from "./commit-content";
import { ThreeDots } from "react-loader-spinner";
import { Utils } from "../utils";

export interface CommitProps {
    onReview?: (commit: Commit) => Promise<void>;
    onRevert?: (commit: Commit) => Promise<void>;
    release?: Release;
    rmcReviews?: Review[];
    commit: Commit;
    isDangerous?: boolean;
    reverted?: boolean;
}

interface CommitState {
    showDetails: boolean;
    isLoading?: boolean;
}

export default class ReleaseCommit extends Component<CommitProps, CommitState> {
    constructor(props: CommitProps) {
        super(props);
        this.state = { showDetails: false };
        this.toggle = this.toggle.bind(this);
    }

    toggle(e: any): void {
        this.setState({
            showDetails: e.target.open
        });
    }

    statusIcon(r: PrReview): JSX.Element {
        switch (r.status) {
            case "APPROVED":
                return <CheckIcon className="text-green" />;
            case "REQUEST_CHANGES":
                return <XIcon className="text-red" />;
            case "DISMISSED":
                return <SkipIcon className="text-red" />;
            default:
                return <QuestionIcon />;
        }
    }

    renderCommitInfo(commit: Commit): JSX.Element {
        const date = commit.time ?? commit.pr?.date;
        const displayDate = Utils.formatDateTime(date);
        const author = !!commit.pr?.number ? this.renderAuthorUrl(commit.pr) : commit.author;
        return (
            <div className="f6 text-gray">
                &ensp;committed by {author} on {displayDate} (<span className="text-bold">{commit.shortSha}</span>)
            </div>);
    }

    renderAuthorUrl(pr: PullRequest): JSX.Element {
        return (
            <a href={pr.author.url}
                target="_blank"
                className="link-gray-dark no-underline tooltipped tooltipped-s user-mention"
                aria-label="View profile">{pr.author.login}</a>
        );
    }

    render(): JSX.Element {
        if (this.props.commit.status === CommitStatus.EMPTY || this.props.commit.status === CommitStatus.FETCHING) {
            return (
                <div className={this.props.reverted ? "bg-gray text-gray" : ""}>
                    <div className="d-flex">
                        <div className="mx-1 my-4">
                            {this.state.showDetails ? <TriangleDownIcon /> : <TriangleRightIcon />}
                        </div>

                        <div className="flex-1">
                            <p className="h5 mb-1">
                                <span className={this.props.reverted ? "text-gray" : this.props.isDangerous ? "text-red" : ""}>
                                    {this.props.commit.summary}
                                </span>
                            </p>
                            <div className="no-wrap d-flex flex-items-center mt-1">
                                <PersonIcon className="mr-1" /*alt={"@" + this.props.commit.author}*/ />
                                {this.renderCommitInfo(this.props.commit)}
                            </div>
                        </div>
                    </div>
                    <ThreeDots color="#777777" height={30} />
                </div>);
        }
        const prDetails = <CommitContent commit={this.props.commit} showMessageTab={true} />;

        //TODO: separate into Commit and PR components
        if (!!this.props.commit.pr) {
            return (
                <div
                    className={this.props.commit.pr.isReverted ? "text-gray bg-gray-light" : ""}>
                    <details className="details-reset" onToggle={this.toggle}>
                        <summary>
                            <div className="d-flex">
                                <div className="mx-1 mt-4">
                                    {this.state.showDetails ? <TriangleDownIcon /> : <TriangleRightIcon />}
                                </div>

                                <div className="flex-1">
                                    <p className="h5 mb-1">
                                        <CommitWarnings commit={this.props.commit} />
                                        <a className={this.props.commit.pr.isReverted ? "link-gray no-underline" : "link-gray-dark no-underline"}
                                            href={this.props.commit.pr.url}
                                            target="_blank">
                                            <span className={this.props.reverted ? "text-gray" : ""}>PR #{this.props.commit.pr.number} {this.props.commit.pr.title}</span>
                                        </a>
                                    </p>
                                    <div className="no-wrap d-flex flex-items-center mt-1">
                                        <Avatar
                                            className="mr-1"
                                            alt={"@" + this.props.commit.pr.author.login}
                                            src={this.props.commit.pr.author.avatar}
                                        />

                                        {this.renderCommitInfo(this.props.commit)}
                                    </div>
                                </div>
                            </div>

                            <div className="no-wrap d-flex flex-items-center mt-1 ml-3">
                                &nbsp;
                                <div className={"ml-1 AvatarStack " + (this.props.commit.pr.reviews.reviews.length > 2 ? "AvatarStack--three-plus" : "AvatarStack--two")}>
                                    <div className="AvatarStack-body tooltipped tooltipped-se tooltipped-align-left-1" aria-label="PR Reviewers">
                                        {this.props.commit.pr.reviews.reviews.map((r, index) => (
                                            <img key={index} className="avatar" height="20" width="20" alt={"@" + r.author.login} src={r.author.avatar} />
                                        ))}
                                    </div>
                                </div>
                                {this.props.commit.pr.reviews.reviews.map((r, index) => (
                                    <span key={index} className={"mx-1 tooltipped tooltipped-nw"} aria-label={r.status} >
                                        {this.statusIcon(r)} {r.author.login}{" "}
                                    </span>
                                ))}
                            </div>
                            {NOT_DEPLOYED_STATUSES.includes(this.props.release.status) ? <PrActions {... this.props} /> : null}
                        </summary>
                        {prDetails}
                    </details>
                </div>
            );
        } else {
            return (
                <div className={this.props.reverted ? "bg-gray text-gray" : ""}>
                    <details className="details-reset" onToggle={this.toggle}>
                        <summary>
                            <div className="d-flex">
                                <div className="mx-1 my-4">
                                    {this.state.showDetails ? <TriangleDownIcon /> : <TriangleRightIcon />}
                                </div>

                                <div className="flex-1">
                                    <p className="h5 mb-1">
                                        <span className={this.props.reverted ? "text-gray" : this.props.isDangerous ? "text-red" : ""}>
                                            {this.props.commit.summary}
                                        </span>
                                    </p>
                                    <div className="no-wrap d-flex flex-items-center mt-1">
                                        <PersonIcon className="mr-1" /*alt={"@" + this.props.commit.author}*/ />
                                        {this.renderCommitInfo(this.props.commit)}
                                    </div>
                                </div>

                                {NOT_DEPLOYED_STATUSES.includes(this.props.release.status) ? <PrActions {... this.props} /> : null}
                            </div>
                        </summary>
                        {prDetails}
                    </details>
                </div>
            );
        }
    }
}

ReleaseCommit.contextType = AppContext;
