import React, { Component } from "react";
import { ThreeDots } from "react-loader-spinner";
import Can from "../can";
import { AppContext, AppInfo } from "../context";
import { Resources, Actions, PullRequest, NOT_DEPLOYED_STATUSES } from "../shared-interfaces";
import RmcReviewLabel from "./rmc-review-label";
import { CheckIcon, TrashcanIcon, XIcon } from "@primer/octicons-react";
import { api } from "../api";
import { CommitProps } from "./release-commit";

interface State {
    isLoading: boolean;
}

enum ApprovalStatus {
    APPROVE = 'approve',
    DISAPPROVE = 'disapprove'
}

export default class PrActions extends Component<CommitProps, State> {
    constructor(props: CommitProps) {
        super(props);
        this.state = { isLoading: false };
        this.review = this.review.bind(this);
        this.revert = this.revert.bind(this);
        this.isApprovedByMe = this.isApprovedByMe.bind(this);
        this.isDisapprovedByMe = this.isDisapprovedByMe.bind(this);
        this.isDisapproved = this.isDisapproved.bind(this);
        this.isInReview = this.isInReview.bind(this);
        this.countReviews = this.countReviews.bind(this);
    }

    private review(status: ApprovalStatus): void {
        const appInfo = this.context as AppInfo;
        this.setState({ isLoading: true });
        api.post(`releases/${this.props.release.id}/reviews/${this.props.commit.sha}`,
            { Accept: "application/json", "Content-Type": "application/json" },
            JSON.stringify({
                commitId: this.props.commit.sha,
                releaseId: this.props.release.id,
                userId: appInfo.user.id,
                status: status,
                message: ""
            })
        ).then(() => {
            return this.props.onReview(this.props.commit);
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    private revert(): void {
        if (!confirm(`Revert the following commit?\n${this.props.commit.shortSha} "${this.props.commit.summary}"`)) {
            return;
        }
        this.setState({ isLoading: true });
        api.post(`releases/${this.props.release.id}/commits/${this.props.commit.sha}/revert`, {
            Accept: "application/json",
        }).then((pr: PullRequest) => {
            if (pr.isMerged) {
                alert(`"${pr.title} has been merged into ${pr.label}. More info: ${pr.url}`);
            } else {
                alert(`"${pr.title} can't be merged into ${pr.label}. Please check if there are any conflicts and merge manually: ${pr.url}`);
            }

            this.setState({ isLoading: false });
            this.props.onRevert(this.props.commit);
        }
        );
    }

    countReviews(status: string): number {
        return this.props.rmcReviews.filter(r => r.status === status).length;
    }

    isApprovedByMe(): boolean {
        const appInfo = this.context as AppInfo;
        return this.props.rmcReviews.some(r => r.userId === appInfo.user.id && r.status === ApprovalStatus.APPROVE);
    }

    isDisapprovedByMe(): boolean {
        const appInfo = this.context as AppInfo;
        return this.props.rmcReviews.some(r => r.userId === appInfo.user.id && r.status === ApprovalStatus.DISAPPROVE);
    }

    isDisapproved(): boolean {
        return this.countReviews(ApprovalStatus.DISAPPROVE) > 0;
    }

    isInReview(): boolean {
        return NOT_DEPLOYED_STATUSES.includes(this.props.release.status);
    }

    render() {
        return (
            <div className="no-wrap d-flex flex-items-center mt-2 ml-4">
                {!this.props.commit.pr ? <span> Wasn't able to find a PR</span> : null}
                <div className="flex-1">
                    {this.props.rmcReviews.map((r, index) => (
                        <RmcReviewLabel key={index} status={r.status} userName={r.userName!} />
                    ))}
                </div>
                <div className="float-right float-bottom">
                    {this.state.isLoading
                        ? <ThreeDots color="#777777" height={30} />
                        : <Can resource={Resources.RELEASES} action={Actions.UPDATE}
                            yes={() => (
                                <div className="BtnGroup">
                                    <button onClick={() => this.review(ApprovalStatus.APPROVE)}
                                        className="btn BtnGroup-item btn-sm tooltipped tooltipped-s"
                                        aria-label="Approve this PR"
                                        disabled={this.isApprovedByMe() || !this.isInReview()} >
                                        <CheckIcon />Approve
                                    </button>
                                    <button onClick={() => this.review(ApprovalStatus.DISAPPROVE)}
                                        className="btn BtnGroup-item btn-sm tooltipped tooltipped-s"
                                        aria-label="Block this PR"
                                        disabled={this.isDisapprovedByMe() || !this.isInReview()}>
                                        <XIcon />Block
                                    </button>
                                    <button onClick={this.revert}
                                        className="btn BtnGroup-item btn-sm tooltipped tooltipped-s"
                                        aria-label="Revert this PR from 'main' branch"
                                        disabled={!this.isDisapproved() || !this.isInReview()}>
                                        <TrashcanIcon />
                                    </button>
                                </div>
                            )}
                        />
                    }
                </div>
            </div>
        );
    }
}

PrActions.contextType = AppContext;
