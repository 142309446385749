import React, { Component } from "react";
import { FileDiffs } from "../shared-interfaces";
import AdditionsDeletions from "./additions-deletions";

interface Props {
    files: FileDiffs[];
}

export default class PrFiles extends Component<Props> {
    render(): JSX.Element {
        if (this.props && this.props.files?.length) {
            return (
                <div className="commit-desc">
                    {this.props.files.map((f: FileDiffs, index) => (
                        <pre
                            key={index}
                            className={f.isDangerous ? "text-red text-bold" : "text-small"}>
                            {f.name}{" "}
                            <AdditionsDeletions additions={f.additions} deletions={f.deletions} />
                        </pre>
                    ))}
                </div>
            );
        } else {
            return <span>No file changes</span>;
        }
    }
}
