import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { api } from "../api";
import { TailSpin } from "react-loader-spinner";
import { Release, Commit as CommitInfo, ReleaseStatus as Status, ReleaseType, ReleaseStatus, RmsReleaseMode, SKIPPED_OR_CANCELED_STATUSES, ArchivedReleaseResponse, ArchivedReleaseRequest } from "../shared-interfaces";
import Commit from "./commit";
import { Utils } from "../utils";
import ArchivedRelease from "./archived-release";
import ReleaseSummary from "../releases/release-summary";
import { CommitIcon } from "@primer/octicons-react";
import { AppContext, AppInfo } from "../context";

const RELEASES_PER_PAGE = 10;

interface State {
    isLoading: boolean;
    activeRelease: Release;
    commitsForNewRelease: CommitInfo[];
    archivedReleases: Release[];
    redirect: string;
    numberShownRecords: number;
    offset: number;
    isAllReleasesLoaded: boolean;
};

interface Props { }

export default class History extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.loadReleases = this.loadReleases.bind(this);
        this.toBeReleasedText = this.toBeReleasedText.bind(this);
        this.state = { commitsForNewRelease: [], archivedReleases: [], isLoading: false, redirect: '', numberShownRecords: 0, offset: 0, isAllReleasesLoaded: false, activeRelease: null };
    }

    getCommitsForNewRelease(): Promise<{ commits: CommitInfo[] }> {
        return api.get("commits/unreleased", {});
    }

    getArchivedReleases(): Promise<ArchivedReleaseResponse> {
        const response: ArchivedReleaseRequest = {
            batchSize: RELEASES_PER_PAGE,
            offset: this.state.offset,
            includeTypes: [ReleaseType.RELEASE],
            includeStatuses: Object.values(ReleaseStatus).filter(x => !SKIPPED_OR_CANCELED_STATUSES.includes(x))
        };
        return api.post("releases/archived", { "Accept": "application/json", "Content-Type": "application/json" }, response);
    }

    componentDidMount(): void {
        this.loadCommits();
        this.loadReleases();
    }

    loadCommits(): void {
        console.log('Loading commits for new release');
        this.setState({ isLoading: true });
        this.getCommitsForNewRelease().then(r => {
            this.setState({
                commitsForNewRelease: r.commits,
                isLoading: false
            });
        });
    }

    loadReleases(): Promise<void> {
        console.log('Loading archived releases');
        return this.getArchivedReleases().then(r => {
            const archivedReleases = this.state.archivedReleases;
            archivedReleases.push(...r.releases.filter(x => x.status !== ReleaseStatus.IN_REVIEW));
            const activeRelease = this.state.activeRelease ?? r.releases.find(x => x.status === ReleaseStatus.IN_REVIEW);
            this.setState({
                archivedReleases,
                activeRelease,
                offset: this.state.offset + RELEASES_PER_PAGE,
                isAllReleasesLoaded: !r.releases.length || r.releases.length < RELEASES_PER_PAGE
            });
        });
    }

    renderShowMoreButton(): JSX.Element {
        if (this.state.isAllReleasesLoaded) {
            return (<div className=" d-flex flex-justify-around">All releases have been loaded</div>);
        } else {
            return (
                <div className=" d-flex flex-justify-around">
                    <button
                        className="btn-mktg btn-outline-mktg mr-3"
                        style={{ textAlign: 'center', position: 'relative' }}
                        type="button"
                        onClick={this.loadReleases}>
                        Show more
                    </button>
                </div>);
        }
    }

    releaseHeaderText(release: Release): string {
        const namePrefix = release.tag ?? `Release #${release.id}`;
        switch (release.status) {
            case Status.IN_REVIEW:
                return `${namePrefix} - to be deployed`;
            case Status.DEPLOYING:
                return `${namePrefix} ${Utils.formatDateTime(release.createdAt)} - deploying`;
            case Status.DEPLOYED_WITH_ERRORS:
                return `${namePrefix} ${Utils.formatDateTime(release.createdAt)} - deployed with errors`;
            case Status.DEPLOYED:
            default:
                return `${namePrefix} ${Utils.formatDateTime(release.createdAt)} - deployed`
        }
    }

    toBeReleasedText(commitsCount: number): string {
        if (commitsCount === 0) {
            return 'To be released in the next RC - no new commits to release';
        }

        const settings = (this.context as AppInfo)?.settings?.releaseSettings;
        const nextReleaseTime = !!settings && settings.releaseMode !== RmsReleaseMode.MANUAL && !!settings.autoCreationCrons?.length
            ? `at ${Utils.getNextReleaseTime(settings.autoCreationCrons)}`
            : 'no scheduled releases';
        return `To be released in the next RC (${commitsCount}) - ${nextReleaseTime}`;
    }

    renderRelease(release: Release): JSX.Element {
        if (!release) {
            return null;
        }

        return (
            <div className='mb-5' key={release.id}>
                <div className="Box-header position-sticky top-0" style={{ zIndex: 10 }}>
                    <ReleaseSummary release={release} customName={this.releaseHeaderText} />
                </div>
                <ArchivedRelease releaseId={release.id} />
            </div>
        );
    }

    render(): JSX.Element {
        if (this.state.redirect) {
            return <Navigate replace to={this.state.redirect} />;
        } else if (!!this.state.isLoading) {
            return (
                <div className="d-flex flex-items-center flex-justify-around"
                    style={{ minHeight: "300px" }}>
                    <TailSpin color="#777777" height={50} width={50} />
                </div>
            );
        } else {
            return (
                <div className="col-xl-8 col-lg-8 col-10 m-5 mx-auto">
                    <div>{this.renderRelease(this.state.activeRelease)}</div>

                    <div>
                        <div className='mb-3'>
                            <div className="Box-header h4 position-sticky top-0">

                                <div className="d-flex">
                                    <div className="flex-shrink-0 pl-2 pr-2">
                                        <span  >
                                            <CommitIcon />
                                        </span>
                                    </div>
                                    {this.toBeReleasedText(this.state.commitsForNewRelease.length)}
                                </div>
                            </div>

                            <ul>
                                {this.state.commitsForNewRelease.map((c: CommitInfo, index: number) => (
                                    <li className="Box-row p-2" key={index}> {<Commit commit={c} />} </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div >
                        {this.state.archivedReleases.map((release: Release, _) => this.renderRelease(release))}
                    </div>
                    {this.renderShowMoreButton()}
                </div >
            );
        }
    }
}

History.contextType = AppContext;
