import React, { Component } from 'react';
import { NotificationInfo, NotificationRoot, NotificationType } from './interfaces';
import { Notification } from './notification';
import { toast } from './toast';
import { Dialog } from '@primer/components';
import { Utils } from '../utils';
import Switch from "react-switch";

interface Props { }

interface State {
    notifications: Array<NotificationInfo>;
    confirmation: ConfirmationProps;
}

interface ConfirmationProps {
    header: string;
    isOpen: boolean;
    confirmed: boolean;
    steps: string[];
    currentStep: number;
    confirmCallback: () => void;
}

const SHOW_TIMEOUT_MS = 5000;

export class Notifications extends Component<Props, State> implements NotificationRoot {
    constructor(props: Props) {
        super(props);
        toast.init(this);
        this.state = { notifications: [], confirmation: this.emptyConfirmation() };
        this.showNotification = this.showNotification.bind(this);
        this.removeNotification = this.removeNotification.bind(this);
        this.submitConfirmation = this.submitConfirmation.bind(this);
        this.cancelConfirmation = this.cancelConfirmation.bind(this);
        this.onSliderChecked = this.onSliderChecked.bind(this);
    }

    private emptyConfirmation(): ConfirmationProps {
        return { header: "", isOpen: false, confirmed: false, steps: [], currentStep: 0, confirmCallback: undefined };
    }

    confirm(steps: string[], header: string, confirmCallback: () => void): void {
        this.setState({
            confirmation: {
                isOpen: true,
                confirmed: false,
                header,
                steps,
                currentStep: 0,
                confirmCallback
            }
        })
    }

    showNotification(type: NotificationType, text: string, url: string, urlDescription: string, autoHide: boolean): number {
        const notification: NotificationInfo = { id: null, type, text, url, urlDescription, permanent: !autoHide };
        if (!notification.id) {
            notification.id = Date.now().valueOf() + Math.random();
        }
        this.setState({
            notifications: [...this.state.notifications, notification]
        });

        if (!notification.permanent) {
            setTimeout(() => {
                this.removeNotification(notification);
            }, SHOW_TIMEOUT_MS);
        }
        return notification.id;
    }

    private onSliderChecked(checked: boolean): void {
        const confirmation = this.state.confirmation;
        confirmation.confirmed = checked;
        this.setState({ confirmation });
    }

    private submitConfirmation(): void {
        const confirmation = this.state.confirmation;
        if (++confirmation.currentStep >= confirmation.steps.length) {
            confirmation.confirmCallback();
            this.cancelConfirmation();
        } else {
            confirmation.confirmed = false;
            this.setState({ confirmation });
        }
    }

    private cancelConfirmation(): void {
        this.setState({ confirmation: this.emptyConfirmation() });
    }

    private removeNotification(notification: NotificationInfo): void {
        let notifications = this.state.notifications;
        if (notifications?.length) {
            this.setState({
                notifications: notifications.filter(n => n.id !== notification.id)
            })
        }
    }

    private renderConfirmation(confirmation: ConfirmationProps): JSX.Element {
        const text = confirmation.steps[confirmation.currentStep];
        if (Utils.isNullOrWhitespace(text)) {
            return null;
        }
        const elements: JSX.Element[] = [];
        const urlRegex = /(?:https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&\/=]*)/g;
        const urls = text.match(urlRegex);
        if (urls?.length) {
            const nonUrlParts = text.split(urlRegex);
            let index = 0;
            let textIndex = 0;
            for (const part of nonUrlParts) {
                if (!Utils.isNullOrWhitespace(part)) {
                    elements.push(...this.renderNormalText(part, textIndex++));
                } else if (urls[index]) {
                    elements.push(<a href={urls[index]} key={`link_${index}`} target='_blank'>{urls[index]}</a>);
                    index++;
                }
            }
        } else {
            elements.push(...this.renderNormalText(text, 0));
        }
        return (<>{elements}</>);
    }

    renderNormalText(text: string, startIndex: number): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const parts = text.split('\n');
        let index = 0;
        for (const part of parts) {
            if (!Utils.isNullOrWhitespace(part)) {
                elements.push(<div key={`text_${startIndex}_${index++}`}>{part}</div>);
            }
        }
        return elements;
    }

    render() {
        return (
            <>
                <div className="notification-box position-sticky left-0 right-0 top-0 pt-1">
                    {this.state.notifications.map(n => (
                        <Notification notification={n} hideNotification={this.removeNotification} key={n.id} />
                    ))}
                </div>
                <Dialog title={this.state.confirmation.header}
                    isOpen={this.state.confirmation.isOpen}
                    onDismiss={this.cancelConfirmation}>
                    <div className="Box">
                        <div className="Box-body">
                            {this.renderConfirmation(this.state.confirmation)}
                        </div>
                        <div className="form-actions my-3 mx-5">
                            <Switch className="m-1" onChange={this.onSliderChecked} checked={this.state.confirmation.confirmed} />
                            <label className="m-1 p-1" style={{ verticalAlign: 'top', display: 'inline-block' }}>
                                I read the confirmation
                            </label>
                            <button className="btn m-1" type="button" onClick={this.cancelConfirmation}>Cancel</button>
                            <button className="btn btn-danger m-1" type="submit" onClick={this.submitConfirmation}
                                disabled={!this.state.confirmation.confirmed}>
                                Yes
                            </button>
                        </div>
                    </div>
                </Dialog >
            </>
        );
    }
}