import React, { Component } from "react";
import { Commit as CommitInfo, Release as ReleaseInfo, ReleaseType, Review } from "../shared-interfaces";
import HotifxCommit from "./hotfix-commit";
import ReleaseCommit from "./release-commit";

interface Props {
    commits: CommitInfo[];
    onReview?: (commit: CommitInfo) => Promise<void>;
    onRevert?: (commit: CommitInfo) => Promise<void>;
    release?: ReleaseInfo;
    rmcReviews?: { [key: string]: Review[] };
}

export default class ReleaseCommits extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <div>
                <ul>
                    {this.props.commits.map((c: CommitInfo, index: number) => {
                        return (
                            <li className="Box-row p-2" key={index}>
                                {this.props.release?.type === ReleaseType.RELEASE
                                    ? <ReleaseCommit
                                        commit={c}
                                        rmcReviews={this.props.rmcReviews[c.sha] || []}
                                        release={this.props.release}
                                        onReview={this.props.onReview}
                                        onRevert={this.props.onRevert}
                                    />
                                    : <HotifxCommit commit={c} />}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
