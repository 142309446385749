import React, { Component } from 'react';

interface Props {
    alignRight?: boolean;
    rawValues?: any[];
    items?: ComboBoxItem[];
    selectedIndex?: number;
    selectedValue?: any;
    selectable?: boolean;
    editable: boolean;
    onItemClick: (item: ComboBoxItem) => void;
    width?: number;
    renderSummaryContent?: (item: ComboBoxItem) => JSX.Element;
    summaryClassName?: string;
}

interface State {
    items: ComboBoxItem[];
    selectedItem: ComboBoxItem;
    isOpen: boolean;
}

export interface ComboBoxItem {
    value: any;
    name: any;
    disabled?: boolean;
}

export default class ComboBox extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { items, selectedItem } = ComboBox.resolveDataSourceAndSelection(props);
        this.state = { items, selectedItem, isOpen: false };
        this.showPopup = this.showPopup.bind(this);
        this.close = this.close.bind(this);
        this.select = this.select.bind(this);
        this.renderSummary = this.renderSummary.bind(this);
    }

    componentDidUpdate() {
        const { items, selectedItem, selectedIndex } = ComboBox.resolveDataSourceAndSelection(this.props);
        if (this.state.items.length !== items.length) {
            this.setState({ items, selectedItem });
        } else if (selectedIndex !== this.state.items.indexOf(this.state.selectedItem)) {
            const newSelectedItem = this.state.items[selectedIndex];
            this.setState({ selectedItem: newSelectedItem });
        }
    }

    close(): void {
        this.setState({ isOpen: false });
    }

    private getStyle(): any {
        return isNaN(this.props.width) ? {} : { width: this.props.width };
    }

    private static resolveDataSourceAndSelection(props: Props): { items: ComboBoxItem[], selectedItem: ComboBoxItem, selectedIndex: number } {
        const items = !!props.items?.length ? props.items : props.rawValues.map(x => ({ value: x, name: x, disabled: false }));
        let selectedItem = props.selectable === false ? null : items[0];
        //undefined is considered as True
        if (props.selectable !== false) {
            if (!isNaN(props.selectedIndex)) {
                selectedItem = props.selectedIndex > -1 ? items[props.selectedIndex] : null;
            } else if (!!props.selectedValue) {
                selectedItem = items.find(x => x.value === props.selectedValue);
            }
        }
        return { items, selectedItem, selectedIndex: items.indexOf(selectedItem) };
    }

    private showPopup(evt: React.MouseEvent): void {
        evt.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
    }

    private select(evt: React.MouseEvent, newIndex: number): void {
        evt.preventDefault();
        const selectedItem = this.state.items[newIndex];
        if (this.props.selectable !== false) {
            this.setState({ selectedItem });
        }
        this.props?.onItemClick(selectedItem);
    }

    private renderSummary() {
        return !!this.props.renderSummaryContent
            ? this.props.renderSummaryContent(this.state.selectedItem)
            : (
                <div>
                    {this.state.selectedItem?.name}
                    <div className="dropdown-caret ml-2" />
                </div>
            );
    }

    private getButtonStyle(disabled: boolean): any {
        return disabled ? { color: '#8c959f', pointerEvents: 'none' } : {};
    }

    render(): JSX.Element {
        if (!this.props.editable) {
            return (
                <summary className={this.props.summaryClassName ?? "btn width-full"} aria-disabled>
                    {this.renderSummary()}
                </summary>
            );
        }
        return (
            <details key='popup' className="dropdown details-reset details-overlay" onClick={this.showPopup} open={this.state.isOpen}>
                <summary className={this.props.summaryClassName ?? "btn"} aria-haspopup="true" style={this.getStyle()}>
                    {this.renderSummary()}
                </summary>
                <div className={this.props.alignRight ? 'SelectMenu right-0' : 'SelectMenu'}>
                    <div className="SelectMenu-modal" style={this.getStyle()}>
                        <div className="SelectMenu-list">
                            {this.state.items.map((item, index) => (
                                <button key={index} className="SelectMenu-item" role="menuitem" disabled={item.disabled}
                                    style={this.getButtonStyle(item.disabled)} onClick={evt => this.select(evt, index)}>{item.name}</button>
                            ))}
                        </div>
                    </div>
                </div>
            </details>
        );
    }
}
