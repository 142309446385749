import { PlusIcon } from '@primer/octicons-react';
import React, { Component } from 'react';
import { isValidCron } from "cron-validator";

interface Props {
    onAdd: (cron: string) => void;
}

interface State {
    isValid: boolean;
    cron: string;
}

export default class NewReleaseSchedule extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { cron: '', isValid: false };
        this.addNewCron = this.addNewCron.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const cron = e.target.value;
        const isValid = isValidCron(cron);
        this.setState({ isValid, cron });
    }
    addNewCron() {
        this.props.onAdd(this.state.cron);
        this.setState({ isValid: false, cron: '' });
    }

    render() {
        return (
            <div className="clearfix">
                <label className="col-lg-4 col-md-5 col-xs-8 p-2 m-2 float-left text-right" htmlFor="autoCreationCronSchedule">
                    New release schedule
                </label>
                <input type="text" name="autoCreationCronSchedule" className="form-control col-lg-6 col-md-5 col-xs-3 p-2 m-2 float-left"
                    placeholder='e.g. "* * * * *", "0 */3 * * *", "0 9 * * 1"' value={this.state.cron} onChange={this.handleChange} />
                <div className="form-control float-left p-0 m-2">
                    <button className="btn" onClick={this.addNewCron} disabled={!this.state.isValid}>
                        <PlusIcon />
                    </button>
                </div>
            </div>
        );
    }
}
