import React, { Component } from "react";
import Can from "../can";
import DbSettings from "./db-settings";
import FilterSettings from "./filter-settings";
import RmsSettings from "./rms-settings";
import Users from "./users";
import { api } from "../api";
import { toast } from "../notifications/toast";
import { NotificationType } from "../notifications/interfaces";
import { Actions, ArgocdSettings, FileFilterSettings, ReleaseSettings, Resources, Settings as SettingsInfo } from "../shared-interfaces";
import { TailSpin} from 'react-loader-spinner';
import Services from "./services";
import ApiTokens from "./api-tokens";
import CanaryClusters from "./canary-clusters";
import DeploymentPipelines from "./deployment-pipelines";
import ReleaseFreezes from "./release-freezes";
import ArgocdSettingsForm from "./argocd-settings";

interface SettingsState {
    isLoaded: boolean;
    filterSettings?: FileFilterSettings;
    releaseSettings?: ReleaseSettings;
    argocdSettings?: ArgocdSettings;
}

interface Props {
    onSettingsUpdated: (s: SettingsInfo) => void;
}

export default class Settings extends Component<Props, SettingsState> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoaded: false };
        this.saveSettings = this.saveSettings.bind(this);
        this.reset = this.reset.bind(this);
        this.updateFilterSettings = this.updateFilterSettings.bind(this);
        this.updateReleaseSettings = this.updateReleaseSettings.bind(this);
        this.updateArgocdSettings = this.updateArgocdSettings.bind(this);
    }

    componentDidMount(): void {
        this.loadSettings().then(s => {
            this.setState({ ...s, isLoaded: true });
        });
    }

    reset(): void {
        this.setState({ isLoaded: false });
        this.loadSettings().then(s => {
            this.props.onSettingsUpdated(s);
            this.setState({ ...s, isLoaded: true });
        });
    }

    loadSettings(): Promise<SettingsInfo> {
        return api.get("settings", {}).then(s => s.settings);
    }

    saveSettings(e: React.MouseEvent): void {
        this.setState({ isLoaded: false });
        api.post(
            "settings",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({
                filterSettings: this.state.filterSettings,
                releaseSettings: this.state.releaseSettings,
                argocdSettings: this.state.argocdSettings
            })
        ).then(s => {
            const settings: SettingsInfo = s.settings;
            this.setState({ ...settings, isLoaded: true });
            this.props.onSettingsUpdated(settings);
            toast.show(NotificationType.SUCCESS, "Settings saved successfully");
        });
        e.preventDefault();
    }

    updateFilterSettings(settings: FileFilterSettings): void {
        this.setState({ filterSettings: settings });
    }

    updateArgocdSettings(settings: ArgocdSettings): void {
        console.log(`updating argocd settings`);
        this.setState({ argocdSettings: settings });
    }

    updateReleaseSettings(settings: ReleaseSettings): void {
        console.log("updating release settings");
        this.setState({ releaseSettings: settings });
    }

    render(): JSX.Element {
        if (this.state.isLoaded) {
            return (
                <div className="Box box-shadow col-xl-6 col-lg-8 col-10 m-5 mx-auto">
                    <form onSubmit={e => e.preventDefault()}>
                        <FilterSettings settings={this.state.filterSettings} onUpdate={this.updateFilterSettings} />
                        <RmsSettings settings={this.state.releaseSettings} onUpdate={this.updateReleaseSettings} />
                        <ArgocdSettingsForm settings={this.state.argocdSettings} onUpdate={this.updateArgocdSettings}/>
                        <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                            yes={() => (
                                <div className="form-actions my-3 mx-5">
                                    <button className="btn btn-primary m-1" type="submit" onClick={this.saveSettings}>Save</button>
                                    <button className="btn m-1" type="button" onClick={this.reset}>Cancel</button>
                                </div>
                            )}
                        />
                        <Can action={Actions.DELETE} resource={Resources.SETTINGS}
                            yes={() => (<DbSettings />)}
                        />
                    </form>
                    <ReleaseFreezes />
                    <CanaryClusters />
                    <Services />
                    <DeploymentPipelines />
                    <ApiTokens />
                    <Users />
                </div>
            );
        } else {
            return (
                <div className="d-flex flex-items-center flex-justify-around" style={{ minHeight: "300px" }} >
                    <TailSpin color="#777777" height={50} width={50} />
                </div>
            );
        }
    }
}
