import React, { Component } from "react";
import { CheckIcon, PencilIcon, PlusIcon, QuestionIcon, XIcon } from "@primer/octicons-react";
import Can from "../can";
import { api } from "../api";
import { toast } from "../notifications/toast";
import { Actions, CiVendor, CustomCiJob, Resources, Service, UserRole, getVendorName, userRolesList } from "../shared-interfaces";
import { NotificationType } from "../notifications/interfaces";
import { Dialog } from "@primer/components";
import { Utils } from "../utils";
import ServiceCustomJob from "./service-custom-job";
import NewServiceCustomJob from "./new-service-custom-job";
import ComboBox from "./combo-box";
import ActivationColumn from "./activation-column";
import CircleCiIcon from "../icons/circle-ci-icon";
import BuildkiteIcon from "../icons/buildkite-icon";

interface State {
    isLoaded: boolean;
    services: Service[];
    isEditOpened: boolean;
    editService: Service;
}

interface Props { }

const CI_VENDORS = [CiVendor.CIRCLE_CI, CiVendor.BUILDKITE];

const TRUE = "true";
const FALSE = "false";

export default class Services extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { services: [], isLoaded: false, isEditOpened: false, editService: this.emptyService() };
        this.handleServiceDialog = this.handleServiceDialog.bind(this);
        this.validateRevertFields = this.validateRevertFields.bind(this);
        this.onServiceActivation = this.onServiceActivation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateEditService = this.updateEditService.bind(this);
        this.deleteCustomJob = this.deleteCustomJob.bind(this);
        this.addCustomJob = this.addCustomJob.bind(this);
    }

    componentDidMount(): void {
        api.post(`services`, { "Accept": "application/json", "Content-Type": "application/json" }).then(r => {
            this.setState({ isLoaded: true, services: r.services });
        });
    }

    validateRevertFields(): boolean {
        const service = this.state.editService;
        return this.bothValuesAreEmptyOrNotNull(service.revertJobName, service.revertPipelineTrigger);
    }

    validateCanaryFields(): boolean {
        const service = this.state.editService;
        return this.bothValuesAreEmptyOrNotNull(service.canaryResourceName, service.canaryResourceNamespace);
    }

    bothValuesAreEmptyOrNotNull(value1: string, value2: string): boolean {
        const isValue1Empty = Utils.isNullOrWhitespace(value1);
        const isValue2Empty = Utils.isNullOrWhitespace(value2);
        return (isValue1Empty && isValue2Empty) || (!isValue1Empty && !isValue2Empty);
    }

    handleServiceDialog(): void {
        if (!this.validateRevertFields()) {
            alert('You should provide value for both `Revert job name` and `Revert pipeline trigger` or leave them empty.');
            return;
        }
        if (!this.validateCanaryFields()) {
            alert('You should provide value for both `Canary name` and `Canary namespace` or leave them empty.');
            return;
        }

        this.setState({ isLoaded: false, isEditOpened: false });
        const service = this.state.editService;
        api.post(
            "services/update",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify(service)
        ).then(r => {
            this.setState({ services: r.services, isLoaded: true, editService: this.emptyService() });
            toast.show(NotificationType.SUCCESS, `Service '${service.displayName}' ${service.id ? 'modified' : 'added'} successfully`);
        });
    }

    emptyService(): Service {
        return {
            displayName: '',
            jobName: '',
            revertJobName: '',
            revertRequiredRole: UserRole.ENGINEER,
            sendDeploymentNotification: false,
            customJobs: []
        } as Service;
    }

    onServiceActivation(service: Service, newIsActive: boolean): void {
        const endpoint = newIsActive
            ? `services/${service.id}/activate`
            : `services/${service.id}/deactivate`;

        api.post(endpoint, {})
            .then(() => {
                let services = [...this.state.services];
                services.find(u => u.id === service.id).isActive = newIsActive;
                this.setState({ services: services, isLoaded: true });
                toast.show(NotificationType.SUCCESS, `Service has been successfully ${newIsActive ? 'activated' : 'deactivated'}`);
            });
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.type === "radio"
            ? (e.target.value === TRUE || e.target.value === FALSE ? e.target.value === TRUE : e.target.value)
            : e.target.value;
        this.updateEditService(e.target.name, value);
    }

    updateEditService(propName: string, value: any): void {
        const editService = this.state.editService as any;
        editService[propName] = value;
        this.setState({ editService });
    }

    deleteCustomJob(index: number): void {
        console.log(`Deleting custom job #${index}`);
        const service = this.state.editService;
        service.customJobs.splice(index, 1);
        this.setState({ editService: service });
    }

    addCustomJob(job: CustomCiJob): void {
        console.log(`Adding new custom job: ${JSON.stringify(job)}`);
        const service = this.state.editService;
        service.customJobs.push(job);
        this.setState({ editService: service });
    }

    checkColumn(isChecked: boolean): JSX.Element {
        return (
            <td className="border-right">
                <div className="mx-3">{isChecked ? <CheckIcon /> : <XIcon />}</div>
            </td>
        );
    }

    editColumn(service: Service): JSX.Element {
        return (<Can action={Actions.UPDATE} resource={Resources.SERVICES}
            yes={() => (
                <td>
                    <button className="btn-sm btn-primary m-1"
                        onClick={() => this.setState({ isEditOpened: true, editService: { ...service } })}>
                        <PencilIcon />
                    </button>
                </td>
            )}
        />);
    }

    maxWidthStyle(maxWidth: number): React.CSSProperties {
        return { maxWidth, wordWrap: "break-word" };
    }

    vendorIcon(vendor: CiVendor): JSX.Element {
        switch (vendor) {
            case CiVendor.CIRCLE_CI:
                return <CircleCiIcon />
            case CiVendor.BUILDKITE:
                return <BuildkiteIcon />
            default:
                return null;
        }
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <Dialog title={this.state.editService.id ? `Edit '${this.state.editService.displayName}' service` : 'Create new service'}
                    isOpen={this.state.isEditOpened}
                    onDismiss={() => this.setState({ isEditOpened: false })} aria-labelledby="header-id">
                    <div className="Box">
                        <div className="Box-body">
                            <div className="clearfix">
                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="displayName">
                                    Display name
                                    <span className="tooltipped tooltipped-e" aria-label="Display name is also used as Height deployment tag name, e.g. `Donkey` will be `deployed: donkey`" >
                                        <QuestionIcon className="ml-1" />
                                    </span>
                                </label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="displayName" value={this.state.editService.displayName} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="jobName">Job name</label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="jobName" value={this.state.editService.jobName} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2">CI vendor</label>
                                <div className="col-5 float-left p-2 mb-2">
                                    <ComboBox items={CI_VENDORS.map(x => ({ name: getVendorName(x), value: x }))}
                                        selectedValue={this.state.editService.ciVendor} editable={!this.state.editService?.id} width={100}
                                        onItemClick={(item) => this.updateEditService(`ciVendor`, item.value)} />
                                </div>
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2 text-red" htmlFor="revertJobName">Revert job name</label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="revertJobName" value={this.state.editService.revertJobName} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2 text-red" htmlFor="revertPipelineTrigger">
                                    Revert pipeline trigger
                                    <span className="tooltipped tooltipped-e" aria-label="Parameter name from 'config.yaml', which will be used with TRUE value to trigger pipeline." >
                                        <QuestionIcon className="ml-1" />
                                    </span>
                                </label>

                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="revertPipelineTrigger"
                                    placeholder="Parameter name, e.g. 'deploy_test_workflow'"
                                    value={this.state.editService.revertPipelineTrigger} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2 text-red">Required User role for revert</label>
                                <div className="col-5 float-left p-2 mb-2">
                                    <ComboBox rawValues={userRolesList(UserRole.GUEST)} selectedValue={this.state.editService.revertRequiredRole} editable={true} width={100}
                                        onItemClick={(item) => this.updateEditService(`revertRequiredRole`, item.value)} />
                                </div>
                                <div className="col-5 float-left" />

                                <label htmlFor="revertConfirmationMessage" className="col-3 float-left text-right p-2 m-2">Revert confirmation message</label>
                                <div>
                                    <textarea name="revertConfirmationMessage" className="form-control col-5 float-left p-2 m-2"
                                        value={this.state.editService.revertConfirmationMessage} onChange={this.handleChange} style={{ minHeight: 120 }} />
                                    <div className="col-lg-8 col-md-7 col-xs-8 float-left" />
                                </div>
                                
                                <label className="col-3 float-left text-right p-2 m-2">Canary namespace</label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="canaryResourceNamespace"
                                    placeholder="Canary rollouts object namespace"
                                    value={this.state.editService.canaryResourceNamespace} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2">Canary name</label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="canaryResourceName"
                                    placeholder="Canary rollouts object name"
                                    value={this.state.editService.canaryResourceName} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2">Deployment notifications</label>
                                <div className="radio-group float-left col-5 float-left p-2">
                                    <input type="radio" id="none" className="radio-input" name="sendDeploymentNotification" value={FALSE}
                                        checked={!this.state.editService.sendDeploymentNotification} onChange={this.handleChange} />
                                    <label className="radio-label" htmlFor="none">None</label>

                                    <input type="radio" id="height" className="radio-input" name="sendDeploymentNotification" value={TRUE}
                                        checked={!!this.state.editService.sendDeploymentNotification} onChange={this.handleChange} />
                                    <label className="radio-label" htmlFor="height">Height/Github tag</label>
                                </div>
                            </div>
                        </div>
                        <div className="Box-header">Custom jobs</div>
                        {this.state.editService.customJobs?.length > 0
                            ? (<div className="Box-body">
                                <div className="clearfix">
                                    <div>
                                        {this.state.editService.customJobs.map((job, index) => (
                                            <ServiceCustomJob key={index} job={job} delete={() => { this.deleteCustomJob(index) }} />
                                        ))}
                                    </div>
                                </div>
                            </div>)
                            : null
                        }

                        <Can action={Actions.UPDATE} resource={Resources.SERVICES}
                            yes={() => (
                                <div className="Box-body">
                                    <NewServiceCustomJob onAdd={this.addCustomJob} />
                                </div>
                            )}
                        />
                        <div className="form-actions my-3 mx-5">
                            <button className="btn btn-primary m-1" type="submit" onClick={this.handleServiceDialog}>{this.state.editService.id ? 'Save' : 'Create'}</button>
                            <button className="btn m-1" type="button" onClick={() => this.setState({ isEditOpened: false })}>Cancel</button>
                        </div>
                    </div>
                </Dialog>
                <div className="Box-header">Services</div>
                <div className="Box-body">
                    <table>
                        <thead>
                            <tr>
                                <th className="border-right"><div className="mx-3">Display name</div></th>
                                <th className="border-right"><div className="mx-3">Job name</div></th>
                                <th className="border-right"><div className="mx-3">Revert job name</div></th>
                                <th className="border-right"><div className="mx-3">Deployment notification</div></th>
                                <th className="border-right"><div className="mx-3">Custom jobs</div></th>
                                <th className="border-right"><div className="mx-3">Is canary</div></th>
                                <th className="border-right"><div className="mx-3">Is active</div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.services.map((service, index) => (
                                <tr className="border-top" key={index}>
                                    <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(140)}>{this.vendorIcon(service.ciVendor)}{service.displayName}</div></td>
                                    <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(140)}>{service.jobName}</div></td>
                                    <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(140)}>{Utils.isNullOrWhitespace(service.revertJobName) ? '-' : service.revertJobName}</div></td>
                                    {this.checkColumn(service.sendDeploymentNotification)}
                                    {this.checkColumn(!!service.customJobs?.length)}
                                    {this.checkColumn(!!service.canaryResourceName)}
                                    {this.checkColumn(service.isActive)}
                                    {this.editColumn(service)}
                                    <ActivationColumn row={service} visibleTo={{ resource: Resources.SERVICES, action: Actions.DELETE }}
                                        activate={(row) => this.onServiceActivation(row as Service, true)}
                                        deactivate={(row) => this.onServiceActivation(row as Service, false)} />
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
                <div className="Box-body">
                    <Can action={Actions.CREATE} resource={Resources.SERVICES}
                        yes={() =>
                            <div className="clearfix">
                                <div className="form-control float-right p-0 m-2">
                                    <button className="btn btn-primary" onClick={() => this.setState({ isEditOpened: true, editService: this.emptyService() })}>
                                        <PlusIcon /> Add
                                    </button>
                                </div>
                            </div>}
                    />
                </div>
            </div>
        );
    }
}
