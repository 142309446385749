import React, { Component } from 'react';
import { AppContext } from '../context';
import {
    ArgocdProject, ArgocdHealthStatus, ArgocdSyncStatus, ArgocdApplicationCondition, ArgocdProjectsResponse,
    isArgocdAppInFailedState, ArgocdApplicationConditionType
} from '../shared-interfaces';
import { api } from '../api';
import ComboBox from '../settings/combo-box';
import ArgocdApplicationGroupItem, { ArgocdApplicationGroup, CLOUD_LABEL } from './application-group-item';
import { IssueReopenedIcon } from '@primer/octicons-react';
import { TailSpin } from 'react-loader-spinner';

interface Props { }
const ALL_VALUE = 'ALL';

interface State {
    isLoading: boolean;
    projects: ArgocdProject[];
    allGroups: ArgocdApplicationGroup[];
    source: ArgocdApplicationGroup[];
    filters: Filters;
}

interface Filters {
    clouds: string[];
    selectedCloud: string;
    healthes: string[];
    selectedHealth: string;
    syncs: string[];
    selectedSync: string;
    searchText: string | undefined;
}

export default class ArgocdPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: true, projects: [], source: [], allGroups: [], filters: this.buildFilters([], [], []) };
        this.setDataSource = this.setDataSource.bind(this);
        this.shouldShowAppGroup = this.shouldShowAppGroup.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount(): void {
        this.refresh();
    }

    refresh(): void {
        this.setState({ isLoading: true });
        api.post(`argocd/projects`, { "Accept": "application/json", "Content-Type": "application/json" }).then(
            (r: ArgocdProjectsResponse) => {
                this.setDataSource(r.projects);
            })
            .finally(() => this.setState({ isLoading: false }));
    }

    buildFilters(clouds: string[], healthes: string[], syncs: string[], oldFilters?: Filters): Filters {
        return {
            clouds: [ALL_VALUE, ...clouds],
            selectedCloud: this.getFilterSelectedValue(clouds, oldFilters?.selectedCloud),
            healthes: [ALL_VALUE, ...healthes],
            selectedHealth: this.getFilterSelectedValue(healthes, oldFilters?.selectedHealth),
            syncs: [ALL_VALUE, ...syncs],
            selectedSync: this.getFilterSelectedValue(syncs, oldFilters?.selectedSync),
            searchText: oldFilters?.searchText
        };
    }

    getFilterSelectedValue(values: string[], selectedValue: string): string {
        if (values.includes(selectedValue)) {
            return selectedValue;
        }
        return ALL_VALUE;
    }

    setDataSource(projects: ArgocdProject[]): void {
        const allGroups: ArgocdApplicationGroup[] = [];
        for (const project of projects) {
            const groupedApps = this.groupAppsByName(project);
            allGroups.push(...groupedApps);
        }

        const { clouds, healthes, syncs } = this.getFiltersSources(allGroups);
        const filters = this.buildFilters(clouds, healthes, syncs, this.state.filters);

        this.setState({ projects, allGroups, source: allGroups.filter(x => this.shouldShowAppGroup(x, filters)), filters });
    }

    getFiltersSources(groups: ArgocdApplicationGroup[]): { clouds: string[], healthes: string[], syncs: string[] } {
        const clouds = Array.from(new Set(groups.flatMap(x => x.apps.flatMap(y => y.metadata.labels?.[CLOUD_LABEL] ?? []))));
        const healthes = Array.from(new Set(groups.flatMap(x => Object.keys(x.health).filter(y => x.health[y as ArgocdHealthStatus] > 0)))) as ArgocdHealthStatus[];
        const syncs = Array.from(new Set(groups.flatMap(x => Object.keys(x.syncStatus).filter(y => x.syncStatus[y as ArgocdSyncStatus] > 0)))) as ArgocdSyncStatus[];
        return { clouds, healthes, syncs };
    }

    shouldShowAppGroup(app: ArgocdApplicationGroup, filters: Filters): boolean {
        if (filters.selectedHealth !== ALL_VALUE && !app.health[filters.selectedHealth as ArgocdHealthStatus]) {
            return false;
        }
        if (filters.selectedSync !== ALL_VALUE && !app.syncStatus[filters.selectedSync as ArgocdSyncStatus]) {
            return false;
        }
        if (filters.selectedCloud !== ALL_VALUE && !app.apps.some(x => x.metadata.labels?.[CLOUD_LABEL] === filters.selectedCloud)) {
            return false;
        }
        if (filters.searchText && !app.name.toLowerCase().includes(filters.searchText.toLowerCase())) {
            return false;
        }
        return true;
    }

    setFilterValue(value: string | string[] | ArgocdHealthStatus[] | ArgocdSyncStatus[], filter: keyof Filters): void {
        const filters = { ...this.state.filters };
        (filters[filter] as typeof value) = value;
        console.log(filters);
        this.setState({ filters, source: this.state.allGroups.filter(x => this.shouldShowAppGroup(x, filters)) });
    }

    private groupAppsByName(project: ArgocdProject): ArgocdApplicationGroup[] {
        const nameRegEx = /([\w-]+)-[\w\d]+/;
        const map = new Map<string, ArgocdApplicationGroup>();
        for (const app of project.rawData.applications) {
            const match = app.metadata.name.match(nameRegEx);
            if (!match) {
                continue;
            }
            const name = match[1];
            let group = map.get(name);
            if (!group) {
                console.log(`Creating new group: ${name}`);
                group = {
                    name: name,
                    project: project,
                    health: Object.values(ArgocdHealthStatus).reduce((acc, key) => { acc[key] = 0; return acc; }, {} as Record<ArgocdHealthStatus, number>),
                    syncStatus: Object.values(ArgocdSyncStatus).reduce((acc, key) => { acc[key] = 0; return acc; }, {} as Record<ArgocdSyncStatus, number>),
                    conditions: [],
                    apps: [app],
                    errors: []
                }
                map.set(name, group);
            }
            group.apps.push(app);
            group.health[app.status.health.status] = group.health[app.status.health.status] + 1;
            group.syncStatus[app.status.sync.status] = group.syncStatus[app.status.sync.status] + 1;
            group.conditions.push(...app.status.conditions ?? []);
            if (isArgocdAppInFailedState(app.status.health.status)) {
                group.errors.push(app);
            }

        }

        return Array.from(map.values());
    }

    renderFilters(): JSX.Element {
        return (<div className="d-flex m-5 mb-0">
            <button className="btn mb-3 tooltipped tooltipped-s" aria-label='Refresh' onClick={() => this.refresh()}><IssueReopenedIcon /></button>
            <p className="h4 py-2 ml-6">Health&ensp;</p>
            <ComboBox rawValues={this.state.filters.healthes} editable={true} selectedValue={this.state.filters.selectedHealth} width={150}
                onItemClick={(item) => this.setFilterValue(item.value, 'selectedHealth')} />
            <p className="h4 py-2 ml-6">Sync status&ensp;</p>
            <ComboBox rawValues={this.state.filters.syncs} editable={true} selectedValue={this.state.filters.selectedSync} width={150}
                onItemClick={(item) => this.setFilterValue(item.value, 'selectedSync')} />
            <p className="h4 py-2 ml-6">Cloud&ensp;</p>
            <ComboBox rawValues={this.state.filters.clouds} editable={true} selectedValue={this.state.filters.selectedCloud} width={150}
                onItemClick={(item) => this.setFilterValue(item.value, 'selectedCloud')} />
            <p className="h4 py-2 ml-6">App name&ensp;</p>
            <input value={this.state.filters.searchText} style={{ height: '36px' }} onChange={(event) => this.setFilterValue(event.target.value, 'searchText')} />
        </div>);
    }


    render(): JSX.Element {
        if (this.state.isLoading) {
            return (
                <div className="d-flex flex-items-center flex-justify-around"
                    style={{ minHeight: "300px" }}>
                    <TailSpin color="#777777" height={50} width={50} />
                </div>
            );
        }
        return (
            <div className="Box">
                {this.renderFilters()}

                <div className="Box-body">
                    <div className="clearfix">
                        {this.state.source.map((app, index) => (
                            <ArgocdApplicationGroupItem app={app} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

ArgocdPage.contextType = AppContext;
