import React, { Component } from "react";
import { DatabaseIcon, AlertIcon, BeakerIcon } from "@primer/octicons-react";
import { api } from "../api";
import { NotificationType } from "../notifications/interfaces";
import { toast } from "../notifications/toast";

export default class DbSettings extends Component {
    constructor(props: any) {
        super(props);
        this.resetDb = this.resetDb.bind(this);
        this.testDb = this.testDb.bind(this);
    }

    resetDb(): void {
        if (confirm("Drop all tables?")) {
            api.post("settings/db/reset", {})
                .then(() => { toast.show(NotificationType.SUCCESS, `DB schema was recreated`); });
        }
    }

    testDb(): void {
        api.get("settings/db/test", {})
            .then(() => { toast.show(NotificationType.SUCCESS, `DB connection OK!`); });
    }

    //TODO: remove after test
    gitGC(): void {
        api.get("settings/db/gitgc", {})
            .then((res) => { toast.show(NotificationType.SUCCESS, `Git clean up completed!\n${res.result}`); });
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <div className="Box-header">DB Settings</div>
                <div className="Box-body">
                    <div className="form-actions mx-3">
                        <button className="btn text-red m-1" onClick={this.resetDb}><AlertIcon />Reset DB</button>
                        <button className="btn m-1" onClick={this.testDb}><DatabaseIcon />Test DB</button>
                        <button className="btn m-1" onClick={this.gitGC}><BeakerIcon />Git GC</button>
                    </div>
                </div>
            </div>
        );
    }
}
