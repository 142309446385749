import { Avatar } from "@primer/components";
import { PersonIcon } from "@primer/octicons-react";
import React, { Component } from "react";
import { TailSpin } from "react-loader-spinner";
import { Commit as CommitInfo } from "../shared-interfaces";
import { Utils } from "../utils";

interface Props {
    commit: CommitInfo;
}

export interface CommitState {
    isLoading?: boolean;
}

export default class Commit extends Component<Props, CommitState> {
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: false };
    }

    private renderHeader(commit: CommitInfo): JSX.Element {
        if (!!commit?.pr) {
            return (
                <a className={commit.pr.isReverted ? "link-gray no-underline" : "link-gray-dark no-underline"}
                    href={commit.pr.url} target="_blank">
                    <span className={commit.pr.isReverted ? "text-gray" : ""}>PR #{commit.pr.number} {commit.pr.title}</span>
                </a>)
        } else {
            return <span>{commit.summary}</span>;
        }
    }

    render(): JSX.Element {
        if (!!this.state.isLoading) {
            return (
                <div className="d-flex flex-items-center flex-justify-around">
                    <TailSpin color="#777777" height={50} width={50} /></div>
            );
        } else {
            return (
                <div className="details-reset" >
                    <div className="d-flex">
                        <div className="flex-1">
                            <p className="h5 mb-1">{this.renderHeader(this.props.commit)}</p>
                            <div className="no-wrap d-flex flex-items-center mt-1 mb-1">
                                <div>
                                    {!this.props.commit.pr
                                        ? <PersonIcon className="mr-3" />
                                        : <Avatar size={32}
                                            className="mr-3"
                                            alt={"@" + this.props.commit.pr.author.login}
                                            src={this.props.commit.pr.author.avatar} />
                                    }
                                </div>
                                <div className="f6 text-gray">
                                    <p className="text-gray-dark user-mention mb-1">
                                        {this.props.commit.author} commited on {Utils.formatDateTime(this.props.commit.time)}</p>
                                    <p >{this.props.commit.sha} </p>
                                    <p className="text-gray-dark user-mention"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        }
    }
}
