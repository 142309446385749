import React, { Component } from "react";
import { CheckIcon, PencilIcon, PlusIcon, XIcon } from "@primer/octicons-react";
import Can from "../can";
import { api } from "../api";
import { toast } from "../notifications/toast";
import { Actions, ReleaseFreezeSchedule, ReleaseFreezesResponse, Resources } from "../shared-interfaces";
import { NotificationType } from "../notifications/interfaces";
import { Dialog } from "@primer/components";
import { Moment, utc } from "moment";
import { Utils } from "../utils";

interface State {
    isLoaded: boolean;
    schedules: ReleaseFreezeSchedule[];
    isEditOpened: boolean;
    editSchedule: ReleaseFreezeSchedule;
}

interface Props { }

const SHOW_FINISHED_FREEZES_DAYS = 60;

export default class ReleaseFreezes extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { schedules: [], isLoaded: false, isEditOpened: false, editSchedule: this.emptySchedule() };
        this.handleReleaseFreezeDialog = this.handleReleaseFreezeDialog.bind(this);
        this.onReleaseFreezeFinished = this.onReleaseFreezeFinished.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateEditSchedule = this.updateEditSchedule.bind(this);
    }

    componentDidMount(): void {
        api.post(`release-freezes`, { "Accept": "application/json", "Content-Type": "application/json" }).then((r: ReleaseFreezesResponse) => {
            this.setState({ isLoaded: true, schedules: r.schedules });
        });
    }

    handleReleaseFreezeDialog(): void {
        const schedule = this.state.editSchedule;
        if (Utils.isNullOrWhitespace(schedule.name)) {
            alert(`Release freeze 'Name' should be specified.`);
            return;
        }
        if (utc(schedule.startAt).isAfter(utc(schedule.finishAt))) {
            alert(`'Start at' date must be before 'Finish at' date.`);
            return;
        }
        this.setState({ isLoaded: false, isEditOpened: false });
        api.post(
            "release-freezes/update",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify(schedule)
        ).then((r: ReleaseFreezesResponse) => {
            this.setState({ schedules: r.schedules, isLoaded: true, editSchedule: this.emptySchedule() });
            toast.show(NotificationType.SUCCESS, `Release freeze '${schedule.name}' ${schedule.id ? 'modified' : 'added'} successfully`);
        });
    }

    emptySchedule(): ReleaseFreezeSchedule {
        return {
            name: '',
            startAt: utc().add(1, 'day').startOf('day').toISOString(),
            finishAt: utc().add(2, 'day').startOf('day').toISOString(),
            isFinished: false,
            isStarted: false
        };
    }

    onReleaseFreezeFinished(schedule: ReleaseFreezeSchedule): void {
        toast.confirm(
            [`You are going to finish Release Freeze '${schedule.name}' - this action is not revertable. Are you sure?`],
            `'${schedule.name}' finish confirmation`,
            () => {
                api.post(`release-freezes/${schedule.id}/finish`, {})
                    .then((r: ReleaseFreezesResponse) => {
                        this.setState({ schedules: r.schedules, isLoaded: true, editSchedule: this.emptySchedule() });
                        toast.show(NotificationType.SUCCESS, `Release freeze '${schedule.name}' has been finished successfully`);
                    });
            });
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        const value = e.target.type === "datetime-local"
            ? this.extractDateTime(e.target.value)
            : e.target.value;
        this.updateEditSchedule(e.target.name, value);
    }

    extractDateTime(value: string): string {
        const coercedValue = utc(value).startOf('hour').toISOString();
        return coercedValue;
    }

    formatUtcDate(value: string | Moment): string {
        return utc(value).format('yyyy-MM-DDTHH:mm');
    }

    updateEditSchedule(propName: string, value: any): void {
        const editSchedule = this.state.editSchedule as any;
        editSchedule[propName] = value;
        this.setState({ editSchedule });
    }

    checkColumn(isChecked: boolean): JSX.Element {
        return (
            <td className="border-right">
                <div className="mx-3">{isChecked ? <CheckIcon /> : <XIcon />}</div>
            </td>
        );
    }

    finishColumn(schedule: ReleaseFreezeSchedule): JSX.Element {
        if (schedule.isFinished) {
            return null;
        }

        return (
            <td className="border-right">
                <button className="btn-sm btn-danger m-1 tooltipped tooltipped-sw" aria-label="Finish Release Freeze"
                    onClick={() => { this.onReleaseFreezeFinished(schedule); }}>
                    <XIcon />
                </button>
            </td>);
    }

    editColumn(schedule: ReleaseFreezeSchedule): JSX.Element {
        if (schedule.isFinished || schedule.isStarted) {
            return null;
        }

        return (<Can action={Actions.UPDATE} resource={Resources.SETTINGS}
            yes={() => (
                <td>
                    <button className="btn-sm btn-primary m-1"
                        onClick={() => this.setState({ isEditOpened: true, editSchedule: { ...schedule } })}>
                        <PencilIcon />
                    </button>
                </td>
            )}
        />);
    }

    maxWidthStyle(maxWidth: number): React.CSSProperties {
        return { maxWidth, wordWrap: "break-word" };
    }

    render(): JSX.Element {
        return (
            <div className="Box">
                <Dialog title={this.state.editSchedule.id ? `Edit '${this.state.editSchedule.name}' release freeze` : 'Create new release freeze'}
                    isOpen={this.state.isEditOpened}
                    onDismiss={() => this.setState({ isEditOpened: false })} aria-labelledby="header-id">
                    <div className="Box">
                        <div className="Box-body">
                            <div className="clearfix">
                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="name">Name</label>
                                <input type="text" className="form-control col-5 float-left p-2 m-2" name="name" required
                                    value={this.state.editSchedule.name} onChange={this.handleChange} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="startAt">Start at (UTC)</label>
                                <input type="datetime-local" step={60 * 60} className="form-control col-5 float-left p-2 m-2" name="startAt"
                                    value={this.formatUtcDate(this.state.editSchedule.startAt)} onChange={this.handleChange}
                                    min={this.formatUtcDate(utc().startOf('day'))} />
                                <div className="col-5 float-left" />

                                <label className="col-3 float-left text-right p-2 m-2" htmlFor="finishAt">Finish at (UTC)</label>
                                <input type="datetime-local" step={60 * 60} className="form-control col-5 float-left p-2 m-2" name="finishAt"
                                    value={this.formatUtcDate(this.state.editSchedule.finishAt)} onChange={this.handleChange}
                                    min={this.formatUtcDate((this.state.editSchedule.startAt ? utc(this.state.editSchedule.startAt) : utc()).startOf('day').add(1, 'hour'))} />
                                <div className="col-5 float-left" />
                            </div>
                        </div>

                        <div className="form-actions my-3 mx-5">
                            <button className="btn btn-primary m-1" type="submit" onClick={this.handleReleaseFreezeDialog}>{this.state.editSchedule.id ? 'Save' : 'Create'}</button>
                            <button className="btn m-1" type="button" onClick={() => this.setState({ isEditOpened: false })}>Cancel</button>
                        </div>
                    </div>
                </Dialog>
                <div className="Box-header">Release freezes</div>
                <div className="Box-body">
                    <table>
                        <thead>
                            <tr>
                                <th className="border-right"><div className="mx-3">Name</div></th>
                                <th className="border-right"><div className="mx-3">Start at</div></th>
                                <th className="border-right"><div className="mx-3">Finish at</div></th>
                                <th className="border-right"><div className="mx-3">Started</div></th>
                                <th className="border-right"><div className="mx-3">Finished</div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.schedules
                                .filter(x => utc().add(SHOW_FINISHED_FREEZES_DAYS, 'day').isAfter(utc(x.finishAt)))
                                .map((schedule, index) => (
                                    <tr className="border-top" key={index}>
                                        <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(240)}>{schedule.name}</div></td>
                                        <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(240)}>{utc(schedule.startAt).format('ddd, MMMM DD yyyy, HH:mm UTC')}</div></td>
                                        <td className="border-right"><div className="mx-3" style={this.maxWidthStyle(200)}>{utc(schedule.finishAt).format('ddd, MMMM DD yyyy, HH:mm UTC')}</div></td>
                                        {this.checkColumn(schedule.isStarted)}
                                        {this.checkColumn(schedule.isFinished)}
                                        {this.editColumn(schedule)}
                                        {this.finishColumn(schedule)}
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                </div>
                <div className="Box-body">
                    <Can action={Actions.UPDATE} resource={Resources.SETTINGS}
                        yes={() =>
                            <div className="clearfix">
                                <div className="form-control float-right p-0 m-2">
                                    <button className="btn btn-primary" onClick={() => this.setState({ isEditOpened: true, editSchedule: this.emptySchedule() })}>
                                        <PlusIcon /> Add
                                    </button>
                                </div>
                            </div>}
                    />
                </div>
            </div>
        );
    }
}
