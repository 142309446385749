import React, { Component } from "react";

interface Props { }

export default class BuildkiteIcon extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <svg width="12" height="12" viewBox="0 0 400 200" className="mr-2">
                <path d="M0 0v133l133.1 66.5v-133L0 0zM266.2 0v133l133.1-66.5L266.2 0z" fill="#30f2a2" />
                <path d="M266.2 133.1v133l133.1-66.5V66.5l-133.1 66.6zM133.1 66.5v133L266.2 133V0L133.1 66.5z"
                    fill="#14cc80" />
            </svg>
        );
    }
}
