import React, { Component } from "react";
import { TabNav } from "@primer/components";
import PrFiles from "./pr-files";
import PrDiffs from "./pr-diffs";
import { Commit } from "../shared-interfaces";

interface Props {
    commit: Commit;
    showMessageTab: boolean;
}

interface State {
    activeTab: CommitTab;
}

enum CommitTab {
    MESSAGE = 'message',
    FILES = 'files',
    DIFF = 'diff'
}

export default class CommitContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { activeTab: (props.showMessageTab ? CommitTab.MESSAGE : CommitTab.FILES) };
        this.setActiveTab = this.setActiveTab.bind(this);
    }

    setActiveTab(tab: CommitTab): void {
        this.setState({
            activeTab: tab,
        });
    }

    render(): JSX.Element {
        if (this.props.showMessageTab && !this.props.commit.pr) {
            return (<div>
                PR wasn't found. Please make sure that commit message matches the following format: "Change type(module): description (#PR)"
            </div>);
        }

        const diffs = this.props.commit.pr?.diffs ?? this.props.commit.diffs;
        const id = this.props.commit.pr?.number ?? this.props.commit.sha;

        return (
            <div>
                <TabNav aria-label={"pr-details-" + id}>
                    {this.props.showMessageTab
                        ? <TabNav.Link
                            href={"#pr-details-message-" + id}
                            selected={this.state.activeTab === CommitTab.MESSAGE}
                            onClick={(e) => { e.preventDefault(); this.setActiveTab(CommitTab.MESSAGE); }}>Message</TabNav.Link>
                        : null}
                    <TabNav.Link
                        href={"#pr-details-message-" + id}
                        selected={this.state.activeTab === CommitTab.FILES}
                        onClick={(e) => { e.preventDefault(); this.setActiveTab(CommitTab.FILES); }}>Files</TabNav.Link>
                    <TabNav.Link
                        href={"#pr-details-message-" + id}
                        selected={this.state.activeTab === CommitTab.DIFF}
                        onClick={(e) => { e.preventDefault(); this.setActiveTab(CommitTab.DIFF); }} >Diff</TabNav.Link>
                </TabNav>
                <div className="p-4 border border-top-0 overflow-x-auto">
                    {this.state.activeTab === CommitTab.MESSAGE
                        ? <div className="commit-desc">
                            <pre className="text-small">{this.props.commit?.pr?.body}</pre>
                        </div>
                        : null
                    }
                    {this.state.activeTab === CommitTab.FILES
                        ? <PrFiles files={diffs?.changedFiles} />
                        : null
                    }
                    {this.state.activeTab === CommitTab.DIFF
                        ? <PrDiffs diffs={diffs?.changedFiles} />
                        : null
                    }
                </div>
            </div>);
    }
}
