import React, { Component } from "react";
import { FileDiffs as FileDiffsInfo } from "../shared-interfaces";
import FileDiffs from "./file-diffs";

interface Props {
    diffs: FileDiffsInfo[];
}

export default class PrDiffs extends Component<Props> {
    getDiffs(fileDiffs: Props): JSX.Element {
        if (fileDiffs && fileDiffs.diffs?.length) {
            return (
                <div>
                    {fileDiffs.diffs.map((diff: FileDiffsInfo, index) => (
                        <FileDiffs {...diff} key={index} />))}
                </div>);
        } else {
            return <span>Not availables</span>;
        }
    }

    render(): JSX.Element {
        return (
            <div className="commit-desc scroll-box">
                <pre className="text-small">{this.getDiffs(this.props)}</pre>
            </div>
        );
    }
}
