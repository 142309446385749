import React, { Component } from "react";
import { api } from "./api";

interface AuthFormState {
    login: string;
    password: string;
};

interface Props {
    onAuth: () => void;
}
export default class AuthForm extends Component<Props, AuthFormState> {
    constructor(props: Props) {
        super(props);
        this.state = { login: "", password: "" };
        this.handleChange = this.handleChange.bind(this);
        this.auth = this.auth.bind(this);
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ [e.target.name]: e.target.value } as any);
    }

    auth(e: React.MouseEvent): void {
        e.preventDefault();
        console.log("authenticating");
        api.post(
            "login/basic",
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({
                login: this.state.login,
                password: this.state.password
            })
        ).then(response => {
            this.props.onAuth();
        }).catch(reason => {
            console.error(`Error while authorising: ${reason}`);
        });
    }

    render(): JSX.Element {
        return (
            <div className="Box box-shadow col-xl-3 col-lg-4 col-sm-6 m-5 mx-auto">
                <div className="Box-header">Authorisation</div>
                <div className="Box-body">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="Box-row pt-0 border-top-0">
                            <dl className="form-group">
                                <dt><label htmlFor="login">Login</label></dt>
                                <dd>
                                    <input
                                        className="form-control"
                                        name="login"
                                        type="text"
                                        value={this.state.login}
                                        onChange={this.handleChange}
                                    />
                                </dd>
                            </dl>

                            <dl className="form-group">
                                <dt>
                                    <label htmlFor="password">Password</label>
                                </dt>
                                <dd>
                                    <input
                                        className="form-control"
                                        name="password"
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                    />
                                </dd>
                            </dl>
                            <div className="form-actions">
                                <button type="submit" className="btn btn-primary" onClick={this.auth}>Login</button>
                                <button type="button" className="btn">Cancel</button>
                            </div>
                        </div>
                        <div className="Box-row text-center">
                            <p>- OR -</p>
                            <a href={`/api/login/okta?redirectTo=${location.pathname}`} className="btn">Login with OKTA</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
