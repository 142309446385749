import React, { Component } from "react";
import { GitBranchIcon } from "@primer/octicons-react";
import ReleaseSummary from "./release-summary";
import { Navigate } from "react-router-dom";
import Pagination from "./pagination";
import Can from "../can";
import { api, handleError, ServerError } from "../api";
import { Actions, NOT_DEPLOYED_STATUSES, ReleaseStatus, Resources, ServerErrorCodes } from "../shared-interfaces";
import { TailSpin} from "react-loader-spinner";
import { Release } from "../shared-interfaces";
import { AppContext, AppInfo } from "../context";

const RECORDS_PER_PAGE = 8;

interface State {
    isLoading: boolean,
    activeReleases: Release[],
    archiveReleases: Release[],
    pageCount: number,
    archivePage: Release[],
    redirect: string
};

interface Props { }

export default class Releases extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { activeReleases: [], archiveReleases: [], archivePage: [], pageCount: 0, isLoading: false, redirect: '' };
        this.startNewReleaseCreation = this.startNewReleaseCreation.bind(this);
        this.createNewRelease = this.createNewRelease.bind(this);
        this.createNewHotfix = this.createNewHotfix.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }


    getData(): Promise<{ releases: Release[] }> {
        return api.get("releases", {});
    }

    componentDidMount(): void {
        this.loadData();
    }

    loadData(): Promise<void> {
        console.log("Loading all reviews");
        this.setState({ isLoading: true });
        let active: Release[] = [];
        let archive: Release[] = [];
        return this.getData().then(r => {
            r.releases.forEach((release: Release) => {
                if (NOT_DEPLOYED_STATUSES.includes(release.status)) {
                    active.push(release);
                } else {
                    archive.push(release);
                }
            });
            const pageCount = Math.ceil(archive.length / RECORDS_PER_PAGE);
            this.setState({
                activeReleases: active,
                archiveReleases: archive,
                pageCount: pageCount,
                archivePage: archive.slice(0, RECORDS_PER_PAGE),
                isLoading: false
            });
        });
    }

    canCreateNewRelease(): boolean {
        if (this.state.activeReleases?.length > 0
            && !confirm(`You have an unmerged release ${this.state.activeReleases[0].name}\nIt will be canceled.`)) {
            return false;
        }
        return true;
    }

    startNewReleaseCreation(): void {
        if (this.canCreateNewRelease()) {
            this.createNewRelease(false);
        }
    }

    createNewRelease(forceSkipRequiredCommits: boolean): void {
        const hasDeployedRelease = this.state.archiveReleases.some(x => x.status === ReleaseStatus.DEPLOYING || x.status === ReleaseStatus.DEPLOYED);
        let lastDeployedCommit: string;
        if (!hasDeployedRelease) {
            lastDeployedCommit = prompt("Looks like it's our first release.\nPlease enter the hash of the last deployed commit");
            if (!lastDeployedCommit) {
                return
            }
        }
        this.setState({ isLoading: true });
        console.log(`Creating new release`);
        api.post(`releases/new`,
            { "Accept": "application/json", "Content-Type": "application/json" },
            JSON.stringify({ lastDeployedCommit, skipRequiredCommits: forceSkipRequiredCommits }),
            false)
            .then((r) => this.handleReleaseCreated(r))
            .catch((error: ServerError) => {
                const appInfo = this.context as AppInfo;
                if (!!appInfo.settings?.releaseSettings.allowUnsafeWorkflow &&
                    error.error?.code === ServerErrorCodes.RELEASE_NO_REQUIRED_COMMITS) {
                    if (confirm(`${error.error.message}\nReleasing now may revert a recent hotfix. Do you want to create a new release anyway?`)) {
                        return this.createNewRelease(true);
                    }
                } else {
                    handleError(error.error, false);
                }
                this.loadData();
            });
    }

    createNewHotfix(): void {
        if (!confirm(
            `Warning!

Hotfix is the last resort to fix a severe bug of high priority. 
If your fix is not related to P1 or P2 incident - you probably don't need a hotfix.

Reach out to RMC in #team_releasemanagement if you have more questions.`)) {
            return;
        }
        if (!this.canCreateNewRelease()) {
            return;
        }
        this.setState({ isLoading: true });
        console.log(`Creating new hotfix`);
        api.post(`hotfixes/new`, {}).then((r) => this.handleReleaseCreated(r)).catch(_ => this.loadData());
    }

    handleReleaseCreated(r: Release): void {
        console.log(r);
        if (r.id) {
            this.setState({ redirect: `/releases/${r.id}` });
        } else {
            this.loadData();
        }
    }

    onPageChange(page: number): void {
        this.setState({
            archivePage: this.state.archiveReleases.slice(
                RECORDS_PER_PAGE * page,
                RECORDS_PER_PAGE * (page + 1)
            )
        });
    }

    render(): JSX.Element {
        if (this.state.redirect) {
            return <Navigate replace to={this.state.redirect} />;
        } else if (!!this.state.isLoading) {
            return (
                <div className="d-flex flex-items-center flex-justify-around"
                    style={{ minHeight: "300px" }}>
                    <TailSpin color="#777777" height={50} width={50} />
                </div>
            );
        } else {
            return (
                <div className="Box box-shadow col-xl-8 col-lg-8 col-10 m-5 mx-auto">
                    <div className="Box">
                        <div className="Box-header clearfloat">
                            <Can action={Actions.CREATE} resource={Resources.RELEASES}
                                yes={() => (
                                    <div className="float-right ">
                                        <button
                                            className="btn btn-sm btn-outline mr-2"
                                            onClick={this.createNewHotfix}>
                                            <GitBranchIcon />New hotfix
                                        </button>
                                        <button
                                            className="btn btn-sm btn-primary mr-2"
                                            onClick={this.startNewReleaseCreation}>
                                            <GitBranchIcon />New release
                                        </button>
                                    </div>
                                )}
                            />
                            <h5>Active releases</h5>
                        </div>
                        <ul>
                            {this.state.activeReleases.map((r, index) => (
                                <li key={index} className="Box-row p-2">
                                    <ReleaseSummary release={r} />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="Box">
                        <div className="Box-header">
                            <h5>Previous releases</h5>
                        </div>
                        <div>
                            <ul>
                                {this.state.archivePage.map((r: Release, index: number) => (
                                    <li key={index} className="Box-row p-2">
                                        <ReleaseSummary release={r} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {this.state.pageCount < 2 ? null : (
                            <div className="border-top">
                                <Pagination pageCount={this.state.pageCount} onPageChange={this.onPageChange} />
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }
}

Releases.contextType = AppContext;
