import React, { Component } from "react";
import { GitPullRequestIcon, ShieldCheckIcon, ShieldXIcon, } from "@primer/octicons-react";

interface Props {
    total: number;
    approved: number;
    rejected: number;
}

export default class ReleaseReviewStats extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <div className="m-2">
                <span className="tooltipped tooltipped-n" aria-label="Total number of PRs">
                    <GitPullRequestIcon className="mx-1" />
                    <span className="h5 mr-2">{this.props.total}</span>
                </span>
                <span className="tooltipped tooltipped-n" aria-label="Number of approved PRs">
                    <ShieldCheckIcon className="mx-1 text-green" />
                    <span className="h5 mr-2">{this.props.approved}</span>
                </span>
                <span className="tooltipped tooltipped-n" aria-label="Number of blocked PRs. Any blocked PR blocks the release!">
                    <ShieldXIcon className="mx-1 text-red" />
                    <span className="h5 mr-2">{this.props.rejected}</span>
                </span>
            </div>
        );
    }
}
